/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React from "react";
import { injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components";

const ContactCardWrapper = styled.div`
  margin: 38px 48px;
  height: calc(100% - 76px);
  background: white;
  border: 1px solid ${({ theme }) => theme.color.Border};
`;

const ContentCard = ({ children, ...rest }) => <ContactCardWrapper {...rest}>{children}</ContactCardWrapper>;

export default withTheme(injectIntl(ContentCard));
