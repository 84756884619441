/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be in added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { injectIntl } from "react-intl";

import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";

import { get_permissable_routes } from "../../routing/appRouter";

import AppContext from "../../AppContext";

class BreadCrumb extends Component {
  static contextType = AppContext;

  async componentDidMount() {
    this.setupBreadCrumb();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search ||
      this.props.intl.locale !== prevProps.intl.locale ||
      JSON.stringify(this.props.history.location.state) !== JSON.stringify(prevProps.history.location.state)
    ) {
      this.setupBreadCrumb();
    }
  }

  setupBreadCrumb = () => {
    const { match, location } = this.props;
    let breadcrumbs = this.context.breadcrumbs;
    let idx = breadcrumbs.findIndex(x => x.pathname === location.pathname && x.search === location.search);
    if (idx !== -1) {
      this.context.updateBreadcrumbs(breadcrumbs.slice(0, idx + 1));
    } else {
      let to_keep = breadcrumbs.filter(b => {
        return location.pathname.includes(b.pathname);
      });
      breadcrumbs = to_keep;
      const pathSnippets = match.path.split("/").filter(i => i);
      let rmap = get_permissable_routes(
        this.context.permissions[this.context.active_role],
        this.context.roles[this.context.active_role]
      );
      pathSnippets.forEach((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 2).join("/")}`;
        let path = Object.keys(match.params).length
          ? Object.keys(match.params).reduce((url, param) => {
              return url.replace(`:${param}`, match.params[param]);
            }, url)
          : url;
        let idx = breadcrumbs.findIndex(x => {
          return x.pathname.replace(":title", x.label) === path.replace("?", "");
        });
        if (idx === -1) {
          // we are on a new path so reset breadcrumbs
          breadcrumbs = breadcrumbs.slice(0, index + 1);
          let item = rmap.filter(x => `/${x.product}/${x.regex ? x.regex : x.path}` === url);
          if (item.length === 0) {
            //look in children of parent
            const purl = `/${pathSnippets.slice(0, 2).join("/")}`;
            let parent = rmap.filter(x => `/${x.product}/${x.regex ? x.regex : x.path}` === purl);
            if (parent[0] !== undefined && parent[0].children) {
              item = parent[0].children.filter(x => `/${x.product}/${x.regex ? x.regex : x.path}` === url);
            }
          }

          if (item.length !== 0) {
            breadcrumbs.push({
              pathname:
                match.path === `/${item[0].product}/${item[0].regex ? item[0].regex : item[0].path}` ? match.url : url,
              search: url === match.path ? location.search : "",
              label:
                item[0].label === "{title}"
                  ? decodeURIComponent(match.params["title"])
                  : this.props.intl.formatMessage({ id: item[0].label }),
              link: item[0].component,
              extra_info: null,
            });
          }
        }
      });
      this.context.updateBreadcrumbs(breadcrumbs);
    }
  };

  buildBreadCrumb = () => {
    let bcCnt = this.context.breadcrumbs.length;
    return this.context.breadcrumbs.map((item, idx) => {
      return (
        <React.Fragment key={"f" + item.pathname}>
          <Breadcrumb.Item key={item.pathname}>
            {item.link && bcCnt !== idx + 1 ? (
              <Link to={{ pathname: item.pathname, search: item.search, state: { extra_info: item.extra_info } }}>
                {item.label}
              </Link>
            ) : (
              item.label
            )}
          </Breadcrumb.Item>
          {this.props.history.location.state?.extra_info?.ext_bc_item?.display_name ? (
            <Breadcrumb.Item key={item.pathname}>
              {this.props.history.location.state?.extra_info?.ext_bc_item?.display_name}
            </Breadcrumb.Item>
          ) : (
            false
          )}
        </React.Fragment>
      );
    });
  };

  render() {
    return <Breadcrumb>{this.buildBreadCrumb()}</Breadcrumb>;
  }
}

export default withRouter(withTheme(injectIntl(BreadCrumb)));

export function updateURLParameters(pComponent, params) {
  const { props, context } = pComponent;
  // work with history because thats what we are dynamically changing
  let urlParams = new URLSearchParams(props.history.location.search);
  Object.keys(params).forEach(k => {
    urlParams.set(k, params[k]);
  });

  // update breadcrumb nav needs to be first
  let breadcrumbs = context.breadcrumbs;
  let idx = breadcrumbs.findIndex(
    x => x.pathname === props.history.location.pathname && x.search === props.history.location.search
  );
  if (idx >= 0 && idx < breadcrumbs.length) {
    breadcrumbs[idx].search = urlParams.toString().length > 0 ? "?" + urlParams.toString() : "";
    context.updateBreadcrumbs(breadcrumbs);
  }
  // update url history for backbutton
  props.history.replace(props.history.location.pathname + "?" + urlParams.toString());

  return;
}

export function extendCurrenBreadCrumb(pComponent, params) {
  const { context, props } = pComponent;
  let breadCrumbs = context.breadcrumbs;
  let currBC = breadCrumbs[breadCrumbs.length - 1];
  // add info to breadcrumb for nav via breadcrumb
  breadCrumbs[breadCrumbs.length - 1].extra_info = params;
  context.updateBreadcrumbs(breadCrumbs);

  // replace history for backbutton
  props.history.replace(currBC.pathname, { extra_info: params });
  return;
}
