import styled from "styled-components";

const SidebarViewWrapper = styled.div`
  .sidebarViewContainer {
    height: 100vh;

    .sidebarViewContentHeader {
      display: flex;
      align-items: center;
      padding-left: 10px;

      .sidebarViewContentHeaderCollapse {
        font-size: 2em;
        cursor: pointer;
      }
    }

    .sidebarViewContentLayout {
      overflow-y: auto;
    }

    .backTopPosition {
      right: 44px;
      bottom: 66px;
    }
  }
`;

export default SidebarViewWrapper;
