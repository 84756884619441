import React, { Fragment, useContext } from "react";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { useRemoteVideoTileState, useActiveSpeakersState } from "amazon-chime-sdk-component-library-react";
import NoCamera from "./noCamera";
import VideoTile from "./videoTile";
import MeBlock from "./meBlock";
import MeetingContext from "../../MeetingContext";

const FeaturedTile = withTheme(
  injectIntl(({ intl, theme }) => {
    const { attendees } = useContext(MeetingContext);
    const { attendeeIdToTileId, tiles } = useRemoteVideoTileState();
    const activeSpeakers = useActiveSpeakersState();
    const activeSpeaker = attendeeIdToTileId[activeSpeakers[0]];

    const calculateTile = () => {
      if (tiles?.length > 0) {
        if (activeSpeaker) {
          return <VideoTile key="tileActiveWithCamera" tile={activeSpeaker} />;
        } else {
          return <MeBlock />;
        }
      } else {
        if (activeSpeaker) {
          return <NoCamera key="tileActiveNoCamera" attendee={attendees?.[activeSpeaker]} />;
        } else {
          return <MeBlock />;
        }
      }
    };

    return (
      <Fragment>
        {calculateTile()}
      </Fragment>
    );
  })
);

export default FeaturedTile;
