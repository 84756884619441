import { presetPalettes } from '@ant-design/colors';

// Neutral pallet override
const neutral_colors = [
    "#FFFFFF",
    "#FAFAFA",
    "#F5F5F5",
    "#E8E8E8",
    "#D9D9D9",
    "#BFBFBF",
    "#8C8C8C",
    "#595959",
    "#262626",
    "#000000"
]

export const build_color_palette = (colors) => {
    let palette = {}
    presetPalettes['grey'] = neutral_colors;

    Object.keys(colors).map(k => {
        let ref = colors[k].split('-');
        let color = ref[0];
        let code = parseInt(ref[1]);

        if (presetPalettes[color] !== undefined){
            if (presetPalettes[color][(code-1)] !== undefined){
                palette[k] = presetPalettes[color][(code-1)];
            }
        }else{
            palette[k] = colors[k];
        }

        return false
    });

    Object.keys(presetPalettes).map(k => {
        let codes = {}

        presetPalettes[k].map((h,i) => {
            codes[`${k}-${i+1}`] = h;

            return h;
        })

        palette[k] = codes;

        return false
    });

    return palette;
}
