import React from "react";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import PanelStyleWrapper from "./panel.style";
import { siteConfig } from "../../settings/config";
import AppContext from "../../AppContext";

import Nprogress from "nprogress";

import { withTheme } from "styled-components";

class PanelView extends React.PureComponent {
  static contextType = AppContext;

  componentWillUnmount() {
    Nprogress.start();
  }

  async componentDidMount() {
    if (!this.props.layout && this.context.appLayout !== "panel") {
      await this.context.updateLayout("panel");
    }
    Nprogress.done();
  }

  render() {
    var lang = this.context.currentLanguage();
    return this.props.layout ? (
      <PanelStyleWrapper className="panelViewWrapper">
        <div id="main-layout" className="panelViewContainer">
          <div className="panelViewContent">
            {this.props.logo !== false ? (
              <div className="panelViewLogo">
                <Link to="/">
                  <img src={siteConfig[this.props.theme.panelLogo][lang]} alt="" />
                </Link>
              </div>
            ) : (
              false
            )}

            {this.props.children}
          </div>
        </div>
      </PanelStyleWrapper>
    ) : (
      this.props.children
    );
  }
}

export default withTheme(injectIntl(PanelView));
