import React, { useContext, useEffect, useState, useRef } from "react";
import styled, { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import { Space } from "antd";
import MeetingContext from "./MeetingContext";
import VideoTileGrid from "./components/VideoTileGrid";
import MeetingControl from "./components/meetingControl";
import MeetingControls from "./components/MeetingControls";
import MicActivity from "./components/microphoneActivityBubble";
import { useToggleLocalMute, useRosterState } from "amazon-chime-sdk-component-library-react";

const Room = withTheme(
  injectIntl(({ intl, manager }) => {
    const { attendees, checkPage, fullscreen, meeting_info, setToFullScreen } = useContext(MeetingContext);
    const [callButtonsVisible, setVisible] = useState(fullscreen);
    const [fullscreenButtonVisible, setFullVisible] = useState(false);
    const { muted } = useToggleLocalMute();
    const { roster } = useRosterState();

    const current_roster = useRef({ roster: {} });

    useEffect(() => {
      compareRoster();
    });

    const compareRoster = () => {
      const old_keys = Object.keys(current_roster?.current?.roster ?? {});
      const new_keys = Object.keys(roster ?? {});
      let removed = [];
      let added = [];
      if (
        old_keys?.length === 0 &&
        new_keys?.length === 1 &&
        new_keys?.includes(meeting_info?.attendeeInfo?.AttendeeId)
      ) {
        current_roster.current.roster = roster;
        return;
      }
      if (
        (old_keys?.length > 1 || old_keys?.length === 1) &&
        new_keys?.length > 1 &&
        old_keys?.includes(meeting_info?.attendeeInfo?.AttendeeId)
      ) {
        for (const key of old_keys) {
          if (!new_keys.includes(key)) {
            removed.push(key);
          }
        }
        for (const key of new_keys) {
          if (!old_keys.includes(key)) {
            added.push(key);
          }
        }
        for (const user of removed) {
          notifyUser(`${attendees?.[user]?.Name ?? "Anonymous"} has left the call.`);
        }
        for (const user of added) {
          notifyUser(`${attendees?.[user]?.Name ?? "Anonymous"} has joined the call.`);
        }
      }
      current_roster.current.roster = roster;
    };

    const notifyUser = message => {
      // notification.info({
      //   message: intl.formatMessage({ id: message }),
      //   duration: 2,
      // });
    };

    return (
      <RoomContainer
        {...(checkPage("room") && !fullscreen && { style: { margin: 0, borderRadius: "2rem" } })}
        onMouseEnter={() => {
          if (!fullscreen) {
            setFullVisible(true);
            setVisible(true);
          }
        }}
        onMouseLeave={() => {
          if (!fullscreen) {
            setFullVisible(false);
            setVisible(false);
          }
        }}
      >
        <Space
          className="audioAndFullscreen"
          style={{
            justifyContent: "flex-end",
            background: "transparent",
            paddingTop: "0.5em",
            position: "absolute",
            paddingRight: "0.5em",
            width: "100%",
            zIndex: 2,
            height: "30px",
          }}
        >
          <MicActivity hidden={fullscreen || muted} style={{ marginLeft: "1em" }} />
          <MeetingControl
            buttonText={{ icon: ["fal", "compress-wide"], color: "white" }}
            onClick={() => {
              setFullVisible(false);
              setToFullScreen();
            }}
            placement="bottom"
            title="Show Fullscreen"
            type="ghost"
            visible={fullscreenButtonVisible && callButtonsVisible}
          />
        </Space>
        <VideoTileGrid />
        <MeetingControls
          callButtonsVisible={callButtonsVisible}
          {...(fullscreen ? { extraButtonsVisible: true } : {})}
          manager={manager}
        />
      </RoomContainer>
    );
  })
);

const RoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 1rem;
  background: #3f4149;
  border-radius: 2rem;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3);
  position: relative;
`;

export default Room;
