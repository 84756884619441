import React, { useContext } from "react";
import { Button, Space } from "antd";
import { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import MeetingContext from "../MeetingContext";
import { useLocalVideo } from "amazon-chime-sdk-component-library-react";

const Buttons = withTheme(
  injectIntl(({ manager, shape = "round", size = "large", style = { fontWeight: "bold" } }) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const { cleanUpMeeting, joinCall, setCall, setPrevCoords, setToFullScreen, shrinkScreen, startLoading, stopLoading } =
      useContext(MeetingContext);
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    return (
      <Space size={30}>
        <Button
          shape={shape}
          size={size}
          style={style}
          onClick={async () => {
            startLoading();
            await manager.leave();
            await cleanUpMeeting();
            setToFullScreen();
            stopLoading();
          }}
        >
          <FormattedMessage id="Leave" />
        </Button>
        <Button
          type="primary"
          shape={shape}
          size={size}
          style={style}
          onClick={async () => {
            let wasToggled = false;
            if (isVideoEnabled) {
              wasToggled = true;
              toggleVideo();
            }
            startLoading();
            await manager.start();
            await joinCall(wasToggled);
            await setPrevCoords({ x: clientWidth - 220, y: clientHeight - 20 });
            shrinkScreen();
            setCall(true);
          }}
        >
          <FormattedMessage id="Join" />
        </Button>
      </Space>
    );
  })
);

export default Buttons;
