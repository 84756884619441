/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { Layout, BackTop, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Sidemenu from "../../components/menu/sidemenu";
import Topbar from "../../components/menu/topbar";
import { siteConfig } from "../../settings/config";
import SidebarViewWrapper from "./sidebar.style";

import { withTheme } from "styled-components";

import ErrorBoundary from "../../helpers/errorBoundary";
import AppContext from "../../AppContext";

import Nprogress from "nprogress";

const { Content } = Layout;

export class SidebarView extends Component {
  static contextType = AppContext;

  componentWillUnmount() {
    Nprogress.start();
  }

  async componentDidMount() {
    if (!this.props.layout && this.context.appLayout !== "sidebar") {
      await this.context.updateLayout("sidebar");
    }
    Nprogress.done();
  }

  render() {
    return this.props.layout ? (
      <SidebarViewWrapper>
        <Layout id="main-layout" className="sidebarViewContainer">
          <BackTop />
          <Layout style={{ flexDirection: "row", overflowX: "hidden" }} className="sidebarViewLayout">
            <Sidemenu
              footer={
                <Space>
                  <FontAwesomeIcon icon={["fas", "bolt"]} />
                  {siteConfig.footerText}
                </Space>
              }
            />
            <Layout style={{ overflowY: "hidden" }}>
              <Topbar />
              <Layout
                id="content-main-layout"
                className="sidebarViewContentLayout"
                style={{ overflowY: this.context.appContentScroll }}
              >
                <BackTop className="backTopPosition" target={() => document.getElementById("content-main-layout")} />
                <Content id="content-main" className="sidebarViewContent">
                  <ErrorBoundary>{this.props.children}</ErrorBoundary>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </SidebarViewWrapper>
    ) : (
      this.props.children
    );
  }
}

export default withTheme(SidebarView);
