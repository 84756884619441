import React from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";

import { build_route_switch } from "./routing/appRouter";

import NotFound from "./pages/notfound";

import AppContext from "./AppContext";
import asyncComponent from "./helpers/AsyncFunc";

import { config } from "./settings/config";

const state_routing = (state, state_order, location, disableScreenRecording) => {
  /* The Routes for state redirection */
  let state_routes = {
    sq: "/security/security-questions",
    token: "/security/device-token",
  };

  /* Loop over the state order*/
  for (let s of state_order) {
    /* Check State Status */
    if (
      (state[s] !== undefined && state[s] === 0 && state_routes[s] !== undefined) ||
      (state[s] === undefined && state_routes[s] !== undefined)
    ) {
      /* Check we aren't currently there */
      if (location !== state_routes[s]) {
        if (state_routes[s] === "/security/security-questions") {
          disableScreenRecording("Set Security Questions Requested");
        } else if (state_routes[s] === "/security/device-token") {
          disableScreenRecording("Security Question Requested");
        }
        return <Redirect key="stateroute" to={state_routes[s]} />;
      } else {
        return false;
      }
    }
  }

  return false;
};

class Routes extends React.Component {
  render() {
    const context = { ...this.props.context, navigate: this.props.history.push, history: this.props.history };
    return (
      <AppContext.Provider value={context}>
        <Switch>
          {/* Unauthenticated Routes */}
          <Route exact path={"/authorize"} component={asyncComponent(() => import("./pages/public/authorize"))} />
          <Route exact path={"/signin"} component={asyncComponent(() => import("./pages/public/signin"))} />
          <Route
            exact
            path={"/forgotpassword"}
            component={asyncComponent(() => import("./pages/public/forgotpassword"))}
          />
          <Route exact path={"/activate/:code"} component={asyncComponent(() => import("./pages/public/activate"))} />
          <Route exact path={"/challenge"} component={asyncComponent(() => import("./pages/public/challenge"))} />
          {!this.props.context.isAuthenticated ? (
            <Redirect
              to={{
                pathname: !config.sso_enabled ? "/signin" : "/authorize",
                state: { referrer: window.location.pathname },
              }}
            />
          ) : (
            false
          )}

          {/* This manages the state based redirection */}
          {this.props.context.user_state !== null
            ? state_routing(
                this.props.context.user_state,
                this.props.context.state_order,
                window.location.pathname,
                this.props.context.disableScreenRecording
              )
            : false}

          {/* Security Routes */}
          <Route
            key="securityquestions"
            path="/security/security-questions"
            exact
            component={asyncComponent(() => import("./pages/security/securityQuestions"))}
          />
          <Route
            key="devicetokens"
            path="/security/device-token"
            exact
            component={asyncComponent(() => import("./pages/security/deviceTokens"))}
          />

          {/* Authenticated Routes */}
          {build_route_switch(
            this.props.context.permissions[this.props.context.active_role],
            this.props.context.roles[this.props.context.active_role]
          ).map(r => r)}
          <Route exact path={"/"}>
            <Redirect
              to={{
                pathname:
                  this.props.context.roles[this.props.context.active_role] !== undefined &&
                  this.props.context.roles[this.props.context.active_role].page !== undefined &&
                  this.props.context.roles[this.props.context.active_role].page !== null
                    ? this.props.context.roles[this.props.context.active_role].page
                    : this.props.context.defaultPage,
              }}
            />
          </Route>
          <Route key="notfound" component={NotFound} />
        </Switch>
      </AppContext.Provider>
    );
  }
}

export default withRouter(Routes);
