export const charts = {
    "Area Primary" : [
        "#fffcf0",
        "#fffbf0",
        "#f2eee4",
        "#e6d8ba",
        "#d9bf8e",
        "#b3976b",
        "#8c724d",
        "#664f33",
        "#40301f"
    ]
}



export const categories = {
    CASH: [ // Cash
        '#fff5f0',
        '#fff4f0',
        '#f7ddd5',
        '#ebb2a4',
        '#de8977',
        '#b8665a',
        '#914840',
        '#6b2e2a',
        '#451c1a'
    ],
    BD: [ // Bond / Debt
        '#fffcf0',
        '#fffbf0',
        '#f2eee4',
        '#e6d8ba',
        '#d9bf8e',
        '#b3976b',
        '#8c724d',
        '#664f33',
        '#40301f'
    ],
    SE: [ // Stock / Equity
        '#d1dedc',
        '#c5d1d0',
        '#b9c4c3',
        '#adb8b7',
        '#8aabaa',
        '#669c9d',
        '#487578',
        '#2d4f52',
        '#16292b',
        '#020505'
    ],
    MF: [ // Mutual Funds
        '#b0a9a5',
        '#a39c99',
        '#96908d',
        '#8a7771',
        '#7d5b52',
        '#6f4037',
        '#4a2721',
        '#24100e',
        '#000000'
    ],
    OP: [ // Option
        '#f0fffc',
        '#f0fffd',
        '#f0fffe',
        '#e9f7f7',
        '#e0ebeb',
        '#b1c4c4',
        '#869d9e',
        '#607578',
        '#404f52'
    ],
    UN: [ // Unknown
        '#fff0f2',
        '#fff0f3',
        '#ffcfd9',
        '#f7a1b6',
        '#eb7395',
        '#de4977',
        '#b83362',
        '#91214c',
        '#6b1338',
        '#450c25'
    ],
    N: [ // None / Other
        '#fffbf0',
        '#fcf8ed',
        '#f0ebe1',
        '#e3ded5',
        '#d6d1c9',
        '#cac5bf',
        '#a39b93',
        '#7d736a',
        '#574d45',
        '#302b26'
    ],
    C: [ // Bonds & Preferreds
        '#fffcf0',
        '#fffbf0',
        '#f2eee4',
        '#e6d8ba',
        '#d9bf8e',
        '#b3976b',
        '#8c724d',
        '#664f33',
        '#40301f'
    ],
    O: [ // Market Alternative
        '#b0a9a5',
        '#a39c99',
        '#96908d',
        '#8a7771',
        '#7d5b52',
        '#6f4037',
        '#4a2721',
        '#24100e',
        '#000000'
    ],
    L: [ // Canadian Equity
        '#d1dedc',
        '#c5d1d0',
        '#b9c4c3',
        '#adb8b7',
        '#8aabaa',
        '#669c9d',
        '#487578',
        '#2d4f52',
        '#16292b',
        '#020505'
    ],
    U: [ // International Equity
        '#c9c6bd',
        '#bdb9b1',
        '#b0aca5',
        '#a39f99',
        '#96928d',
        '#8a7e71',
        '#63584d',
        '#3d342c',
        '#17130f',
        '#000000'
    ],
    M: [ // Emerging Equity
        '#fffbf0',
        '#fcf8ed',
        '#f0ebe1',
        '#e3ded5',
        '#d6d1c9',
        '#cac5bf',
        '#a39b93',
        '#7d736a',
        '#574d45',
        '#302b26'
    ],
    X: [ // Cash
        '#fff5f0',
        '#fff4f0',
        '#f7ddd5',
        '#ebb2a4',
        '#de8977',
        '#b8665a',
        '#914840',
        '#6b2e2a',
        '#451c1a'
    ],
    B: [ // High Yield Bonds
        '#f0fffc',
        '#f0fffd',
        '#f0fffe',
        '#e9f7f7',
        '#e0ebeb',
        '#b1c4c4',
        '#869d9e',
        '#607578',
        '#404f52'
    ],
    F: [ // Private Pool
        '#fff0f2',
        '#fff0f3',
        '#ffcfd9',
        '#f7a1b6',
        '#eb7395',
        '#de4977',
        '#b83362',
        '#91214c',
        '#6b1338',
        '#450c25'
    ]
};

export const sectors = {
    11: [// Not Applicable
        '#fffbf0',
        '#fcf8ed',
        '#f0ebe1',
        '#e3ded5',
        '#d6d1c9',
        '#cac5bf',
        '#a39b93',
        '#7d736a',
        '#574d45',
        '#302b26'
    ],
    12: [// Unmapped
        '#fffbf0',
        '#fcf8ed',
        '#f0ebe1',
        '#e3ded5',
        '#d6d1c9',
        '#cac5bf',
        '#a39b93',
        '#7d736a',
        '#574d45',
        '#302b26'
    ],
    860: [ // Energy
        '#d1dedc',
        '#c5d1d0',
        '#b9c4c3',
        '#adb8b7',
        '#8aabaa',
        '#669c9d',
        '#487578',
        '#2d4f52',
        '#16292b',
        '#020505'
    ],
    861: [
        '#c9c6bd',
        '#bdb9b1',
        '#b0aca5',
        '#a39f99',
        '#96928d',
        '#8a7e71',
        '#63584d',
        '#3d342c',
        '#17130f',
        '#000000'
    ],	//Materials
    862: [
        '#e6e3d8',
        '#d9d5cc',
        '#ccc9c0',
        '#bfb8a8',
        '#b3a181',
        '#a58a5d',
        '#806541',
        '#594429',
        '#332415',
        '#0d0905'
    ],	//	Industrials
    863: [
        '#fff5f0',
        '#fff4f0',
        '#f7ddd5',
        '#ebb2a4',
        '#de8977',
        '#b8665a',
        '#914840',
        '#6b2e2a',
        '#451c1a'
    ],	//Consumer Discretionary
    864: [
        '#f2e9e4',
        '#e6dcd8',
        '#d9baad',
        '#cc9683',
        '#bf735c',
        '#b35239',
        '#8c3726',
        '#662116',
        '#40100b',
        '#1a0604'
    ],	//	Consumer Staples
    865: [
        '#b0a9a5',
        '#a39c99',
        '#96908d',
        '#8a7771',
        '#7d5b52',
        '#6f4037',
        '#4a2721',
        '#24100e',
        '#000000'
    ],	//	Health Care
    866: [
        '#d5e3df',
        '#c9d6d3',
        '#bdc9c7',
        '#b1bdbb',
        '#89b0aa',
        '#66a49d',
        '#477d78',
        '#2d5755',
        '#173030',
        '#050a0a'
    ],	//	Financials
    867: [
        '#e7eddf',
        '#dae0d3',
        '#ced4c7',
        '#c1c7bb',
        '#a7ba97',
        '#8aae71',
        '#668751',
        '#456135',
        '#273b1d',
        '#0d140a'
    ],	//	Information Technology
    868: [
        '#fff0fa',
        '#fcedf8',
        '#f0e1ec',
        '#e3d5e0',
        '#d6b0ce',
        '#ca85bf',
        '#a3649b',
        '#7d4677',
        '#572c54',
        '#301830'
    ],	//	Communication Services
    869: [
        '#fffaf0',
        '#fff9f0',
        '#f7e8d5',
        '#ebc9a4',
        '#dea977',
        '#b8845a',
        '#916240',
        '#6b432a',
        '#45291a'
    ],	//	Utilities
    870: [
        '#fff3f0',
        '#fff2f0',
        '#fff1f0',
        '#f2e5e4',
        '#e6bcba',
        '#d98f8e',
        '#b36b6c',
        '#8c4d50',
        '#663337',
        '#401f23'
    ],	//	Real Estate
    896: [
        '#bebfb4',
        '#b1b3a8',
        '#a4a697',
        '#939973',
        '#818c53',
        '#6f8037',
        '#4a5922',
        '#293311',
        '#0a0d04',
        '#000000'
    ],	//	Bonds & Preferreds
    897: [
        '#fffcf0',
        '#fffbf0',
        '#f2eee4',
        '#e6d8ba',
        '#d9bf8e',
        '#b3976b',
        '#8c724d',
        '#664f33',
        '#40301f'
    ],	//	Cash
    904: [
        '#fff0fb',
        '#fcedf9',
        '#f0bde6',
        '#e38fd6',
        '#d665c9',
        '#ca3fbf',
        '#a32a9d',
        '#7d1a7b',
        '#550e57',
        '#2e0730'
    ],	//	Equity Funds
    908: [
        '#fff0f2',
        '#ffcfd8',
        '#f7a1b5',
        '#eb7393',
        '#de4a77',
        '#b8335f',
        '#91214a',
        '#6b1336',
        '#450c24'
    ],	//	Equity Index
    927: [
        '#f0fffc',
        '#daf2ee',
        '#aae6de',
        '#7ed9d0',
        '#56ccc4',
        '#32bfbb',
        '#209999',
        '#127073',
        '#08484d',
        '#042326'
    ],	//	Balanced Funds
    958: [
        '#eaf0e1',
        '#dee3d5',
        '#c3d6a9',
        '#a7c97f',
        '#8bbd59',
        '#6fb037',
        '#508a24',
        '#346315',
        '#1d3d0a',
        '#0a1703'
    ],	//	Precious Metals
    959: [
        '#fffcf0',
        '#fffbf0',
        '#f2eee4',
        '#e6d8ba',
        '#d9bf8e',
        '#b3976b',
        '#8c724d',
        '#664f33',
        '#40301f'
    ],	// Bond Funds
};

