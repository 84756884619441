/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Badge, Modal, notification, Typography } from "antd";
import { MessageFilled } from "@ant-design/icons";
import ChatDrawer from "./drawer";
import AppContext from "../../AppContext";
import { invokeApi } from "../../helpers/authHelper";
import moment from "moment";

const { Text } = Typography;

const Chat = withTheme(
  injectIntl(
    ({
      chatSocket = {},
      fullscreen = true,
      intl,
      noButton = false,
      noSelectable = false,
      selectedChatroom,
      setSelectedChatroom,
      setVisible,
      updateOuter = () => {},
      type = "meeting",
      visible = false,
    }) => {
      const { axiosCancel, meetingContext, user_info } = useContext(AppContext);

      const signal = axiosCancel();

      const chatScrollbar = useRef({});
      const messageScrollbar = useRef({});
      const MAX_RETRYS = 10;
      const timeout = useRef({
        time: 30000,
        attempts: 0,
      });

      const [attachment, setAttachment] = useState(null);
      const [backToBottom, setBackToBottom] = useState(false);
      const [chats, setChats] = useState([]);
      const [chatScrollLimit, setChatScrollLimit] = useState(null);
      const [chatToken, setChatToken] = useState(null);
      const [isLoading, setIsLoading] = useState(true);
      const [isUploading, setIsUploading] = useState(false);
      const [messages, setMessages] = useState({});
      const [messageScrollLimit, setMessageScrollLimit] = useState(null);
      const [rooms, setRooms] = useState({});

      useEffect(() => {
        if (chatSocket.connection === null) {
          getMessageCount();
        }
        if (visible) {
          if (noSelectable) {
            updateRooms([
              {
                uuid: selectedChatroom?.uuid,
                count: rooms?.count,
                read_marker: meetingContext?.channelLastRead,
              },
            ]);
          } else {
            getChats();
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [chatSocket.connection, visible]);

      useEffect(() => {
        if (chatSocket.connection !== null) {
          chatSocket.connection.onmessage = handleSocketMessages; //for updating props
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [chatSocket.connection, messageScrollbar?.current, rooms, selectedChatroom, visible]);

      const showDrawer = () => setVisible(true);

      const hideDrawer = () => setVisible(false);

      const getMessageCount = async () => {
        let params = {
          path: `/rest/${type}/chat/unread`,
          method: "GET",
          cancelToken: signal.token,
          onSuccess: handleMessageCount,
          onError: handleError,
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(), // for meetings
          };
        }
        return await invokeApi(params);
      };

      const updateChannelLastRead = () => {
        let params = {
          path: `/rest/${type}/chat/read`,
          method: "POST",
          body: {
            c: selectedChatroom?.uuid,
          },
          cancelToken: signal.token,
          onSuccess: updateViewedRoom,
          onError: handleError,
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(), // for meetings
          };
        }
        return invokeApi(params);
      };

      const handleMessageCount = count => {
        chatSocket.counter = count;
        connectSocket();
      };

      const handleSocketMessages = async message => {
        const data = JSON.parse(message.data);
        const headers = data?.Headers;
        let payload = data?.Payload;
        if (payload) {
          payload = JSON.parse(payload);
        }
        const event_type = headers?.["x-amz-chime-event-type"]?.toUpperCase();
        const message_type = headers?.["x-amz-chime-message-type"]?.toUpperCase();
        switch (event_type) {
          case "SESSION_ESTABLISHED":
            if (message_type === "SYSTEM") {
              if (noSelectable) {
                setIsLoading(false);
              } else {
                getChats();
              }
            }
            return;
          case "CREATE_CHANNEL_MESSAGE":
            let room = rooms?.[payload?.ChannelArn] ?? {};
            let typing = room?.typing ?? {};
            let count = room?.count ?? 0;
            switch (message_type) {
              case "CONTROL":
                if (payload?.Persistence === "NON_PERSISTENT") {
                  switch (payload?.Content) {
                    case "TYPING":
                      if (
                        payload?.Sender?.Arn !== user_info?.chatroom_user_arn &&
                        payload?.Sender?.Arn !== user_info?.meeting_user_arn
                      ) {
                        typing[payload?.Sender?.Arn] = payload?.CreatedTimestamp;
                        setRooms(rooms => ({
                          ...rooms,
                          [payload?.ChannelArn]: {
                            ...room,
                            typing: typing,
                          },
                        }));
                      }
                      return;
                    case "STOPPED":
                      if (
                        payload?.Sender?.Arn !== user_info?.chatroom_user_arn &&
                        payload?.Sender?.Arn !== user_info?.meeting_user_arn
                      ) {
                        if (typing[payload?.Sender?.Arn]) {
                          delete typing[payload?.Sender?.Arn];
                        }
                        setRooms(rooms => ({
                          ...rooms,
                          [payload?.ChannelArn]: {
                            ...room,
                            typing: typing,
                          },
                        }));
                      }
                      return;
                    case "REQUEST_RECORDING":
                      if (type === "meeting" && payload?.Sender?.Arn !== user_info?.meeting_user_arn) {
                        requestRecording(payload?.Sender?.Arn);
                      }
                      return;
                    case "RECORDING_APPROVED":
                      if (type === "meeting") {
                        notification.success({
                          message: intl.formatMessage({ id: "You can now record this session" }),
                          duration: 3,
                        });
                        meetingContext.updateCanRecord(true);
                      }
                      return;
                    default:
                      return;
                  }
                }
                return;
              case "STANDARD":
                if (payload?.Persistence === "PERSISTENT") {
                  let new_count = 0;
                  if (selectedChatroom?.uuid === payload?.ChannelArn) {
                    if (visible) {
                      if (
                        messageScrollbar.current?.scrollbar?.offset?.x !==
                          messageScrollbar.current?.scrollbar?.limit?.x ||
                        messageScrollbar.current?.scrollbar?.offset?.y !== messageScrollbar.current?.scrollbar?.limit?.y
                      ) {
                        setMessageScrollLimit({
                          x: messageScrollbar.current?.scrollbar?.offset?.x,
                          y: messageScrollbar.current?.scrollbar?.offset?.y,
                        });
                      } else {
                        setMessageScrollLimit(null);
                      }
                      if (
                        messageScrollbar.current.scrollbar?.limit?.y - messageScrollbar.current.scrollbar?.offset?.y >
                        50
                      ) {
                        chatSocket.counter = chatSocket.counter + 1;
                        new_count = 1;
                        setBackToBottom(true);
                      } else {
                        setBackToBottom(false);
                      }
                    } else {
                      chatSocket.counter = chatSocket.counter + 1;
                      new_count = 1;
                    }
                    if (noButton && !visible && !fullscreen) {
                      notification.info({ message: intl.formatMessage({ id: "New meeting message" }), duration: 1 });
                    }
                    setMessages(messages => ({ ...messages, ...{ [payload?.MessageId]: payload } }));
                  } else if (!noSelectable) {
                    chatSocket.counter = chatSocket.counter + 1;
                    new_count = 1;
                  }
                  if (typing[payload?.Sender?.Arn]) {
                    delete typing[payload?.Sender?.Arn];
                  }
                  setRooms(rooms => ({
                    ...rooms,
                    [payload?.ChannelArn]: {
                      ...room,
                      typing: typing,
                      count: count + new_count,
                    },
                  }));
                  updateOuter();
                }
                return;
              default:
                return;
            }
          case "UPDATE_CHANNEL_MESSAGE":
          case "REDACT_CHANNEL_MESSAGE":
            if (payload?.Persistence === "PERSISTENT") {
              update_message(payload);
            }
            return;
          default:
            return;
        }
      };

      const update_message = updates => {
        if (Object.keys(messages)?.includes(updates?.MessageId)) {
          setMessages(messages => ({ ...messages, ...{ [updates?.MessageId]: updates } }));
        }
      };

      const connectSocket = () => {
        if (chatSocket?.url && !chatSocket?.connected) {
          chatSocket.connection = new WebSocket(chatSocket.url);
          let connectInterval;

          // websocket onopen event listener
          chatSocket.connection.onopen = () => {
            chatSocket.connected = true;
            timeout.current.time = 30000; // reset timer to 30 seconds on open of websocket connection
            timeout.current.attempts = 0;
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection
          };

          chatSocket.connection.onmessage = handleSocketMessages;

          // websocket onclose event listener
          chatSocket.connection.onclose = () => {
            timeout.current.time += timeout.current.time; //increment retry interval
            timeout.current.attempts += 1;
            if (timeout.current.attempts < MAX_RETRYS) {
              connectInterval = setTimeout(check, timeout.current.time); //call check function after timeout
            }
          };

          // websocket onerror event listener
          chatSocket.connection.onerror = () => {
            chatSocket.connection.close();
          };
        }
      };

      const requestRecording = user => {
        Modal.confirm({
          width: 500,
          title: (
            <span>
              {meetingContext.findAttendee(user) ? (
                meetingContext.findAttendee(user)
              ) : (
                <FormattedMessage id="Someone" />
              )}{" "}
              <FormattedMessage id="wants to record this session, is that ok with you?" />
            </span>
          ),
          okText: intl.formatMessage({ id: "Yes" }),
          cancelText: intl.formatMessage({ id: "No" }),
          onOk: () => {
            meetingContext.acceptRecording();
          },
        });
      };

      const check = () => {
        if (!chatSocket.connection || chatSocket.connection.readyState === WebSocket.CLOSED) connectSocket(); //check if websocket instance is closed, if so call `connect` function.
      };

      const getChats = () => {
        return invokeApi({
          path: `/rest/${type}/channels`,
          method: "GET",
          cancelToken: signal.token,
          onSuccess: handleChats,
          onError: handleError,
        });
      };

      const getChatToken = async () => {
        if (chatToken) {
          setIsLoading(true);
          return await invokeApi({
            path: `/rest/${type}/channels`,
            method: "GET",
            queryParams: {
              t: chatToken,
            },
            cancelToken: signal.token,
            onSuccess: appendToChats,
            onError: handleError,
          });
        }
      };

      const handleChats = result => {
        updateRooms(result?.channels);
        setChats(result?.channels);
        setChatToken(result?.NextToken);
        setIsLoading(false);
      };

      const updateRooms = channels => {
        let new_rooms = {};
        for (let channel of channels) {
          let room = rooms?.[channel?.uuid] ?? {};
          new_rooms[channel.uuid] = {
            ...room,
            last_read: channel?.read_marker,
            count: channel?.count ?? 0,
            name: channel?.name,
          };
        }
        setRooms(rooms => ({
          ...rooms,
          ...new_rooms,
        }));
        updateOuter();
      };

      const updateViewedRoom = () => {
        let room = rooms?.[selectedChatroom?.uuid] ?? {};
        let count = room?.count ?? 0;
        if (noSelectable) {
          count = chatSocket.counter;
        }
        chatSocket.counter = chatSocket.counter - count;
        if (chatSocket.counter < 0) {
          chatSocket.counter = 0;
        }
        room[selectedChatroom?.uuid] = {
          ...room,
          last_read: moment().toString(),
          count: 0,
        };
        if (type === "meeting") {
          meetingContext.updateChannelLastRead(moment().toString());
        }
        setRooms(rooms => ({
          ...rooms,
          ...room,
        }));
      };

      const appendToChats = result => {
        updateRooms(result?.channels);
        setChats(chats => [...chats, ...result?.channels]);
        setChatToken(result?.NextToken);
        setIsLoading(false);
      };

      const handleError = e => {
        if (e.message) {
          notification.error({
            message: intl.formatMessage({ id: e.message }),
          });
        } else {
          console.error(e);
        }
        setIsLoading(false);
      };

      return (
        <Wrapper>
          {chatSocket.connection !== null && !noButton && (
            <Badge count={chatSocket.counter} onClick={showDrawer} size="small">
              <MessageFilled />
            </Badge>
          )}
          <ChatDrawer
            attachment={attachment}
            backToBottom={backToBottom}
            chatScrollbar={chatScrollbar}
            chatScrollLimit={chatScrollLimit}
            chatToken={chatToken}
            chats={chats}
            closeDrawer={hideDrawer}
            getChatToken={getChatToken}
            loading={isLoading}
            noSelectable={noSelectable}
            messages={messages}
            messageScrollbar={messageScrollbar}
            messageScrollLimit={messageScrollLimit}
            rooms={rooms}
            selectedChatroom={selectedChatroom}
            setAttachment={setAttachment}
            setBackToBottom={setBackToBottom}
            setChatScrollLimit={setChatScrollLimit}
            setUploading={setIsUploading}
            setMessages={setMessages}
            setMessageScrollLimit={setMessageScrollLimit}
            setSelectedChatroom={setSelectedChatroom}
            title={
              <Text className="drawerTitle">
                <FormattedMessage id="Chat" />
              </Text>
            }
            type={type}
            updateChannelLastRead={updateChannelLastRead}
            uploading={isUploading}
            visible={visible}
            width={600}
          />
        </Wrapper>
      );
    }
  )
);

const Wrapper = styled.div`
  .ant-badge {
    font-size: ${props => props.theme.text["f20"]};
    cursor: pointer;
    vertical-align: middle;
    color: ${props => props.theme.color.grey["grey-8"]};
  }
`;

export default Chat;
