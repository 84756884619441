import React, { useContext } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { Space, Typography } from "antd";
import MeetingContext from "../MeetingContext";
import Buttons from "./lobbyButtons";
// import { useRosterState } from "amazon-chime-sdk-component-library-react";

const { Text, Title } = Typography;

const LobbyInfo = withTheme(
  injectIntl(({ manager }) => {
    const {
      // attendees,
      title,
    } = useContext(MeetingContext);
    // const { roster } = useRosterState();

      // const whoIsOnTheCall = () => {
  //   let users = "";
  //   console.log(roster)
  //   let extra = null;
  //   if (Object.keys(roster)?.length === 0) {
  //     return <FormattedMessage id="No one is on the call" />;
  //   }
  //   let index = 1;
  //   for (const attendee of Object.keys(roster)) {
  //     users += attendees?.[attendee]?.Name ?? "Anonymous";
  //     if (index > 1) {
  //       users += ", ";
  //     }
  //     index++;
  //     if (index > 2) {
  //       let totalLeft = (Object.keys(roster)?.length ?? 3) - 2;
  //       extra = (
  //         <FormattedMessage id="and {num} other{more}" values={{ num: totalLeft, more: totalLeft > 1 ? "s" : "" }} />
  //       );
  //       break;
  //     }
  //   }
  //   if (extra) {
  //     users = <FormattedMessage id="{string}{extra}" values={{ string: users, extra: extra }} />;
  //   }
  //   return <FormattedMessage id="{users} are on the call" values={{ users: users }} />;
  // };

    return (
      <Space direction="vertical" size={30}>
        <Title level={3}>
          <FormattedMessage id="Ready to Join?" />
        </Title>
        {/* <Text>{whoIsOnTheCall()}</Text> */}
        {title && (
          <Space size={30}>
            <Text strong>
              <FormattedMessage id="Meeting Name" />:
            </Text>
            <Text copyable={{ text: title ?? "Unknown" }}>{title ?? "Unknown"}</Text>
          </Space>
        )}
        <Buttons manager={manager} />
      </Space>
    );
  })
);

export default LobbyInfo;
