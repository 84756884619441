// TODO: Need to find a way to do custom chart themeing per deployment
import { charts, categories, sectors } from "./charts";

// Transition
export function transition(timing = 0.3) {
  return `
      -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
}

// Border Radius
export function borderRadius(radius = 0) {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -ms-transition: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}

// Box Shadow
export function boxShadow(shadow = "none") {
  return `
      -webkit-box-shadow: ${shadow};
      -moz-box-shadow: ${shadow};
      box-shadow: ${shadow};
  `;
}

export function smoothScrollCSS(trackColor, thumbColor) {
  return `
    .ant-table-body::-webkit-scrollbar,
  .ant-table-content::-webkit-scrollbar {
    height: 0.6em;
    width: 0.6em;
  }

  .ant-table-body::-webkit-scrollbar-track,
  .ant-table-content::-webkit-scrollbar-track {
    background: ${trackColor};
  }

  .ant-table-body::-webkit-scrollbar-thumb,
  .ant-table-content::-webkit-scrollbar-thumb {
    background: ${thumbColor};
    border-radius: 0.5em;
  }

  .ant-table-body::-webkit-scrollbar-thumb:hover,
  .ant-table-content::-webkit-scrollbar-thumb:hover {
    background: ${thumbColor};
  }
  `;
}

// Font Size and Weight
export function fontSizeWeight(defaultSize = 14) {
  const size = {
    baseSize: defaultSize,
    default: `${defaultSize}px`,
    f12: `${12 / defaultSize}em`,
    f14: `${14 / defaultSize}em`,
    f16: `${16 / defaultSize}em`,
    f18: `${18 / defaultSize}em`,
    f20: `${20 / defaultSize}em`,
    f24: `${24 / defaultSize}em`,
    f30: `${30 / defaultSize}em`,
    f38: `${38 / defaultSize}em`,
    f46: `${46 / defaultSize}em`,
    f56: `${56 / defaultSize}em`,
  };

  const weight = {
    light: "300",
    regular: "400",
    semibold: "600",
    bold: "700",
    extrabold: "800",
  };

  return { ...size, ...weight };
}

export function emConverter(px, defaultSize = 14) {
  return `${px / defaultSize}em`;
}

export function chartColors(key, shade = 4) {
  return charts?.[key]?.[shade] ?? "#333";
}

export function chartCategoryColor(category, shade = "4") {
  return categories[category]?.[shade] ?? categories["UN"][shade];
}

export function chartSectorColor(sector, shade = "4") {
  return sectors[sector]?.[shade] ?? sectors[12][shade];
}
