import React, { useContext, useEffect } from "react";
import { Card, notification, Typography } from "antd";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { PreviewVideo, useLocalVideo } from "amazon-chime-sdk-component-library-react";

import MeetingContext from "./MeetingContext";
import LobbyInfo from "./components/lobbyInfo";
import LobbyControls from "./components/lobbyFooter";

const { Title } = Typography;

const LobbyContainer = styled(Card)`
  width: 100%;
  height: 100%;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3);

  video {
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  z-index: 0;
  width: 55%;
  height: 72%;
  text-align: center;
  background: ${props => (props.cameraOn ? "transparent" : "black")};
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
`;

const Lobby = ({ intl, manager }) => {
  const { cleanUpMeeting, getMeetingInfo, setCall, stopLoading } = useContext(MeetingContext);

  const { isVideoEnabled } = useLocalVideo();

  useEffect(() => {
    joinMeeting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCall]);

  const joinMeeting = () => {
    let meeting_info = getMeetingInfo();
    if (meeting_info) {
      try {
        manager.join(meeting_info);
        setTimeout(() => stopLoading(), 1500);
      } catch {
        notification.info({
          message: intl.formatMessage({ id: "This meeting is no longer available" }),
          duration: 5,
        });
        cleanUpMeeting();
      }
    } else {
      cleanUpMeeting();
    }
  };

  return (
    <LobbyContainer>
      <VideoContainer cameraOn={isVideoEnabled}>
        <PreviewVideo hidden={!isVideoEnabled} style={{ height: "100%" }} />
        <Title hidden={isVideoEnabled} style={{ color: "white", alignSelf: "center", paddingTop: "25%" }} level={3}>
          <FormattedMessage id="Camera is Off" />
        </Title>
        <LobbyControls manager={manager} />
      </VideoContainer>
      <LobbyInfo manager={manager} />
    </LobbyContainer>
  );
};

export default injectIntl(Lobby);
