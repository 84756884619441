/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "antd";
import { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import Chats from "./chats";
import Messages from "./messages";
import { invokeApi } from "../../helpers/authHelper";
import AppContext from "../../AppContext";
import { Button, Form, notification, Space } from "antd";

const ChatDrawer = withTheme(
  injectIntl(
    ({
      attachment,
      backToBottom,
      chats = [],
      chatScrollbar = {},
      chatScrollLimit,
      chatToken = null,
      closeDrawer,
      getChatToken,
      intl,
      loading = false,
      noSelectable = false,
      messages,
      messageScrollbar = {},
      messageScrollLimit,
      rooms = {},
      selectedChatroom,
      setAttachment,
      setBackToBottom,
      setChatScrollLimit,
      setMessages,
      setMessageScrollLimit,
      setSelectedChatroom,
      setUploading,
      title,
      type = "meeting",
      updateChannelLastRead,
      uploading,
      visible,
      width,
    }) => {
      const { axiosCancel, meetingContext } = useContext(AppContext);
      const [isLoading, setIsLoading] = useState(false);
      const [messageToken, setMessageToken] = useState(null);
      const [users, setUsers] = useState({});
      const [itemVisible, setItemVisible] = useState(null);
      const [itemEditing, setItemEditing] = useState(null);
      const [senderButtonDisabled, setSenderButtonDisabled] = useState(true);
      const [canSave, setCanSave] = useState(false);
      const [isMessagesLoading, setMessagesLoading] = useState(true);
      const signal = axiosCancel();
      const [messageForm] = Form.useForm();
      const [senderForm] = Form.useForm();

      useEffect(() => {
        if (selectedChatroom) {
          getMessages(selectedChatroom);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedChatroom]);

      const goBack = () => {
        updateChannelLastRead();
        if (!noSelectable) {
          setMessageToken(null);
          setMessages({});
          setSelectedChatroom(null);
        }
        setAttachment(null);
        setBackToBottom(false);
        senderForm.resetFields();
        setItemVisible(null);
        setItemEditing(null);
        setMessagesLoading(false);
      };

      const getMessages = chat => {
        if (chat) {
          let params = {
            path: `/rest/${type}/chat/join`,
            method: "POST",
            body: {
              c: chat?.uuid,
            },
            cancelToken: signal.token,
            onSuccess: result => handleMessages(result, chat),
            onError: handleError,
          };
          if (type === "meeting") {
            params.queryParams = {
              m: meetingContext?.getMeeting(),
            };
          }
          setIsLoading(true);
          invokeApi(params);
        }
      };

      const handleMessages = (result, chat) => {
        setMessageToken(result?.NextToken);
        setMessages(result?.messages);
        setUsers(result?.users);
        if (!noSelectable) {
          setSelectedChatroom(chat);
        }
        setIsLoading(false);
      };

      const addToMessages = result => {
        setMessageToken(result?.NextToken);
        setMessages(messages => ({ ...messages, ...result.messages }));
        setTimeout(() => {
          setMessageScrollLimit(limit => ({
            y: messageScrollbar.current?.scrollbar?.limit?.y - limit?.y,
            x: messageScrollbar.current?.scrollbar?.limit?.x,
          }));
          setIsLoading(false);
        }, 1);
      };

      const getMessageToken = async () => {
        if (messageToken) {
          setIsLoading(true);
          let params = {
            path: `/rest/${type}/chat/join`,
            method: "POST",
            queryParams: {
              t: messageToken,
            },
            body: {
              c: selectedChatroom?.uuid,
            },
            cancelToken: signal.token,
            onSuccess: addToMessages,
            onError: handleError,
          };
          if (type === "meeting") {
            params.queryParams.m = meetingContext?.getMeeting();
          }
          return await invokeApi(params);
        }
      };

      const handleError = e => {
        if (e.message) {
          notification.error({
            message: intl.formatMessage({ id: e.message }),
          });
        } else {
          console.error(e);
        }
        setIsLoading(false);
      };

      const onCloseDrawer = () => {
        setBackToBottom(false);
        updateChannelLastRead();
        if (!noSelectable) {
          setMessageToken(null);
          setMessages({});
          setSelectedChatroom(null);
        }
        setAttachment(null);
        closeDrawer();
      };

      return (
        <Drawer
          footer={
            <Space style={{ width: "100%", placeContent: "flex-end" }}>
              {!noSelectable && selectedChatroom && (
                <Button type="link" onClick={goBack}>
                  <FormattedMessage id="Go Back" />
                </Button>
              )}
              <Button onClick={onCloseDrawer}>
                <FormattedMessage id="Close" />
              </Button>
            </Space>
          }
          footerStyle={{ lineHeight: 1 }}
          getContainer={false}
          onClose={onCloseDrawer}
          title={title}
          visible={visible}
          width={width}
        >
          <Messages
            attachment={attachment}
            backToBottom={backToBottom}
            canSave={canSave}
            chatSelected={selectedChatroom}
            getToken={getMessageToken}
            itemEditing={itemEditing}
            isLoading={isMessagesLoading}
            itemVisible={itemVisible}
            limit={messageScrollLimit}
            loading={isLoading || loading}
            messageForm={messageForm}
            messages={messages}
            noSelectable={noSelectable}
            room={rooms?.[selectedChatroom?.uuid]}
            scrollbar={messageScrollbar}
            senderButtonDisabled={senderButtonDisabled}
            senderForm={senderForm}
            setAttachment={setAttachment}
            setBackToBottom={setBackToBottom}
            setCanSave={setCanSave}
            setSenderButtonDisabled={setSenderButtonDisabled}
            setItemEditing={setItemEditing}
            setLimit={setMessageScrollLimit}
            setUploading={setUploading}
            setItemVisible={setItemVisible}
            setLoading={setMessagesLoading}
            token={messageToken}
            type={type}
            updateChannelLastRead={updateChannelLastRead}
            uploading={uploading}
            users={users}
            visible={noSelectable ? visible : selectedChatroom}
          />
          <Chats
            chats={chats}
            getToken={getChatToken}
            limit={chatScrollLimit}
            loading={isLoading || loading}
            onSelect={setSelectedChatroom}
            rooms={rooms}
            scrollbar={chatScrollbar}
            setLimit={setChatScrollLimit}
            token={chatToken}
            visible={selectedChatroom === null}
          />
        </Drawer>
      );
    }
  )
);

export default ChatDrawer;
