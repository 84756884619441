/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { Avatar, Button, Form, Input, Space, Tooltip, notification, Spin, Modal, Popconfirm } from "antd";
import { EditOutlined, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import AppContext from "../../AppContext";
import { HeaderWrapper } from "./index.style";
import { invokeApi, axiosCancel } from "../../helpers/authHelper";

class Header extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      deleteVisible: false,
    };
  }
  signal = axiosCancel();
  formRef = React.createRef();

  confirmSave = values => {
    const { confirm } = Modal;
    confirm({
      width: 500,
      title: this.props.intl.formatMessage({ id: "Are you sure you want to save these changes?" }),
      okText: this.props.intl.formatMessage({ id: "Yes" }),
      okButtonProps: {
        type: "primary",
      },
      cancelText: this.props.intl.formatMessage({ id: "No" }),
      cancelButtonProps: {
        type: "default",
      },
      onOk: () => {
        this.updateJobTitle(values);
        this.props.setDescriptionEditing(false);
        this.props.setFormTouched(false);
      },
    });
  };

  removeAvatar = async uuid => {
    try {
      await invokeApi({
        path: `rest/attachment/${uuid}/archive`,
        method: "GET",
        cancelToken: this.signal.token,
      });
    } catch (e) {
      console.error(e);
    }
  };

  updateJobTitle = async data => {
    this.setState({ loading: true });
    return invokeApi({
      path: `rest/advisor/update`,
      method: "PUT",
      cancelToken: this.signal.token,
      body: data?.job_title?.trim()?.length === 0 ? null : data,
      onSuccess: r => {
        this.handleUpdate(r);
      },
      onError: e => {
        notification.error({
          message: this.props.intl.formatMessage({ id: "Error updating job title" }),
        });
        this.setState({ loading: false });
        if (this.formRef.current) {
          this.formRef.current.resetFields();
        }
      },
    });
  };

  handleUpdate = async r => {
    await this.context.updateUserStatus();
    this.setState({ loading: false });
    notification.success({
      message: this.props.intl.formatMessage({ id: "Successfully updated job title" }),
    });
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <HeaderWrapper>
        <Spin spinning={this.state.loading || this.props.avatarEditing}>
          <div className="settingsHeader">
            {/* <Popconfirm
              title={
                <span>
                  <FormattedMessage id="Are you sure you want to remove your profile picture?" />
                </span>
              }
              visible={this.props.contact_info?.photo_url !== null && this.state.deleteVisible}
              trigger="click"
              onConfirm={async () => {
                this.setState({ loading: true, deleteVisible: false });
                await this.removeAvatar(this.props.contact_info?.photo_uuid);
                await this.context.updateUserStatus();
                this.setState({ loading: false });
              }}
              onCancel={() => this.setState({ deleteVisible: false })}
              okText={<FormattedMessage id="Yes" />}
              cancelText={<FormattedMessage id="No" />}
            >
              {this.props.contact_info?.photo_url !== null && (
                <Button
                  className="overlayButton"
                  type="link"
                  onClick={() => {
                    this.setState({ deleteVisible: true });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              )} */}
            <Avatar size={72} style={{ marginLeft: "20px" }}>
              {this.props.contact_info?.first_name || this.props.contact_info?.last_name
                ? this.props.contact_info?.first_name?.charAt(0).toUpperCase() +
                  this.props.contact_info?.last_name?.charAt(0).toUpperCase()
                : "--"}
            </Avatar>
            {/* </Popconfirm> */}
            <div className="marginSmallTop">
              <div className="fieldValue">
                {this.props.contact_info?.display_name
                  ? this.props.contact_info?.display_name
                  : this.props.contact_info?.first_name || this.props.contact_info?.last_name
                  ? this.props.contact_info?.first_name + " " + this.props.contact_info?.last_name
                  : "--"}
              </div>
              {this.props.descriptionEditing ? (
                <Form name="about" ref={this.formRef} onFieldsChange={() => this.props.setFormTouched(true)}>
                  <Form.Item
                    name="job_title"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (value) {
                            // eslint-disable-next-line
                            return value.match(/^[a-zA-Z0-9 -]{0,}$/g)
                              ? Promise.resolve()
                              : Promise.reject(this.props.intl.formatMessage({ id: "Enter valid job title" }));
                          }
                        },
                      },
                    ]}
                  >
                    <Space>
                      <Input width="50%" defaultValue={this.props.contact_info?.job_title} />
                      <Button
                        type="link"
                        onClick={() => {
                          this.formRef.current.validateFields().then(values => {
                            this.confirmSave(this.formRef.current?.getFieldsValue());
                          });
                        }}
                      >
                        <SaveOutlined />
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              ) : (
                <Space>
                  <div className="fieldValue">{this.props.contact_info?.job_title ?? "--"}</div>
                  <Tooltip
                    title={
                      <span>
                        <FormattedMessage id="Edit job title" />
                      </span>
                    }
                  >
                    <Button
                      type="link"
                      onClick={() => {
                        this.props.setDescriptionEditing(true);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                </Space>
              )}
            </div>
          </div>
        </Spin>
      </HeaderWrapper>
    );
  }
}

export default withTheme(injectIntl(Header));
