import styled from "styled-components";

const TopbarWrapper = styled.div`
  .ant-layout-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding-left: 10px;
    position: relative;

    background-color: ${props => props.theme.headerColor};
  }

  .topbarContentLeft {
    display: flex;
    align-items: center;

    .topbarBreadcrumb {
      display: flex;
      padding-left: 14px;

      .ant-breadcrumb {
        font-size: ${props => props.theme.text["f16"]};
      }
    }
  }

  .topbarContentRight {
    display: flex;
    align-items: center;
  }

  .topbarMenuContent {
    margin-right: 1em;
  }

  .collapseSidebarToggle {
    font-size: 2em;
    cursor: pointer;
  }

  .topbarUserIconContainer {
    cursor: pointer;
  }

  .topbarUserDropdownOverlayContainer {
    color: ${props => props.theme.headerText};
    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-collapse-content-box {
      padding: 12px; /* hardcoded to match collapse header for formatting */
    }

    .ant-collapse {
      border: none;
    }

    .panelHdr {
      color: ${props => props.theme.headerText};
      svg {
        margin-right: 10px;
      }
    }

    .panelIndent {
      margin-left: 35px;
    }
  }

  .userRoles {
    .ant-menu-item {
      height: inherit;
      line-height: inherit;
      margin-bottom: 8px; // hardcoded to have consistent spacing in menu to match antd
      margin-left: 16px; // hardcode to sent proper indent
    }
  }

  .menuItem {
    color: ${props => props.theme.headerText};
    .menuIcon {
      margin-right: 10px;
    }
  }

  .topbarRegionContainer {
    cursor: pointer;
    font-size: ${props => props.theme.text["f20"]};
    color: ${props => props.theme.headerText};
  }

  .topbarRegionDropdownOverlayContainer {
    color: ${props => props.theme.headerText};
    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-collapse-content-box {
      padding: 12px; /* hardcoded to match collapse header for formatting */
    }

    .ant-collapse {
      border: none;
      border-bottom: 1px solid #f0f0f0; /* set to match menu divider */
    }

    .panelHdr {
      color: ${props => props.theme.headerText};
      svg {
        margin-right: 10px;
      }
    }

    .panelIndent {
      margin-left: 35px;
    }

    label span {
      color: ${props => props.theme.headerText};
    }
  }

  .topbarAlertContainer {
    cursor: pointer;
    font-size: ${props => props.theme.text["f20"]};
    color: ${props => props.theme.headerText};
    .anticon {
      vertical-align: middle;
    }
  }

  .topbarLanguageLabel {
    cursor: pointer;
    text-transform: capitalize;
    &:hover {
      color: ${props => props.theme.color.grey["grey-7"]};
    }
    font-size: ${props => props.theme.text.f18};
    margin-top: -6px;
  }

  .topbarLanguageDropdown {
    height: 3em;
    width: 2em;
    align-self: baseline;
    text-align: center;
  }
`;

export default TopbarWrapper;
