/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { useContext } from "react";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { Avatar, Badge, Button, Comment, Form, Input, notification, Space, Tooltip, Typography } from "antd";
import AppContext from "../../AppContext";
import { invokeApi } from "../../helpers/authHelper";
import axios from "axios";

const { Item } = Form;
const { TextArea } = Input;
const { Text } = Typography;

const Message = withTheme(
  injectIntl(
    ({
      chatSelected,
      details = {},
      editing = false,
      form = {},
      intl,
      read = false,
      saveItem,
      setCanSave,
      type = "meeting",
      user = {},
    }) => {
      const { active_role, hasPermission, meetingContext, roles, user_info } = useContext(AppContext);

      const getInitials = name => {
        if (name) {
          const names = name.split(" ");
          return names.map(item => item?.[0]?.toUpperCase?.())?.join?.("");
        } else {
          return "UN";
        }
      };

      const getDownload = async doc_id => {
        let params = {
          path: `/rest/meeting/chat/attachment/${doc_id}/download`,
          method: "POST",
          body: {
            c: chatSelected?.uuid,
          },
          onSuccess: downloadFile,
          onError: () => {
            notification.error({
              message: intl.formatMessage({ id: "There was a problem, please try again later" }),
              duration: 5,
            });
          },
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(), // for meetings
          };
        }
        return await invokeApi(params);
      };

      const downloadFile = download_info => {
        if (download_info?.url) {
          axios({
            url: download_info?.url,
            method: "GET",
            download: true,
            responseType: "blob",
          })
            .then(response => {
              const display_name = download_info?.display_name;
              const url = window.URL.createObjectURL(response.data);
              const link = document.createElement("a");
              link.href = url;
              link.download = display_name ?? download_info?.filename;
              link.target = "_blank";
              document.body.appendChild(link);
              link.click();
              // Added for safari issues, revoke needed to be delayed so it doesn't cause errors
              setTimeout(function () {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
              }, 10000);
            })
            .catch(err => {
              notification.error({
                message: intl.formatMessage({ id: "There was a problem, please try again later" }),
                duration: 5,
              });
            });
        } else {
          notification.error({
            message: intl.formatMessage({ id: "There was a problem, please try again later" }),
            duration: 5,
          });
        }
      };

      const getContent = () => {
        if (editing) {
          return (
            <Form form={form} onValuesChange={setCanSave}>
              <Item name="content" style={{ marginBottom: 0 }}>
                <TextArea
                  autoSize
                  placeholder={intl.formatMessage({ id: "Enter message" })}
                  maxLength={4000}
                  onPressEnter={event => {
                    if (!event?.shiftKey) {
                      saveItem();
                    }
                  }}
                  style={{ maxHeight: "150px" }}
                />
              </Item>
            </Form>
          );
        } else if (details?.Redacted) {
          return (
            <Text type="secondary">
              <FormattedMessage id="Message was deleted" />
            </Text>
          );
        } else {
          let content = details?.Content;
          let metaFound = false;
          let metaData = "";
          if ((details?.Metadata ?? "") !== "") {
            metaData = details?.metaData;
            content = content.replace("{link}", "");
            metaFound = true;
            if (
              !hasPermission(
                `chime.chat${type === "meeting" ? ".video" : ""}.${roles?.[active_role]?.name}.message.attachment`
              )
            ) {
              metaData = "";
            }
          }
          return (
            <Space>
              <Button
                hidden={!metaFound}
                onClick={() => getDownload(metaData)}
                disabled={metaData === ""}
                style={{ padding: 0 }}
                type="link"
              >
                {metaData === "" ? <FormattedMessage id="Not Available" /> : <FormattedMessage id="Download" />}
              </Button>
              <Text style={{ whiteSpace: "pre-wrap" }}>{content}</Text>
            </Space>
          );
        }
      };

      return (
        <Comment
          author={user?.name ?? "Unknown"}
          avatar={<Avatar src={user?.avatar}>{getInitials(user?.name)}</Avatar>}
          content={getContent()}
          datetime={
            <Space>
              <Tooltip title={moment(details?.CreatedTimestamp).format("MMM DD, YYYY h:mm:ss a")}>
                <Text type="secondary">{moment(details?.CreatedTimestamp).fromNow()}</Text>
              </Tooltip>
              {details?.LastEditedTimestamp && !details?.Redacted && (
                <Text type="secondary">
                  <FormattedMessage id="Edited" />
                </Text>
              )}
              {!read && user_info?.uuid !== user?.uuid && (
                <Tooltip getPopupContainer={e => e?.parentElement} title={<FormattedMessage id="New" />}>
                  <Badge color="blue" offset={[0, -2]} />
                </Tooltip>
              )}
            </Space>
          }
          style={{ width: "100%" }}
        />
      );
    }
  )
);

export default Message;
