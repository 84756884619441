/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
*/

import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components";
import { List } from "antd";
import Scrollbar from "react-smooth-scrollbar";
import Chat from "./chat";

const Chats = withTheme(
  injectIntl(
    ({
      chats,
      getToken,
      limit = null,
      loading = false,
      onSelect,
      rooms = {},
      scrollbar = {},
      setLimit,
      token = null,
      visible = false,
    }) => {
      const body = useRef({});
      const [isLoading, setLoading] = useState(false);

      useEffect(() => {
        if (visible) {
          if (scrollbar.current?.scrollbar) {
            scrollbar.current.scrollbar.scrollTo(
              limit?.x ? scrollbar.current.scrollbar?.limit?.x - limit?.x : scrollbar.current.scrollbar?.limit?.x,
              limit?.y ? scrollbar.current.scrollbar?.limit?.y - limit?.y : scrollbar.current.scrollbar?.limit?.y
            );
          }
        } else {
          setLimit(null);
        }
        setLoading(false);
      }, [chats, limit, scrollbar, setLimit, visible]);

      const onScroll = event => {
        if (event?.offset?.y === 0 && token) {
          setLoading(true);
          setLimit(event?.limit);
          getToken();
        }
      };

      return (
        <Wrapper hidden={!visible} ref={body} style={{ height: "100%" }}>
          <Scrollbar onScroll={onScroll} ref={scrollbar}>
            <List
              dataSource={chats}
              loading={loading || isLoading}
              renderItem={(item, index) => (
                <Chat key={`chat${index}`} details={item} onSelect={onSelect} room={rooms?.[item?.uuid]} />
              )}
            />
          </Scrollbar>
        </Wrapper>
      );
    }
  )
);

const Wrapper = styled.div`
  height: 100%;
  .ant-card-hoverable:hover {
    border-color: ${props => props.theme.color.blue["blue-4"]};
  }
`;

export default Chats;
