/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { Badge, Button, Card, Space, Typography } from "antd";

const { Text } = Typography;

const Chat = withTheme(
  injectIntl(({ details = {}, room = {}, onSelect }) => {
    const handleExtras = () => {
      let extras = [];
      if (room?.count > 0) {
        extras.push(
          <Space key="newMessagesChat">
            <Badge color="blue" offset={[0, 2]} />
            <Text>
              {room?.count} <FormattedMessage id={room?.count > 1 ? "new messages" : "new message"} />
            </Text>
          </Space>
        );
      }
      extras.push(
        <Button key="viewConversationButton" style={{ padding: 0 }} type="link">
          <FormattedMessage id="View Conversation" />
        </Button>
      );
      // TODO: Typing logic if we add the ability to have more then one on one chat rooms
      // if (room?.typing) {
      //   if (Object.keys(room?.typing)?.length > 1) {
      //     extras.push(
      //       <Text key="whosTypingMultiple" type="secondary">
      //         {Object.keys(room.typing).length} <FormattedMessage
      //           defaultMessage="are typing"
      //           id="are typing"/>...
      //       </Text>
      //     );
      //   } else if (Object.keys(room?.typing)?.length === 1) {
      //     extras.push(
      //       <Text key="whosTyping" type="secondary">
      //         <FormattedMessage id="Someone is typing" />...
      //       </Text>
      //     );
      //   }
      // }
      return extras;
    };

    return (
      <Card hoverable onClick={() => onSelect(details)}>
        <Space direction="vertical" size={4} style={{ width: "70%" }}>
          <Space>
            <Text>{details?.name}</Text>
            {Object.keys(room?.typing ?? {})?.length > 0 && (
              <Text key="whosTypingMultiple" type="secondary">
                <FormattedMessage id="is typing" />
                ...
              </Text>
            )}
          </Space>
          {details?.username && <Text type="secondary">({details?.username})</Text>}
        </Space>
        <Space direction="vertical" size={0} style={{ textAlign: "right", width: "30%" }}>
          {handleExtras()}
        </Space>
      </Card>
    );
  })
);

export default Chat;
