import styled from "styled-components";

const SettingsDrawerWrapper = styled.div`
  width: 100%;
`;

export default SettingsDrawerWrapper;

export const HeaderWrapper = styled.div`
  .settingsHeader {
    display: grid;
    grid-template-columns: 100px auto;
    grid-gap: 1em;
    margin-bottom: 1em;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .overlayButton {
    width: 74px;
    height: 74px;
    position: absolute;
    z-index: 1;
    margin-top: 1px;
    margin-left: 20px;
    .anticon {
      position: absolute;
      top: 43%;
      left: 0%;
      width: 100%;
      height: 100%;
      color: transparent;
    }
    &:hover {
      .anticon {
        color: ${props => props.theme.color.red["red-6"]};
      }
      &:before {
        display: block;
        content: "";
        background: lightgray;
        width: 100%;
        opacity: 0.5;
        border-radius: 100%;
        height: 100%;
      }
    }
  }
`;

export const AboutWrapper = styled.div`
  .aboutGrid {
    display: grid;
    grid-template-columns: 120px auto;
  }

  .mb2 {
    margin-bottom: 2em;
  }

  .avatar-button {
    width: 60%;
    height: 100px;
  }
`;
