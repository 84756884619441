import React, { useContext, useState } from "react";
import styled, { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import CallButtons from "./callButtons";
import ExtraButtons from "./extraButtons";
import MeetingContext from "../MeetingContext";

const MeetingControls = withTheme(
  injectIntl(({ callButtonsVisible, extraButtonsVisible = null, intl, manager }) => {
    const { fullscreen } = useContext(MeetingContext);
    const [extraVisible, setVisible] = useState(false);

    if (!fullscreen) {
      if (extraVisible || extraButtonsVisible) {
        setVisible(false);
      }
    }

    return (
      <Wrapper
        onMouseEnter={() => !extraVisible && fullscreen && setVisible(true)}
        onMouseLeave={() => {
          if (extraVisible) {
            setVisible(false);
          }
        }}
        {...(!fullscreen && { style: { gridTemplateColumns: "100%" } })}
      >
        <div />
        <CallButtons visible={callButtonsVisible} manager={manager} />
        <ExtraButtons manager={manager} visible={extraButtonsVisible ?? extraVisible} />
      </Wrapper>
    );
  })
);

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 45% auto auto;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  z-index: 1;

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: white;
    border-color: white;
  }

  .ant-btn-danger:hover,
  .ant-btn-danger:focus,
  .ant-btn-danger:active {
    border-color: transparent;
  }

  .ant-btn-link:hover,
  .ant-btn-link:focus,
  .ant-btn-link:active {
    border-color: transparent;
  }
`;

export default MeetingControls;
