import bgImage from "../../image/signin2.svg";

import { build_color_palette } from "./colors";
import {
  fontSizeWeight,
  smoothScrollCSS,
  emConverter,
  chartColors,
  chartCategoryColor,
  chartSectorColor,
} from "./style-util";

const palette_config = {
  //Product
  "Primary Color": "blue-6",
  "Primary Color Hover": "blue-5",
  "Primary Color Pressed/Active": "blue-7",
  "Primary Color Background Hover": "blue-1",
  "Primary Color Border Color": "blue-3",
  //Neutral
  Title: "grey-9",
  "Primary text": "grey-8",
  "Secondary text": "grey-7",
  Disable: "grey-6",
  Border: "grey-5",
  Dividers: "grey-4",
  Background: "grey-3",
  "Table header": "grey-2",
  "Content Background": "grey-1",
  //Alert
  "Info Primary": "blue-6",
  "Info Primary Hover": "blue-5",
  "Info Border": "blue-3",
  "Info Background": "blue-1",
  "Success Primary": "green-6",
  "Success Primary Hover": "green-5",
  "Success Border": "green-3",
  "Success Background": "green-1",
  "Warning Primary": "gold-6",
  "Warning Primary Hover": "gold-5",
  "Warning Border": "gold-3",
  "Warning Background": "gold-1",
  "Error Primary": "red-6",
  "Error Primary Hover": "red-5",
  "Error Border": "red-3",
  "Error Background": "red-1",
  "Status active": "green-5",
  "Status inactive": "grey-6",
  "Status pending": "orange-5",
  "Status disabled": "red-5",
};

// colors conform to antd tag presets
const status_config = {
  A: "green",
  I: "",
  P: "orange",
  D: "red",
};

const palette = Object.assign(build_color_palette(palette_config), { statusTag: status_config });
const text = fontSizeWeight();
const scrollBar = smoothScrollCSS(palette["Dividers"], palette["Secondary text"]);

var theme = {
  font: "Assistant",
  fontMonospace: "Consolas, Liberation Mono, Menlo, Courier, monospace",

  panelLogo: "siteLogoDark",
  panelBG: bgImage,
  panelBGColor: "rgba(178, 167, 161, 0.6)",
  contentBGColor: "#f0f2f5",

  headerColor: "#FFF",
  headerText: palette["Primary text"],
  sidebarLogo: "siteLogo",
  sidebarIcon: "siteIcon",
  sidebarTheme: "dark",

  color: palette,
  text: text,
  status: status_config,
  scrollBar: scrollBar,
  emConverter: emConverter,

  chartColor: chartColors,
  chartCategoryColor: chartCategoryColor,
  chartSectorColor: chartSectorColor,
  videoGrid: {
    bgd: "black",
  },
  colors: {
    greys: {
      greys100: "black",
    },
  },
  fontSizes: {
    text: {
      fontSize: 16,
    },
  },
};

try {
  var local = require("./theme_local.js");

  theme = { ...theme, ...local.theme };
} catch (e) {}

export default theme;
