import React, { createRef, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Space, Spin } from "antd";
import { injectIntl } from "react-intl";
import styled, { withTheme, ThemeProvider } from "styled-components";
import { lightTheme, MeetingProvider } from "amazon-chime-sdk-component-library-react";
import Routes from "./MeetingRoutes";
import MeetingContext from "./MeetingContext";
import AppContext from "../../AppContext";
import Chat from "../chat";

const MeetingContainer = withTheme(
  injectIntl(({ appContext, context }) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const meeting = createRef({});
    const dragger = createRef({});
    const defaultBounds = {
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
    };

    //States
    const [moved, setMoved] = useState(false);
    const [prevCoords, setPrevCoords] = useState(null);
    const [bounds, setBounds] = useState(defaultBounds);
    const [draggable, setDraggable] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [onCall, setOnCall] = useState(false);
    const [recording, setRecording] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [meBlockVisible, setMeBlockVisible] = useState(true);
    const [chatroomVisible, setChatroomVisible] = useState(false);
    const [chatroom, setChatroom] = useState({ uuid: context.chatroom });
    const [, updater] = useState(false);

    useEffect(() => {}, [onCall]);

    const onStart = data => {
      const targetRect = meeting?.current?.getBoundingClientRect();
      if (targetRect) {
        let calculateBottom = clientHeight - (targetRect.bottom - data.y);
        let calculateLeft = -targetRect.left - data.x;
        let calculateRight = clientWidth - (targetRect.right - data.x);
        let calculateTop = -targetRect.top + data.y;
        if (calculateBottom < 0) {
          calculateBottom = 0;
        }
        if (calculateLeft < 0) {
          calculateLeft = 0;
        }
        if (calculateRight < 0) {
          calculateRight = 0;
        }
        setMoved(true);
        setBounds({
          left: calculateLeft,
          right: calculateRight,
          top: calculateTop,
          bottom: calculateBottom,
        });
      }
    };

    const setToFullScreen = () => {
      if (context.checkPage("room")) {
        setFullscreen(true);
        setPrevCoords({
          x: dragger.current.state?.x ?? 0,
          y: dragger.current.state?.y ?? 0,
        });
        setBounds({ left: 0, right: 0, top: 0, bottom: 0 });
        dragger.current.state.x = 0;
        dragger.current.state.y = 0;
      }
    };

    const shrinkScreen = () => {
      setFullscreen(false);
      if (prevCoords) {
        dragger.current.state.x = prevCoords.x;
        dragger.current.state.y = prevCoords.y;
        setPrevCoords(null);
      } else {
        setBounds({ left: 0, right: 0, top: 0, bottom: 0 });
        if (dragger.current?.state?.x && dragger.current?.state?.y) {
          dragger.current.state.x = prevCoords.clientWidth - 240;
          dragger.current.state.y = -20;
        }
      }
    };

    const requestRecording = async () => {
      if (!context.canRecord) {
        await context.requestRecording();
      }
    }

    const startRecording = async () => {
      if (!recording) {
        await context.startContextRecording();
        setRecording(true);
      }
    };

    const stopRecording = async () => {
      if (recording) {
        await context.stopContextRecording();
        setRecording(false);
      }
    };

    const resetComponent = () => {
      setToFullScreen();
      setPrevCoords(null);
      setBounds(defaultBounds);
      setDraggable(false);
      setFullscreen(true);
      setLoading(true);
      setOnCall(false);
      setRecording(false);
      setChatroom({});
      setSettingsVisible(false);
    };

    let meetingContext = {
      ...context,
      requestRecording: requestRecording,
      startLoading: () => setLoading(true),
      stopLoading: () => setLoading(false),
      startRecording: startRecording,
      stopRecording: stopRecording,
      showSettings: () => setSettingsVisible(true),
      hideSettings: () => setSettingsVisible(false),
      settingsVisible: settingsVisible,
      setToFullScreen: setToFullScreen,
      shrinkScreen: shrinkScreen,
      setPrevCoords: setPrevCoords,
      fullscreen: fullscreen,
      loading: loading,
      meBlockVisible: meBlockVisible,
      setMeBlockVisible: setMeBlockVisible,
      setCall: setOnCall,
      resetComponent: resetComponent,
      showChat: () => setChatroomVisible(true),
    };

    return (
      <ThemeProvider theme={lightTheme}>
        <MeetingProvider>
          <AppContext.Provider value={appContext}>
            <MeetingContext.Provider value={meetingContext}>
              <Draggable
                ref={dragger}
                bounds={bounds}
                onStart={(event, uiData) => onStart(uiData)}
                disabled={context.checkPage("lobby") || fullscreen || draggable}
                {...(draggable && !moved ? { position: { x: clientWidth - 240, y: -20 } } : {})}
              >
                <MeetingWrapper
                  {...(fullscreen
                    ? {
                        style: {
                          width: "97%",
                          height: "90%",
                          cursor: "default",
                          border: "solid thin black",
                          left: "30px",
                        },
                      }
                    : { style: { borderRadius: "2rem" } })}
                  onMouseOver={() => draggable && setDraggable(false)}
                  onMouseOut={() => !fullscreen && setDraggable(true)}
                  ref={meeting}
                  hidden={!context.isVisible()}
                >
                  <Spin spinning={loading} style={{ height: "100%", width: "100%" }}>
                    <Space direction="vertical" style={{ height: "100%", width: "100%" }} className="innerSpacing">
                      <Routes />
                    </Space>
                  </Spin>
                </MeetingWrapper>
              </Draggable>
              {context.chatSocket?.url && (
                <Chat
                  chatSocket={context.chatSocket}
                  noButton
                  noSelectable
                  selectedChatroom={chatroom}
                  setSelectedChatroom={() => {}}
                  setVisible={setChatroomVisible}
                  updateOuter={() => updater(old => !old)}
                  visible={chatroomVisible}
                />
              )}
            </MeetingContext.Provider>
          </AppContext.Provider>
        </MeetingProvider>
      </ThemeProvider>
    );
  })
);

const MeetingWrapper = styled.div`
  width: 200px;
  height: 200px;
  cursor: move;
  background: white;
  position: fixed;
  bottom: 20px;
  text-align: center;
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
  .ant-space-item {
    height: 100%;
  }

  .spaceDots {
    .ant-space-item {
      background: white;
      border-radius: 100%;
      height: 0.2em;
    }
  }

  .audioAndFullscreen {
    .ant-space-item {
      &:first-child {
        flex: auto;
        text-align: left;
      }
      &:last-child {
        margin-right: 0.5em;
      }
    }
  }
`;

export default MeetingContainer;
