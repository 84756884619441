/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { Result } from "antd";
import { FormattedMessage } from "react-intl";

import SidebarView from "./template/sidebar";
import ContentCard from "./template/ContentCard";

import AppContext from "../AppContext";

class TestPage extends Component {
  static contextType = AppContext;

  render() {
    return (
      <SidebarView layout>
        <ContentCard>
          <Result status="warning" title={<FormattedMessage id={"Page Not Found"} />} />
        </ContentCard>
      </SidebarView>
    );
  }
}

export default injectIntl(TestPage);
