import logoDarkEN from "../image/logo-dark-en.svg";
import logoLightEN from "../image/logo-light-en.svg";
import logoDarkFR from "../image/logo-dark-fr.svg";
import logoLightFR from "../image/logo-light-fr.svg";
import logoIcon from "../image/icon.svg";

var config = {};
var default_config = {
  apiGateway: {
    URL: "",
    REGION: "",
  },
  jwt: {
    sign_key: "sign_key_here",
  },
  canadaPostApiKey: "KA92-CX29-GZ99-HN59",
  defaultLanguage: "en",
  sso_enabled: false,
  sso_config: {
    auth_url: "",
    identity_provider: "",
    scope: "openid+aws.cognito.signin.user.admin",
    grant_type: "authorization_code",
    client_id: "",
    redirect_uri: "",
  },
};

var siteConfig = {
  title: "Wells Financial Partners",
  siteLogo: {
    EN: logoLightEN,
    FR: logoLightFR,
  },
  siteLogoDark: {
    EN: logoDarkEN,
    FR: logoDarkFR,
  },
  siteIcon: logoIcon,
  defaultPage: "/",
  footerText: "Four Eyes Financial ©",
  passwordLength: 8,
  company_name: "Wells Financial",
  dzClientGroupSelect: false,
  defaultTheme: "light",
};

var componentConfig = {
  // Example use : dz_dashboard: () => import("../custom/dz/dashboard")
};

try {
  var local = require("./config_local.js");

  config = { ...default_config, ...local.config };
  siteConfig = { ...siteConfig, ...local.siteConfig };
  componentConfig = { ...componentConfig, ...local.componentConfig };
} catch (e) {}

export { config, siteConfig, componentConfig };
