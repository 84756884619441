import React, { Fragment, useContext } from "react";
import { Avatar } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import {
  Flex,
  LocalVideo,
  useContentShareState,
  useLocalVideo,
  useRemoteVideoTileState,
  useRosterState,
  useToggleLocalMute,
  VideoTile,
} from "amazon-chime-sdk-component-library-react";
import MeetingContext from "../../MeetingContext";
import NamePlate from "./namePlate";
import { CloseOutlined } from "@ant-design/icons";

const MeBlock = withTheme(
  injectIntl(({ intl, noCameras = [], theme }) => {
    const { attendee, attendees, fullscreen, meBlockVisible, setMeBlockVisible } = useContext(MeetingContext);
    const { tileId: contentTileId } = useContentShareState();
    const { tiles } = useRemoteVideoTileState();
    const { isVideoEnabled } = useLocalVideo();
    const { muted } = useToggleLocalMute();
    const { roster } = useRosterState();

    const getInitials = name => {
      if (name) {
        const names = name.split(" ");
        return names.map(item => item?.[0]?.toUpperCase?.())?.join?.("");
      } else {
        return "UN";
      }
    };

    const generateCSS = () => {
      let css = "";
      if (!contentTileId && (tiles?.length > 0 || noCameras?.length > 0)) {
        css += "height: 200px; right: 50px; bottom: 100px; width: 300px; position: absolute;";
      }
      return css;
    };

    const minimizeMe = () => {
      if (Object.keys(roster ?? {})?.length > 1) {
        setMeBlockVisible(false);
      }
    };

    return fullscreen ? meBlockVisible && (
      <Flex
        css={`
          height: 100%;
          background: #3f4149;
          box-shadow: 0px 0px 0px 1px ${theme.color.grey["grey-8"]};
          ${generateCSS()}
        `}
        layout="fill-space-centered"
      >
        {Object.keys(roster ?? {})?.length > 1 && (
          <CloseOutlined
            style={{
              position: "absolute",
              zIndex: 1,
              top: "10px",
              right: "10px",
              color: theme?.color?.grey?.["grey-1"],
            }}
            onClick={minimizeMe}
          />
        )}
        {isVideoEnabled ? (
          <LocalVideo />
        ) : (
          <Fragment>
            <Avatar
              size={96}
              style={{ zIndex: 1, position: "absolute", fontSize: "1.5em" }}
              src={attendees?.[attendee]?.Avatar?.url}
            >
              {getInitials(attendees?.[attendee]?.Name)}
            </Avatar>
            <VideoTile style={{ background: "#3F4149" }} />
          </Fragment>
        )}
        <NamePlate muted={muted} text={<FormattedMessage id="Me" />} />
      </Flex>
    ) : (
      <Flex
        css={`
          height: 100%;
          background: #3f4149;
          box-shadow: 0px 0px 0px 1px ${theme.color.grey["grey-8"]};
          width: 100%;
        `}
        layout="fill-space-centered"
      >
        {isVideoEnabled ? (
          <LocalVideo />
        ) : (
          <Fragment>
            <Avatar
              size={96}
              style={{ zIndex: 1, position: "absolute", fontSize: "1.5em" }}
              src={attendees?.[attendee]?.Avatar?.url}
            >
              {getInitials(attendees?.[attendee]?.Name)}
            </Avatar>
            <VideoTile style={{ background: "#3F4149" }} />
          </Fragment>
        )}
        <NamePlate muted={muted} text={<FormattedMessage id="Me" />} />
      </Flex>
    );
  })
);

export default MeBlock;
