/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { Avatar, Checkbox, Collapse, Divider, Layout, Menu, Popover, Space, Dropdown, Select } from "antd";

import TopbarWrapper from "./topbar.style";

import BreadCrumb from "./breadcrumb";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import { invokeApi } from "../../helpers/authHelper";

import { MenuOutlined, UserOutlined, SettingOutlined, LogoutOutlined, BellFilled } from "@ant-design/icons";
import { withTheme } from "styled-components";

import AppContext from "../../AppContext";

import Chat from "../chat";

import SettingsDrawer from "../settings/index";

import { debounce } from "../../helpers/utilities";
import { withRouter } from "react-router-dom";
import { extendCurrenBreadCrumb } from "../../components/menu/breadcrumb";

const { Header } = Layout;

class Topbar extends Component {
  static contextType = AppContext;

  render() {
    return (
      <TopbarWrapper>
        <Header id="topbar-container">
          <div className="topbarContentLeft">
            <MenuOutlined className="collapseSidebarToggle" onClick={this.context.toggleCollapsed} />
            <span className="topbarBreadcrumb">{this.context.isAuthenticated ? <BreadCrumb /> : false}</span>
          </div>
          <Space className="topbarContentRight" size={20}>
            {this.context.active_role === "supervisor" ? <TopbarRegion /> : false}
            {this.context.roles[this.context.active_role]?.name === "overseer" ? <TopbarAdvisors /> : false}
            {this.context.chatSocket?.url && (
              <Chat
                chatSocket={this.context.chatSocket}
                selectedChatroom={this.context.selectedChatroom}
                setSelectedChatroom={this.context.setSelectedChatroom}
                setVisible={this.context.setChatsDrawerVisiblity}
                type={this.context.roles[this.context.active_role]?.name}
                visible={this.context.chatroomsVisible}
              />
            )}
            {this.context.hasPermission("risk.notification.view") ? <TopbarAlerts /> : false}
            {this.context.hasPermission("r4.language_toggle.view") ? <TopbarLanguageToggle /> : false}
            <TopbarUser />
          </Space>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default withTheme(Topbar);

export class TopbarUser extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      settingsVisible: false,
    };
  }

  handleLogout = () => {
    this.context.signout_user();
  };
  openSettingsDrawer = () => {
    this.setState({
      settingsVisible: true,
    });
  };
  closeSettingsDrawer = () => {
    this.setState({
      settingsVisible: false,
    });
  };
  render() {
    const roles = Object.keys(this.context.roles).map(r => {
      let roleInfo = this.context.roles[r] !== undefined ? this.context.roles[r] : false;
      if (roleInfo === false) return false;
      return (
        <div key={"w_" + r} className="panelIndent">
          <Checkbox
            key={r}
            checked={r === this.context.active_role}
            onChange={() => {
              this.context.changeActiveRole(r);
              if (roleInfo.page !== undefined && r !== this.context.active_role) {
                setTimeout(() => this.context.trackLyticsPage(window.location.pathname), 1000);
                this.context.navigate(roleInfo.page);
              }
            }}
          >
            {roleInfo.iso_language !== undefined
              ? this.context.intlFormatData(roleInfo.iso_language, roleInfo?.display_name ?? roleInfo.name)
              : roleInfo.name !== undefined
              ? roleInfo.name
              : r}
          </Checkbox>
        </div>
      );
    });

    const menu = (
      <>
        <Menu selectedKeys={[]}>
          <Menu.Item disabled>
            <div className="menuItem">
              <FontAwesomeIcon className="menuIcon fa-fw" icon={["fal", "history"]} />
              <FormattedMessage id="Login History" />
            </div>
          </Menu.Item>
        </Menu>
        <Collapse expandIconPosition="right" defaultActiveKey="region" ghost>
          <Collapse.Panel
            header={
              <div className="panelHdr">
                <FontAwesomeIcon className="menuIcon fa-fw" icon={["fal", "users"]} />
                <FormattedMessage id="User Roles" />
              </div>
            }
            key="region"
          >
            {Object.keys(this.context.roles).length > 1 ? roles : false}
          </Collapse.Panel>
        </Collapse>
        <Menu selectedKeys={[]}>
          <Menu.Item
            disabled={this.context.roles[this.context.active_role]?.name !== "advisor"}
            onClick={() => {
              this.openSettingsDrawer();
            }}
          >
            <div className="menuItem">
              <SettingOutlined style={{ width: "1.25em" }} />
              <FormattedMessage id="Settings" />
            </div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={this.handleLogout}>
            <div className="menuItem">
              <LogoutOutlined style={{ width: "1.25em" }} />
              <FormattedMessage id="Sign Out" />
            </div>
          </Menu.Item>
        </Menu>
      </>
    );

    return (
      <>
        <Popover
          content={menu}
          placement="bottomRight"
          getPopupContainer={() => document.getElementById("topbar-container")}
          overlayClassName="topbarUserDropdownOverlayContainer"
        >
          <div className="topbarUserIconContainer">
            <Avatar
              icon={<UserOutlined />}
              src={
                this.context.user_info !== undefined &&
                this.context.user_info.photo_url !== undefined &&
                this.context.user_info.photo_url !== null
                  ? this.context.user_info.photo_url
                  : false
              }
            />
          </div>
        </Popover>
        <SettingsDrawer visible={this.state.settingsVisible} closeDrawer={this.closeSettingsDrawer} />
      </>
    );
  }
}

export class TopbarRegion extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      regions: [],
    };
  }

  componentDidMount() {
    this.getRegions();
  }

  async getRegions() {
    return invokeApi({
      path: "/rest/region",
      method: "GET",
      body: {},
      onSuccess: r => this.setupRegions(r),
      onError: e => this.setState({ data_loading_error: e, isLoading: false }),
    });
  }

  setupRegions = regions => {
    regions.push({
      region_id: "all",
      region_name: "All",
    });
    let sregions = regions.sort((a, b) => (a.region_name > b.region_name ? 1 : b.region_name > a.region_name ? -1 : 0));
    this.setState({ regions: sregions, isLoading: false });
  };

  render() {
    // TODO: do we want to have an all region added to the DB or manually add one here
    const regions = this.state.regions.map(r => {
      return (
        <div key={"w_" + r.region_id} className="panelIndent">
          <Checkbox
            key={r.region_id}
            checked={r.region_id === this.context.active_region}
            onChange={() => {
              this.context.changeActiveRegion(r.region_id);
            }}
          >
            {r.region_name}
          </Checkbox>
        </div>
      );
    });

    const menu = (
      <Collapse expandIconPosition="right" defaultActiveKey="region" ghost>
        <Collapse.Panel
          header={
            <div className="panelHdr">
              <FontAwesomeIcon className="fa-fw" icon={["fal", "map-marker"]} /> <FormattedMessage id="Select Region" />
            </div>
          }
          key="region"
        >
          {regions}
        </Collapse.Panel>
        <Divider />
        <Collapse.Panel
          header={
            <div className="panelHdr">
              <FontAwesomeIcon className="fa-fw" icon={["fal", "user-friends"]} />{" "}
              <FormattedMessage id="Select Team Member" />
            </div>
          }
          key="team"
        ></Collapse.Panel>
      </Collapse>
    );

    return (
      <Popover
        content={menu}
        trigger="click"
        placement="bottomRight"
        getPopupContainer={() => document.getElementById("topbar-container")}
        overlayClassName="topbarRegionDropdownOverlayContainer"
      >
        <div className="topbarRegionContainer">
          <FormattedMessage id="Region" />
        </div>
      </Popover>
    );
  }
}

export class TopbarAlerts extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="topbarAlertContainer">
        <BellFilled />
      </div>
    );
  }
}

export class TopbarLanguageToggle extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {};
  }
  onClick = lang => {
    this.context.changeLanguage(lang);
  };

  render() {
    const menu = (
      <Menu>
        {this.context
          .getLanguages()
          ?.sort((a, b) => a?.full_toggle_label?.localeCompare(b?.full_toggle_label))
          ?.map(lang => (
            <Menu.Item onClick={() => this.onClick(lang?.value)} key={lang?.key}>
              <FormattedMessage id={lang?.full_toggle_label} />
            </Menu.Item>
          ))}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} arrow trigger="click" className="topbarLanguageDropdown">
        <div className="topbarLanguageLabel">{this.context.currentLanguage()}</div>
      </Dropdown>
    );
  }
}

class TopbarAdvisorsCOM extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      advisors: [],
      isLoading: false,
      selectedAdvisor: null,
    };
  }

  handleSearch = search => {
    this.setState({ isLoading: true });
    invokeApi({
      path: "/rest/advisor",
      method: "GET",
      queryParams: {
        search: search,
      },
      cancelToken: this.signal.token,
      onSuccess: r => {
        this.setState({ advisors: r, isLoading: false });
      },
    });
  };

  componentWillUnmount() {
    this.signal.cancel();
  }

  componentDidMount() {
    this.signal = this.context.axiosCancel();
    let advisor = JSON.parse(sessionStorage.getItem("overseer_selected_advisor"));
    if (advisor) {
      this.setState({ selectedAdvisor: advisor.advisor_uuid, advisors: [advisor] });
    }
  }

  handleSelect = value => {
    let advisor = this.state.advisors?.find(a => a.advisor_uuid === value);
    sessionStorage.setItem("overseer_selected_advisor", JSON.stringify(advisor));
    this.setState({ selectedAdvisor: value });
    this.context.updateOversightContact(value);
    extendCurrenBreadCrumb(this, null); // flush out any currently saved data
  };

  render() {
    return (
      <Select
        bordered={false}
        placeholder={<FormattedMessage id="Search for advisor" />}
        showSearch
        onSearch={debounce(this.handleSearch)}
        style={{ width: "300px" }}
        options={this.state.advisors?.map(a => ({
          label: a?.display_name,
          value: a?.advisor_uuid,
          key: a?.advisor_uuid,
        }))}
        getPopupContainer={e => e.parentElement}
        filterOption={false}
        defaultActiveFirstOption={false}
        onSelect={this.handleSelect}
        value={this.state.selectedAdvisor}
      />
    );
  }
}

const TopbarAdvisors = withRouter(TopbarAdvisorsCOM);
