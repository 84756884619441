import React, { Fragment, useContext } from "react";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { AudioMutedOutlined } from "@ant-design/icons";
import { RemoteVideo, useAttendeeStatus, useRemoteVideoTileState } from "amazon-chime-sdk-component-library-react";
import MeetingContext from "../../MeetingContext";

const VideoTile = withTheme(
  injectIntl(({ tile }) => {
    const { attendees } = useContext(MeetingContext);
    const { tileIdToAttendeeId } = useRemoteVideoTileState();
    const the_attendee = tileIdToAttendeeId?.[tile];
    const { muted: attendeeMuted } = useAttendeeStatus(the_attendee);

    return (
      <RemoteVideo
        tileId={tile}
        name={
          attendeeMuted ? (
            <Fragment>
              {attendees?.[the_attendee]?.Name} <AudioMutedOutlined />
            </Fragment>
          ) : (
            attendees?.[the_attendee]?.Name
          )
        }
      />
    );
  })
);

export default VideoTile;
