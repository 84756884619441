/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { Drawer, Button, Tabs, Modal } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import AppContext from "../../AppContext";
import SettingsDrawerWrapper from "./index.style";
import { invokeApi, axiosCancel } from "../../helpers/authHelper";
import Header from "./header";
import About from "./about";

const { TabPane } = Tabs;

class SettingsDrawer extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      avatarEditing: false,
      contact: {},
      descriptionEditing: false,
      formTouched: false,
      showUploader: true,
    };
  }
  signal = axiosCancel();

  componentDidMount() {
    this.getContactInfo();
  }

  getContactInfo = () => {
    return invokeApi({
      path: `rest/user/${this.context.user_info?.uuid}/contact_info`,
      method: "GET",
      cancelToken: this.signal.token,
      onSuccess: r => {
        this.setState({ contact: r });
      },
      onError: e => {
        console.log(e);
      },
    });
  };

  setAvatarEditing = values => {
    this.setState({ avatarEditing: values });
  };

  setDescriptionEditing = values => {
    this.setState({ descriptionEditing: values });
  };

  setFormTouched = values => {
    this.setState({ formTouched: values });
  };

  clearState = () => {
    this.setState({ descriptionEditing: false, avatarEditing: false, formTouched: false });
    this.props.closeDrawer();
  };

  confirmClose = () => {
    const { confirm } = Modal;
    if (this.state.formTouched || this.state.descriptionEditing || this.state.avatarEditing) {
      confirm({
        width: 500,
        title: this.props.intl.formatMessage({ id: "Are you sure you want to discard these changes?" }),
        content: this.props.intl.formatMessage({ id: "If you continue, changes will be lost." }),
        okText: this.props.intl.formatMessage({ id: "Yes" }),
        okButtonProps: {
          type: "primary",
        },
        cancelText: this.props.intl.formatMessage({ id: "No" }),
        cancelButtonProps: {
          type: "default",
        },
        onOk: () => {
          this.clearState();
        },
      });
    } else {
      this.props.closeDrawer();
    }
  };

  render() {
    let contact_info = {
      display_name: this.context.user_info?.display_name,
      first_name: this.context.user_info?.first_name,
      last_name: this.context.user_info?.last_name,
      middle_name: this.context.user_info?.middle_name,
      job_title: this.context.user_info?.job_title,
      email: this.state.contact.email,
      phone_number: this.state.contact.phone_number,
      photo_url: this.context.user_info?.photo_url,
      photo_uuid: this.context.user_info?.photo_uuid,
    };
    return (
      <Drawer
        title={
          <span className="header2">
            <FormattedMessage id="Settings" />
          </span>
        }
        width={600}
        visible={this.props.visible}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
            <Button
              onClick={() => {
                this.confirmClose();
              }}
            >
              <FormattedMessage id="Close" />
            </Button>
          </div>
        }
        onClose={() => {
          this.confirmClose();
        }}
        forceRender
      >
        <SettingsDrawerWrapper>
          <Header
            intl={this.props.intl}
            contact_info={contact_info}
            avatarEditing={this.state.avatarEditing}
            descriptionEditing={this.state.descriptionEditing}
            formTouched={this.state.formTouched}
            setAvatarEditing={this.setAvatarEditing}
            setDescriptionEditing={this.setDescriptionEditing}
            setFormTouched={this.setFormTouched}
            closeDrawer={this.props.closeDrawer}
          />
          <Tabs>
            <TabPane tab={<FormattedMessage id="About" />} key="about">
              <About
                intl={this.props.intl}
                avatarEditing={this.state.avatarEditing}
                contact_info={contact_info}
                showUploader={this.state.showUploader}
                setAvatarEditing={this.setAvatarEditing}
                closeDrawer={this.props.closeDrawer}
              />
            </TabPane>
          </Tabs>
        </SettingsDrawerWrapper>
      </Drawer>
    );
  }
}

export default withTheme(injectIntl(SettingsDrawer));
