/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React from "react";
import { Typography, Collapse } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { blue } from "@ant-design/colors";

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: false, errorInfo: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error);
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ padding: "1em" }}>
          <Title level={4} type="secondary">
            <FormattedMessage id="Error" />
          </Title>
          <Collapse bordered={false} defaultActiveKey={[]} style={{ background: "transparent", border: 0 }}>
            <Panel
              header={<FormattedMessage id="Details" />}
              key="1"
              style={{ background: "transparent", border: 0, borderBottom: "0px solid transparent", opacity: 0.7 }}
            >
              <Paragraph>{this.state.error.toString()}</Paragraph>
              <Paragraph>{this.state.error.stack}</Paragraph>
            </Panel>
          </Collapse>
        </div>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundary);

class ComponentErrorBoundaryC extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: false, errorInfo: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error);
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ padding: "1em" }}>
          <Title
            level={5}
            type="danger"
            copyable={{
              text: this.state.error.stack,
              icon: [
                <Text style={{ fontSize: "0.8em", color: blue.primary }}>
                  <FormattedMessage id="Copy" />
                </Text>,
                <Text type="success" style={{ fontSize: "0.8em" }}>
                  <FormattedMessage id="Copied" />
                </Text>,
              ],
              tooltips: false,
            }}
          >
            {this.state.error?.toString()}
          </Title>
          <Collapse bordered={false} ghost defaultActiveKey={[]}>
            <Panel header={<FormattedMessage id="Details" />} key="1">
              <Paragraph style={{ fontSize: "10px", fontFamily: "monospace" }}>
                <pre>{String(this.state.error.stack)?.replace(" at ", "\nat ")}</pre>
              </Paragraph>
            </Panel>
          </Collapse>
        </div>
      );
    }

    return this.props.children;
  }
}

export const ComponentErrorBoundary = injectIntl(ComponentErrorBoundaryC);
