import React from "react";
import styled, { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import MicActivity from "./microphoneActivityBubble";
import CallButtons from "./callButtons";
import ExtraButtons from "./extraButtons";
import { useToggleLocalMute } from "amazon-chime-sdk-component-library-react";


const LobbyControls = withTheme(
  injectIntl(({ intl, manager }) => {
    const { muted } = useToggleLocalMute();

    return (
      <Wrapper>
        <MicActivity hidden={muted} />
        <CallButtons hideEndCall manager={manager} />
        <ExtraButtons hideRecording hideSharing hideShrink manager={manager} spacingSize={0} />
      </Wrapper>
    );
  })
);

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 44% auto auto;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  z-index: 1;

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: white;
    border-color: white;
  }

  .ant-btn-danger:hover,
  .ant-btn-danger:focus,
  .ant-btn-danger:active {
    border-color: transparent;
  }

  .ant-btn-link:hover,
  .ant-btn-link:focus,
  .ant-btn-link:active {
    border-color: transparent;
  }
`;

export default LobbyControls;
