import React from "react";
import { Button, Select, Space } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components";
import {
  Label,
  useVideoInputs,
  useAudioOutputs,
  useAudioInputs,
} from "amazon-chime-sdk-component-library-react";

import TestSound from "../utils/TestSound";

const DeviceSelection = withTheme(
  injectIntl(({ manager }) => {
    const CameraDevices = () => {
      const { devices, selectedDevice } = useVideoInputs();

      const handleChange = async deviceId => {
        await manager.selectVideoInputDevice(deviceId);
      };
      
      return (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Label>
            <FormattedMessage id="Camera" />
          </Label>
          <Select value={selectedDevice} style={{ width: "100%" }} onSelect={handleChange}>
            {devices.map(device => (
              <Select.Option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </Select.Option>
            ))}
          </Select>
        </Space>
      );
    };

    const MicrophoneDevices = () => {
      const { devices, selectedDevice } = useAudioInputs();

      const handleChange = async deviceId => {
        await manager.selectAudioInputDevice(deviceId);
      };

      return (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Label>
            <FormattedMessage id="Microphone" />
          </Label>
          <Select value={selectedDevice} style={{ width: "100%" }} onSelect={handleChange}>
            {devices.map(device => (
              <Select.Option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </Select.Option>
            ))}
          </Select>
        </Space>
      );
    };

    const SpeakerDevices = () => {
      const { devices, selectedDevice } = useAudioOutputs();

      const handleChange = async deviceId => {
        await manager.selectAudioOutputDevice(deviceId);
      };

      const handleTestSpeaker = () => {
        new TestSound(selectedDevice);
      };

      return (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Label>
            <FormattedMessage id="Speaker" />
          </Label>
          <Space className="extraButton" style={{ width: "100%" }}>
            <Select
              value={selectedDevice}
              style={{ width: "100%", marginRight: "1rem" }}
              onSelect={handleChange}
            >
              {devices.map(device => (
                <Select.Option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </Select.Option>
              ))}
            </Select>
            <Button shape="round" onClick={handleTestSpeaker}>
              <FormattedMessage id="Test" />
            </Button>
          </Space>
        </Space>
      );
    };

    return (
      <DevicesContainer>
        <Space direction="vertical" style={{ width: "100%" }}>
          <CameraDevices />
          <MicrophoneDevices />
          <SpeakerDevices />
        </Space>
      </DevicesContainer>
    );
  })
);

const DevicesContainer = styled.div`
  margin: 1rem;

  .extraButton {
    .ant-space-item {
      &:first-child {
        width: 100%;
      }
    }
  }
`;

export default DeviceSelection;
