import styled from "styled-components";

const PanelStyleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: url(${props => props.theme.panelBG}) no-repeat center center;
  background-size: cover;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${props => props.theme.panelBGColor};
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .panelViewContainer {
    width: 500px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
    position: relative;
  }

  .panelViewContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px 50px;
    position: relative;
    background-color: ${props => props.theme.color.grey["grey-1"]};

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 70px 20px;
    }

    .panelViewLogo {
      width: 100%;
      display: flex;
      margin-bottom: 50px;
      justify-content: center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${props => props.theme.color['Disable']};

        img {
          height: 100px;
          max-height: 100px;
        }
      }
    }

    .panelViewTitle {
      font-size: 1.25em;
      font-weight: 400;
      margin-bottom: 0.5em;
      text-align: center;
    }

    .panelViewSubTitle {
      font-size: 0.875em;
      font-weight: 400;
      width: 80%;
      text-align: center;
      margin: 0 auto 1em;
    }

    .panelViewSubContent {
      text-align: center;

      .panelViewSubText {
        font-size: 12px;
        color: ${props => props.theme.color['Disable']};
        margin-bottom: 10px;
        text-decoration: none;
      }

      a.panelViewSubText{
        &:hover{
          color: ${props => props.theme.color['Primary Color Hover']};
        }
      }
    }
  }

  .panelViewLoading {
    text-align: center;
    margin-top: 15px;
    font-size: ${props => props.theme.text.f16};

    .panelViewLoadingIcon {
      color: ${props => props.theme.color["Info Primary"]};
    }
  }
`;

export default PanelStyleWrapper;
