import React, { useContext } from "react";
import { Badge, Button, message, Modal, Space } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { useContentShareControls, useRosterState } from "amazon-chime-sdk-component-library-react";
import { withTheme } from "styled-components";
import MeetingContext from "../MeetingContext";
import MeetingControl from "./meetingControl";
import DeviceSelection from "./DeviceSelection";
import AppContext from "../../../AppContext";

const ExtraButtons = withTheme(
  injectIntl(
    ({ hideRecording = false, hideSharing = false, hideShrink = false, intl, manager, spacingSize = 20, visible = true }) => {
      const {
        canRecord,
        chatSocket,
        hideSettings,
        meBlockVisible,
        setMeBlockVisible,
        recording,
        requestRecording,
        settingsVisible,
        showChat,
        showSettings,
        shrinkScreen,
        startRecording,
        stopRecording,
      } = useContext(MeetingContext);
      const { active_role, hasPermission, roles } = useContext(AppContext);

      const { roster } = useRosterState();
      const { isLocalUserSharing, toggleContentShare } = useContentShareControls();

      const screenShareValues = {
        icon: isLocalUserSharing ? "stop" : "tv-alt",
        text: isLocalUserSharing ? "Stop sharing" : "Share content",
        iconColor: "white",
        buttonType: isLocalUserSharing ? "danger" : "ghost",
      };

      const recordingValues = {
        icon: "record-vinyl",
        text: canRecord ? (recording ? "Stop Recording" : "Record Meeting") : "Request Recording",
        iconColor: recording ? "red" : "white",
        onClick: canRecord ? (recording ? stopRecording : startRecording) : requestRecording,
      };

      const toggleShrink = () => {
        let string = "";
        if (isLocalUserSharing) {
          string = "You are sharing your screen";
        }
        if (recording) {
          if (string !== "") {
            string = "You are recording your session";
          } else {
            string += " and recording your session";
          }
        }
        if (string !== "") {
          message.info({
            content: intl.formatMessage({ id: string }),
            duration: 3,
          });
        }
        shrinkScreen();
      };

      return (
        <Space size={spacingSize} hidden={!visible} style={{ justifyContent: "flex-end" }}>
          <MeetingControl
            buttonText={{ icon: ["fal", "plus-square"], color: "white" }}
            onClick={() => setMeBlockVisible(true)}
            placement="topRight"
            title="Show Camera"
            type="ghost"
            visible={!hideShrink && !meBlockVisible && Object.keys(roster ?? {})?.length > 1}
          />
          <MeetingControl
            buttonText={{ icon: ["fal", "compress-arrows-alt"], color: "white" }}
            onClick={toggleShrink}
            title="Shrink Video"
            visible={!hideShrink}
          />
          {hasPermission(`chime.video.${roles?.[active_role]?.name}.screen_share`) && (
            <MeetingControl
              buttonText={{ icon: ["fal", screenShareValues?.icon], color: screenShareValues?.iconColor }}
              onClick={toggleContentShare}
              title={screenShareValues?.text}
              type={screenShareValues?.buttonType}
              visible={!hideSharing}
            />
          )}
          {hasPermission(`chime.chat.video.${roles?.[active_role]?.name}.join`) && chatSocket?.connection && (
            <MeetingControl
              buttonText={{ icon: ["fal", "comment"], color: "white" }}
              title="Chat"
              type="ghost"
              visible={!hideShrink}
              {...(!hideShrink && {
                wrapper: children => (
                  <Badge count={chatSocket.counter} onClick={showChat}>
                    {children}
                  </Badge>
                ),
              })}
            />
          )}
          <MeetingControl
            buttonText={{ icon: ["fal", "user-cog"], color: "white" }}
            onClick={showSettings}
            placement="topRight"
            title="Device settings"
            type="ghost"
          />
          {hasPermission(`chime.video.${roles?.[active_role]?.name}.record`) && (
            <MeetingControl
              buttonText={{ icon: ["fal", recordingValues?.icon], color: recordingValues?.iconColor }}
              onClick={recordingValues?.onClick}
              placement="top"
              title={recordingValues.text}
              visible={!hideRecording}
              type="link"
            />
          )}
          <Modal
            title={<FormattedMessage id="Device Settings" />}
            centered
            visible={settingsVisible}
            onCancel={hideSettings}
            footer={
              <Button type="primary" onClick={hideSettings}>
                <FormattedMessage id="Done" />
              </Button>
            }
          >
            <DeviceSelection manager={manager} />
          </Modal>
        </Space>
      );
    }
  )
);

export default ExtraButtons;
