/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be in added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React from "react";
import { Route } from "react-router-dom";
import r4 from "./r4";
import r7 from "./r7";
import fef from "./fef";
const routes = [...r4, ...r7, ...fef];

export default routes;

export const build_route_map = () => {
  var build_map_par = item => {
    let chdrn = {};
    if (item.children !== undefined) {
      for (let c of item.children) {
        chdrn[c.key] = build_map_par(c);
      }
    }
    return { ...item, children: chdrn };
  };

  var route_map = {};
  for (let r of routes) {
    if (route_map[r.product] === undefined) {
      route_map[r.product] = {};
    }
    route_map[r.product][r.key] = build_map_par(r);
  }
  return route_map;
};

export const get_permissable_routes = (permissions = [], active_role) => {
  // If you have no permissions return none
  if (permissions.length === 0) {
    return [];
  }
  return routes
    .filter(r => (active_role?.product && r.product ? r.product === active_role.product : true))
    .map(singleRoute => {
      // Check permissions on the root route
      if (singleRoute.permissions !== undefined && singleRoute.permissions.some(r => permissions.indexOf(r) >= 0)) {
        // if there are child routes work on them first
        if (singleRoute.children) {
          // Work through child routes
          let perm_children = singleRoute.children.map(childRoute => {
            //Check permissions
            if (childRoute.permissions !== undefined && childRoute.permissions.some(r => permissions.indexOf(r) >= 0)) {
              return childRoute;
            }

            return undefined;
          });

          singleRoute["children"] = perm_children.filter(x => x !== undefined);

          return singleRoute;
        } else {
          return singleRoute;
        }
      }

      return undefined;
    })
    .filter(x => x !== undefined);
};

export const build_route_switch = (permissions = [], active_role = null) => {
  let proutes = get_permissable_routes(permissions, active_role);
  let rjsx = proutes.map(singleRoute => {
    const { key, path, product, regex, exact, component, children } = singleRoute;
    if (children) {
      return children.map(childRoute => {
        const { key, path, product, regex, exact, component } = childRoute;
        return (
          <Route
            exact={exact === false ? false : true}
            key={key}
            path={`/${product}/${regex === undefined ? path : regex}`}
            component={component}
          />
        );
      });
    }
    return (
      <Route
        exact={exact === false ? false : true}
        key={key}
        path={`/${product}/${regex === undefined ? path : regex}`}
        component={component}
      />
    );
  });

  rjsx = rjsx.flat();

  return rjsx;
};
