/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { Button, Upload, notification, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import AppContext from "../../AppContext";
import { AboutWrapper } from "./index.style";
import { invokeApi, axiosCancel } from "../../helpers/authHelper";
import axios from "axios";
import { formatPhoneNumber } from "../ui/phoneNumber/formatPhoneNumber";

const { Dragger } = Upload;

class About extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      status: null,
    };
  }
  signal = axiosCancel();

  // getAvatarStatus = uuid => {
  //   return invokeApi({
  //     path: `rest/attachment/${uuid}/status`,
  //     method: "GET",
  //     cancelToken: this.signal.token,
  //   });
  // };

  // removeAvatar = async uuid => {
  //   try {
  //     await invokeApi({
  //       path: `rest/attachment/${uuid}/archive`,
  //       method: "GET",
  //       cancelToken: this.signal.token,
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     this.setState({ status: null });
  //   }
  // };

  // prepAvatarUpload = info => {
  //   return invokeApi({
  //     path: `rest/advisor/avatar`,
  //     method: "POST",
  //     cancelToken: this.signal.token,
  //     body: {
  //       file_name: info.filename,
  //       file_type: info.file.type,
  //       file_size: info.file.size,
  //     },
  //   });
  // };

  // handleChange = async info => {
  //   try {
  //     this.props.setAvatarEditing(true);
  //     let file = info.file;
  //     let prep_data = await this.prepAvatarUpload(info);
  //     let options = {
  //       headers: prep_data?.upload_info?.headers,
  //     };
  //     await axios.put(prep_data?.upload_info?.url, file, options);
  //     let avtr = await this.checkAvatarStatus(prep_data?.attachment_uuid);
  //     if (avtr === "clean") {
  //       notification.success({
  //         message: this.props.intl.formatMessage({ id: "Profile picture successfully uploaded" }),
  //         duration: 5,
  //       });
  //     } else {
  //       notification.info({
  //         message: this.props.intl.formatMessage({
  //           id: "Image could not be shown at this time, please refresh and try again later",
  //         }),
  //         duration: 5,
  //       });
  //     }
  //   } catch (e) {
  //     if (e.message) {
  //       notification.error({ message: this.props.intl.formatMessage({ id: e.message }), duration: 5 });
  //     }
  //   } finally {
  //     await this.context.updateUserStatus();
  //     this.props.setAvatarEditing(false);
  //     this.setState({ status: null });
  //   }
  // };

  // checkAvatarStatus = async uuid => {
  //   let i = 0;
  //   let resp = await this.getAvatarStatus(uuid);
  //   while (i <= 30 && resp === "pending") {
  //     await this.sleep(3 * 1000);
  //     resp = await this.getAvatarStatus(uuid);
  //     i = i + 3;
  //   }
  //   return resp;
  // };

  // sleep = ms => {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // };

  // resizePhoto = photo => {
  //   return new Promise(resolve => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(photo);
  //     reader.onload = () => {
  //       const canvas = document.createElement("canvas");
  //       const img = document.createElement("img");
  //       img.src = reader.result;
  //       img.onload = () => {
  //         const ctx = canvas.getContext("2d");
  //         canvas.width = 100;
  //         canvas.height = 100;
  //         ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, 100, 100);
  //         canvas.toBlob(resolve);
  //       };
  //     };
  //   });
  // };

  render() {
    // let imageProps = {
    //   name: "avatar.png",
    //   className: "avatar-button",
    //   showUploadList: false,
    //   accept: ".png, .jpeg, .jpg",
    //   disabled: this.props.avatarEditing,
    //   beforeUpload: this.resizePhoto,
    //   customRequest:
    //     (this.props.contact_info?.photo_url ?? "") === ""
    //       ? this.handleChange
    //       : e => {
    //           this.removeAvatar(this.props.contact_info?.photo_uuid);
    //           this.handleChange(e);
    //         },
    // };
    return (
      <AboutWrapper>
        <div className="aboutGrid">
          <div className="fieldLabel">
            <FormattedMessage id="Email" />:
          </div>
          <div className="ellipsis fieldValue">{this.props.contact_info?.email ?? "--"}</div>
        </div>
        <div className="aboutGrid mb2">
          <div className="fieldLabel">
            <FormattedMessage id="Phone Number" />:
          </div>
          <div className="fieldValue">
            {this.props.contact_info?.phone_number ? formatPhoneNumber(this.props.contact_info?.phone_number) : "--"}
          </div>
        </div>
        {/* <Dragger {...imageProps}>
          <Button type="link" disabled={this.props.avatarEditing}>
            <Space>
              {(this.props.contact_info?.photo_url ?? "") !== "" ? (
                <FormattedMessage id="Update Image" />
              ) : (
                <FormattedMessage id="Add Image" />
              )}
              <UploadOutlined />
            </Space>
          </Button>
        </Dragger> */}
      </AboutWrapper>
    );
  }
}

export default withTheme(injectIntl(About));
