import React, { useContext } from "react";
import { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import { Space, Typography } from "antd";
import { AudioMutedOutlined } from "@ant-design/icons";
import MicActivity from "../microphoneActivityBubble";
import MeetingContext from "../../MeetingContext";

const { Text } = Typography;

const NamePlate = withTheme(
  injectIntl(({ intl, muted, noActivity = false, text, theme }) => {
    const { fullscreen } = useContext(MeetingContext);
    return (
      <Space style={{ position: "absolute", bottom: "13px", left: "10px", height: "30px" }}>
        <Text style={{ color: theme?.color?.grey?.["grey-1"], verticalAlign: "middle" }}>{text}</Text>
        {muted ? (
          <AudioMutedOutlined style={{ color: theme?.color?.grey?.["grey-1"], verticalAlign: "middle" }} />
        ) : fullscreen || noActivity ? (
          <MicActivity hidden={muted} />
        ) : (
          false
        )}
      </Space>
    );
  })
);

export default NamePlate;
