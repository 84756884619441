import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import {
  ContentShare,
  useContentShareState,
  useRemoteVideoTileState,
  useRosterState,
  VideoGrid,
} from "amazon-chime-sdk-component-library-react";
import MeetingContext from "../MeetingContext";
import FullscreenTiles from "./VideoTiles/fullscreenTiles";
import SingleTile from "./VideoTiles/singleTile";

const VideoTileGrid = withTheme(
  injectIntl(({ intl, theme }) => {
    const { attendee, fullscreen, stopLoading } = useContext(MeetingContext);
    const { tileId: contentTileId } = useContentShareState();
    const { tileIdToAttendeeId, tiles } = useRemoteVideoTileState();
    const { roster } = useRosterState();
    const [noCameras, setNoCameras] = useState([]);
    const [videoGridSize, setVideoGridSize] = useState(1);

    useEffect(() => {
      stopLoading();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      compareRoster();
    });

    const compareRoster = () => {
      const current_attendees = Object.keys(roster);
      const attendeesWithTiles = tiles.map(tile => tileIdToAttendeeId[tile]);
      const difference = current_attendees
        .filter(a => a !== attendee && !attendeesWithTiles.includes(a))
        .sort((a, b) => a?.toString().localeCompare(b?.toString()));
      if (JSON.stringify(difference) !== JSON.stringify(noCameras)) {
        setNoCameras(difference);
      }
      calculateSize();
    };

    const calculateSize = () => {
      let num = 1;
      if (fullscreen) {
        num = tiles?.length + noCameras?.length;
        if (contentTileId) {
          num++;
        }
      }
      if (videoGridSize !== num) {
        setVideoGridSize(num);
      }
    };

    const showContent = fullscreen && contentTileId;
    return (
      <Row style={{ width: "100%", height: "100%" }}>
        {showContent ? (
          <Col span={15}>
            <ContentShare css="grid-area: ft;" />
          </Col>
        ) : (
          false
        )}
        <Col span={showContent ? 9 : 24}>
          <VideoGrid
            size={videoGridSize}
            style={{ background: "#3f4149", borderRadius: "1rem" }}
            layout="standard"
          >
            {fullscreen ? <FullscreenTiles noCameras={noCameras} /> : <SingleTile />}
          </VideoGrid>
        </Col>
      </Row>
    );
  })
);

export default VideoTileGrid;
