/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import { formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";

/**
 * Formats a phone number string as a Phone Number
 * @param {string} value
 * @returns {string} a string in the format +1 (###) ###-#### or +### #############
 */
export const formatPhoneNumber = phoneNumberString => {
  let formattedPhoneNumber = "--";
  if (phoneNumberString) {
    let phoneNumber = parsePhoneNumber("+" + phoneNumberString);
    if (phoneNumber?.country === "US" || phoneNumber?.country === "CA") {
      let countryCode = "+" + phoneNumberString?.slice(0, 1);
      let areaCode = phoneNumberString?.slice(1, 4);
      let local1 = phoneNumberString?.slice(4, 7);
      let local2 = phoneNumberString?.slice(7, 11);
      formattedPhoneNumber = [countryCode, " (", areaCode, ") ", local1, "-", local2].join("");
    } else {
      formattedPhoneNumber = formatPhoneNumberIntl(phoneNumber?.number);
    }
  }
  return formattedPhoneNumber.length > 0 ? formattedPhoneNumber : "+" + phoneNumberString;
};
