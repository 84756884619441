import antdSA from "antd/lib/locale-provider/fr_FR";
import d3frCA from "d3-format/locale/fr-CA";

import frMessages from "../locales/fr_FR.json";
import frMigratedMessages from "../locales/fr_FR_migrated.json";

import frDescMessages from "../locales/desc_fr_FR.json";
import frDescMigratedMessages from "../locales/desc_fr_FR_migrated.json";

import "moment/locale/fr.js";
import "numeral/locales/fr-ca.js";

d3frCA.currency = ["", "\u00a0$"];
d3frCA.percent = "\u00a0%";

const frLang = {
  messages: {
    ...frMessages,
    ...frMigratedMessages,
    ...frDescMessages,
    ...frDescMigratedMessages,
  },
  antd: antdSA,
  locale: "fr-FR",
  moment: "fr-ca",
  numeral: "fr-ca",
  defaultLocale: "en",
  d3: d3frCA,
  label: "Français",
  app: "fr",
};
export default frLang;
