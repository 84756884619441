import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { config, siteConfig } from "./settings/config";
import GlobalTheme from "./globalTheme";
import { Helmet } from "react-helmet";
import MeetingContext from "./meetingManager";
import Meeting from "./components/Meeting";
import Fourlytics from "./fourlytics";
import { makeFourlyticsCustomVariables } from "./helpers/utilities";

import PublicRoutes from "./router";
import {
  authUser,
  loginUser,
  authorizeUser,
  getUserInfo,
  removeAuthToken,
  getUserState,
  setUserTokens,
  getDeviceToken,
  getChatsocket,
  setDeviceToken,
  axiosCancel,
  invokeApi,
  getUserTokens,
} from "./helpers/authHelper";
import { checkExists } from "./helpers/utilities";

import moment from "moment";
import numeral from "numeral";
import * as d3 from "d3";

import { check_icon_imported } from "./faIcons";

function getView(width) {
  let newView = "MobileView";
  if (width > 1500) {
    newView = "DesktopView";
  } else if (width > 767) {
    newView = "TabView";
  }
  return newView;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    // defaultPage should be controlled via backend so different users types can have different default pages
    this.state = {
      theme: siteConfig.defaultTheme,
      defaultPage: siteConfig.defaultPage,

      currentLocale: AppLocale[localStorage.getItem("app.language")?.toLowerCase() ?? config?.defaultLanguage ?? "en"],

      isAppLoading: true,
      isAuthenticating: true,
      isAuthenticated: false,
      user_state: null,
      state_order: ["sq", "token"],
      active_region: "all",
      active_role: "all",
      roles: {},
      permissions: {},
      languages: [],
      selectedLanguage: (localStorage.getItem("app.language") ?? config?.defaultLanguage ?? "en").toUpperCase(),
      sso_enabled: config.sso_enabled,

      view: getView(window.innerWidth),
      height: window.innerHeight,
      printView: false,

      sbCollapsed: window.innerWidth > 1500 ? false : true,
      sbOpenDrawer: false,
      sbCurrent: [],
      sbOpenKeys: [],

      breadcrumbs: [],

      appLayout: "panel",
      appContentScroll: "auto",
      meetingContext: new MeetingContext(() => this.forceUpdate()),
      chatroomsVisible: false,
      selectedChatroom: null,
      selectedRegions: [],
      oversight_contact: null,
      _tracker: null,
    };
    this.chatSocket = {
      connected: false,
      connection: null,
      counter: 0,
      url: null,
    };
  }

  async componentDidMount() {
    await this.update_user_status();
    window.addEventListener("beforeprint", event => {
      this.printSetup(event);
    });
    window.addEventListener("afterprint", event => {
      this.printSetup(event);
    });
    let _tracker = null;
    if (siteConfig?.fourlyticsId ?? false) {
      _tracker = new Fourlytics(
        siteConfig?.fourlyticsId,
        this.state.user_info?.muuid
          ? `${this.state.user_info?.display_name} - ${this.state.user_info?.muuid}`
          : this.state.user_info?.muuid,
        "",
        this.state.fourlyticsVars?.allow_recording ?? siteConfig?.fourlyticsScreenRecording,
        makeFourlyticsCustomVariables(this.state.fourlyticsVars?.custom_vars ?? [], {
          ...this.state,
        })
      );
    }
    this.setState({ isAppLoading: false, isAuthenticating: false, loading_state: "complete", _tracker: _tracker });
  }

  printSetup(event) {
    this.setState({ printView: event.type === "beforeprint" ? true : false });
  }

  setSelectedChatroom = value => this.setState({ selectedChatroom: value, chatroomCallback: () => {} });

  setChatsDrawerVisiblity = async (value, callback = null) => {
    if (this.chatSocket.url === null) {
      let userState = await getUserInfo();
      await this.newChatSocket(
        this.state?.roles?.[this.state?.active_role]?.name,
        this.state?.permissions?.[this.state?.active_role],
        userState?.user_info
      );
      this.setState({ user_info: userState?.user_info });
    }
    this.setState({ chatroomsVisible: value, chatroomCallback: callback, selectedChatroom: null });
  };

  updateOversightContact = contact => {
    this.setState({ oversight_contact: contact });
    let page = this.state.roles?.[this.state.active_role]?.page;
    if (page) {
      window.location.href = page;
    }
  };

  update_user_status = async () => {
    if (await authUser()) {
      var userState = await getUserInfo();
      if (userState && userState?.user_info?.is_dz_user) {
        localStorage.removeItem("fef.auth.device");
        this.signoutUser();
      } else {
        if (this.state._tracker) {
          if (userState?.fourlyticsVars?.custom_vars) {
            this.state._tracker.setCustomVars(
              makeFourlyticsCustomVariables(userState?.fourlyticsVars?.custom_vars ?? [], {
                ...this.state,
                ...userState,
              })
            );
          }
          if (
            userState?.fourlyticsVars?.allow_recording !== null &&
            userState?.fourlyticsVars?.allow_recording !== undefined
          ) {
            this.state._tracker.setRecordingEnabled(userState?.fourlyticsVars?.allow_recording);
          }
          setTimeout(() => {
            this.state._tracker.updateMuuid(`${userState.user_info?.display_name} - ${userState.user_info?.muuid}`);
          }, 500);
        }
        await this.newChatSocket(
          userState?.roles?.[userState?.active_role]?.name,
          userState?.permissions?.[userState?.active_role],
          userState?.user_info
        );
        if (userState) {
          this.setState({
            isAuthenticated: true,
            loading_state: "user_data",
            ...userState,
            activeRegion:
              checkExists(userState, "user_info", "default_region") && userState.user_info.default_region !== null
                ? userState.user_info.default_region
                : "all",
          });
        }
      }
    }
  };

  update_user_state = async () => {
    if (this.state.isAuthenticated) {
      let s = await getUserState();
      this.setState({ user_state: s });
    }
  };

  updateSelectedRegions = regions => {
    this.setState({ selectedRegions: regions });
  };

  getSelectedRegions = () => {
    return this.state.selectedRegions;
  };

  user_has_authenticated = value => {
    this.setState({ isAuthenticated: value });
  };

  signoutUser = () => {
    let user_info = this.state.user_info;
    if (this.state._tracker) {
      this.state._tracker.disableRecording("Logged Out");
      setTimeout(() => {
        this.state._tracker.updateMuuid(null);
        this.state._tracker.trackPage(window.location.pathname);
      }, 1000);
    }
    if (this.state.meetingContext?.cleanUpMeeting) {
      this.state.meetingContext.cleanUpMeeting();
    }
    let auth_token = localStorage.getItem("fef.auth.tokens");
    auth_token = getUserTokens();
    removeAuthToken();
    sessionStorage.clear();
    this.setState({ isAuthenticated: false });
    if (user_info.is_sso_user) {
      // for right now only if sso user
      invokeApi({
        path: "/auth/signout",
        method: "POST",
        body: {
          refresh_token: auth_token.refresh_token,
          user_uuid: user_info.uuid,
        },
        unauth: true,
      });
    }
  };

  newChatSocket = async (role_name, permissions, user_info) => {
    if (this.chatSocket?.connection?.close) {
      this.chatSocket.connected = false;
      this.chatSocket.url = null;
      this.chatSocket.counter = 0;
      this.chatSocket.connection.close();
      this.chatSocket.connection = null;
    }
    if (
      permissions?.includes(`chime.chat.${role_name}.join`) &&
      user_info?.chatroom_user_arn &&
      this.chatSocket.url === null
    ) {
      this.chatSocket.url = await getChatsocket(role_name);
    }
  };

  changeActiveRole = async role => {
    this.track_lytics_event("Interaction", "Changed Role");
    await this.newChatSocket(this.state.roles?.[role]?.name, this.state.permissions?.[role], this.state.user_info);
    this.setState({ active_role: role, breadcrumbs: [] });
    sessionStorage.setItem("active_role", this.state.roles?.[role]?.name);
    sessionStorage.setItem("active_role_uuid", role);
  };

  changeActiveRegion = region => {
    this.setState({ active_region: region });
  };

  // Function to handle sidebar and topbar nav.
  toggleResize = (width, height) => {
    let view = getView(width);
    this.setState({ view: view, sbCollapsed: view !== "DesktopView", height: height });
  };

  toggleCollapsed = () => {
    this.setState({ sbCollapsed: !this.state.sbCollapsed });
  };

  getLanguages = () => {
    return this.state.languages;
  };

  currentLanguage = () => {
    return this.state.selectedLanguage ?? (config?.defaultLanguage.toUpperCase() || "EN");
  };

  changeLanguage = lang => {
    this.setState({
      currentLocale: AppLocale?.[lang.toLowerCase()] ?? AppLocale?.en,
      selectedLanguage: lang,
    });
    localStorage.setItem("app.language", lang?.toUpperCase?.());
  };

  capitalizeStr = (s, l = 100) => {
    let str = "";

    s.split(" ").map((ss, i) => (str += i < l ? ss.charAt(0).toUpperCase() + ss.slice(1) + " " : ss + " "));

    return str.trim();
  };

  currentProduct = () => {
    return window.location.pathname.split("/")[1];
  };

  hasPermission = permission => {
    return this.state.permissions[this.state.active_role] !== undefined &&
      this.state.permissions[this.state.active_role].indexOf(permission) !== -1
      ? true
      : false;
  };

  updateBreadcrumbs = breadcrumbs => {
    this.setState({ breadcrumbs: breadcrumbs });
  };

  updateLayout = layout => {
    this.setState({ appLayout: layout });
  };

  updateAppContentScroll = scroll => {
    this.setState({ appContentScroll: scroll });
  };

  intlFormatData = (lan_block, description, key = "display_name") => {
    let iso_lan = this.state.currentLocale;
    let tempLanBlock = JSON.parse(JSON.stringify(lan_block || {}));
    Object.keys(tempLanBlock ?? {}).forEach(k => (tempLanBlock[k.toUpperCase()] = tempLanBlock[k]));
    let msg =
      tempLanBlock && tempLanBlock[iso_lan.app.toUpperCase()] && tempLanBlock[iso_lan.app.toUpperCase()][key]
        ? tempLanBlock[iso_lan.app.toUpperCase()][key]
        : description;
    return msg;
  };

  intlFormatKeys = (iso_language = {}, iso_lang_keys = ["display_name"], description = "--") => {
    let text = description;
    for (const key of iso_lang_keys) {
      const results = this.intlFormatData(iso_language, false, key);
      if (results) {
        text = results;
        break;
      }
    }
    return text;
  };

  setMeeting = meeting => {
    if (this.state.meetingContext?.setMeeting) {
      this.state.meetingContext.setMeeting(meeting);
    }
  };

  cleanUpMeeting = () => {
    if (this.state.meetingContext?.cleanUpMeeting) {
      this.state.meetingContext.cleanUpMeeting();
    }
  };

  track_lytics_event = (type, text) => {
    if (this.state._tracker) {
      this.state._tracker.trackEvent(type, text);
    }
  };

  track_lytics_download = text => {
    if (this.state._tracker) {
      this.state._tracker.trackDownload(text);
    }
  };

  enable_screen_recording = text => {
    if (this.state._tracker) {
      this.state._tracker.enableRecording(text, true);
    }
  };

  disable_screen_recording = text => {
    if (this.state._tracker) {
      this.state._tracker.disableRecording(text, true);
    }
  };

  track_lytics_page = text => {
    if (this.state._tracker) {
      this.state._tracker.trackPage(text);
    }
  };

  currentRole = () => {
    return this.state.roles?.[this.state.active_role]?.name;
  };

  render() {
    moment.locale(this.state.currentLocale.moment);
    numeral.locale(this.state.currentLocale.numeral);
    d3.formatDefaultLocale(this.state.currentLocale.d3);

    let context = {
      ...this.state,
      app_container: this.app_container,
      login_user: loginUser,
      authorize_user: authorizeUser,
      signout_user: this.signoutUser,
      setUserTokens: setUserTokens,
      updateUserStatus: this.update_user_status,
      updateUserState: this.update_user_state,
      user_has_authenticated: this.user_has_authenticated,
      getDeviceToken: getDeviceToken,
      setDeviceToken: setDeviceToken,
      toggleCollapsed: this.toggleCollapsed,
      toggleResize: this.toggleResize,
      changeActiveRole: this.changeActiveRole,
      changeActiveRegion: this.changeActiveRegion,
      checkIconImported: check_icon_imported,
      capitalizeStr: this.capitalizeStr,
      currentProduct: this.currentProduct,
      currentRole: this.currentRole,
      currentLanguage: this.currentLanguage,
      axiosCancel: axiosCancel,
      hasPermission: this.hasPermission,
      updateBreadcrumbs: this.updateBreadcrumbs,
      updateLayout: this.updateLayout,
      updateAppContentScroll: this.updateAppContentScroll,
      intlFormatData: this.intlFormatData,
      intlFormatKeys: this.intlFormatKeys,
      setMeeting: this.setMeeting,
      meetingIsVisible: this.state.meetingContext.isVisible,
      selectedChatroom: this.state.selectedChatroom,
      chatroomsVisible: this.state.chatroomsVisible,
      setSelectedChatroom: this.setSelectedChatroom,
      setChatsDrawerVisiblity: this.setChatsDrawerVisiblity,
      chatSocket: this.chatSocket,
      trackLyticsEvent: this.track_lytics_event, //Can be called to track any events from any page
      trackLyticsDownload: this.track_lytics_download, //Can be called to track any downloads from any page
      trackLyticsPage: this.track_lytics_page, //Can bel called to track the page view if not done automatically with href links
      enableScreenRecording: this.enable_screen_recording, //Can be called to block screen recording until re enabled
      disableScreenRecording: this.disable_screen_recording, //Can be called to re enable screen recording
      updateSelectedRegions: this.updateSelectedRegions,
      getSelectedRegions: this.getSelectedRegions,
      updateOversightContact: this.updateOversightContact,
      getLanguages: this.getLanguages,
      changeLanguage: this.changeLanguage,
    };

    return (
      !this.state.isAuthenticating && (
        <ConfigProvider locale={this.state.currentLocale.antd}>
          <IntlProvider
            locale={this.state.currentLocale.locale}
            defaultLocale={"en-US"}
            messages={this.state.currentLocale.messages}
          >
            <ThemeProvider theme={themes[this.state.theme]}>
              <GlobalTheme />
              <Helmet>
                <title>{siteConfig.title}</title>
                <link
                  rel="stylesheet"
                  type="text/css"
                  href={`https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=${config.canadaPostApiKey}`}
                />
                <script
                  type="text/javascript"
                  src={`https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=${config.canadaPostApiKey}`}
                  defer
                ></script>
                <style type="text/css">{`
                  .pac-container:after {
                  background-image: none !important;
                  height: 0px;
                  padding: 0;
                  margin: 0;
                  }
              `}</style>
              </Helmet>
              <BrowserRouter>
                <PublicRoutes context={context} />
              </BrowserRouter>
              {this.hasPermission(`chime.video.${this.state.roles?.[this.state.active_role]?.name}.create`) &&
                this.state.isAuthenticated &&
                this.state.appLayout === "sidebar" &&
                !this.state.meetingContext.checkPage(null) && (
                  <Meeting appContext={context} context={this.state.meetingContext} />
                )}
            </ThemeProvider>
          </IntlProvider>
        </ConfigProvider>
      )
    );
  }
}

export default App;
