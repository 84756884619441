/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React from "react";
import asyncComponent from "../helpers/AsyncFunc";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Note: paths on components within a parent role must be unique for breadcrumbs to work correctly.
// ie. You cannot have appvar_case and appvar in the same parent.
const routes = [
  // {
  //   key: "books",
  //   path: "books",
  //   product: "r4",
  //   onMenu: true,
  //   label: "Books",
  //   leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "books"]} />,
  //   component: asyncComponent(() => import("../pages/books")),
  //   permissions: ["r4.client.personal"],
  //   children: false,
  // },
  {
    key: "clients",
    path: "clients",
    product: "r4",
    onMenu: true,
    label: "Contacts",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "users"]} />,
    component: asyncComponent(() => import("../pages/client/clientPortfolios.js")),
    permissions: ["r4.contact_search.view"],
    children: false,
  },
  {
    key: "client-portfolio",
    path: "clients/:clientid/portfolio",
    regex: "clients/:clientid/portfolio",
    product: "r4",
    onMenu: false,
    label: "Client Portfolio", //"Client Portfolio"
    component: asyncComponent(() => import("../pages/client/clientPortfolioReport.js")),
    permissions: ["r4.client.personal"],
    children: false,
  },
  {
    key: "client-contactCard",
    path: "clients/:clientid/contactCard",
    regex: "clients/:clientid/contactCard",
    product: "r4",
    onMenu: false,
    label: "Contact Card", //"Clients"
    component: asyncComponent(() => import("../pages/Contacts")),
    permissions: ["r4.client.personal"],
    children: false,
  },
  {
    key: "bulkupload",
    path: "clients/bulkupload",
    product: "r4",
    onMenu: false,
    label: "Bulk Upload",
    component: asyncComponent(() => import("../pages/BulkUpload")),
    permissions: ["r4.client.personal"],
  },
  {
    key: "onboarding",
    path: "onboarding",
    product: "r4",
    onMenu: true,
    label: "Onboarding",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "address-card"]} />,
    permissions: ["contact.open.account", "contact.kyc.update"],
    children: [
      {
        key: "accountopening",
        path: "onboarding/accountopening/client=:clientid",
        product: "r4",
        onMenu: false,
        label: "Account Opening",
        component: asyncComponent(() => import("../pages/onboarding")),
        permissions: ["contact.open.account"],
      },
      {
        key: "accountopeningonboarding",
        path: "onboarding/accountopening/onboarding=:formid",
        product: "r4",
        onMenu: false,
        label: "Account Opening",
        component: asyncComponent(() => import("../pages/onboarding")),
        permissions: ["contact.open.account"],
      },
      {
        key: "envelopestatus",
        path: "onboarding/envelopestatus",
        product: "r4",
        onMenu: true,
        label: "Envelope Status",
        component: asyncComponent(() => import("../pages/envelopestatus")),
        permissions: ["contact.open.account", "contact.kyc.update"],
      },
      {
        key: "envelopestatusclient",
        path: "onboarding/envelopestatus/client=:clientid",
        product: "r4",
        onMenu: false,
        label: "Envelope Status",
        component: asyncComponent(() => import("../pages/envelopestatus")),
        permissions: ["contact.open.account", "contact.kyc.update"],
      },
    ],
  },
  {
    key: "kyc",
    path: "kyc",
    product: "r4",
    onMenu: false,
    label: "KYC",
    permissions: ["contact.kyc.update"],
    children: [
      {
        key: "materialkycupdate",
        path: "kyc/material/client=:clientid",
        product: "r4",
        onMenu: false,
        label: "Material KYC Update",
        component: asyncComponent(() => import("../pages/kycUpdates")),
        permissions: ["contact.kyc.update"],
      },
      {
        key: "nonmaterialkycupdate",
        path: "kyc/nonmaterial/client=:clientid",
        product: "r4",
        onMenu: false,
        label: "Non-Material KYC Update",
        component: asyncComponent(() => import("../pages/kycUpdates")),
        permissions: ["contact.kyc.update"],
      },
    ],
  },
  {
    key: "pos",
    path: "pos",
    product: "r4",
    component: asyncComponent(() => import("../pages/pos")),
    onMenu: true,
    label: "Point of Sale",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "cash-register"]} />,
    permissions: ["r4.pos.view"],
    children: false,
  },
  {
    key: "pta",
    path: "pta",
    product: "r4",
    component: asyncComponent(() => import("../pages/pta")),
    onMenu: true,
    label: "Pre Trade",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "chart-pie-alt"]} />,
    permissions: ["r4.pta.view"],
    children: false,
  },
  {
    key: "products",
    path: "products",
    product: "r4",
    onMenu: true,
    label: "Product List",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "parking-circle"]} />,
    permissions: ["r4.product.approved.view"],
    children: [
      {
        key: "reviewed",
        path: "products/reviewed",
        product: "r4",
        onMenu: true,
        permissions: ["r4.product.approved.view"],
        label: "Reviewed",
        component: asyncComponent(() => import("../pages/products/products")),
      },
    ],
  },
  {
    key: "caseManagement",
    path: "case_management",
    product: "r4",
    component: false,
    onMenu: true,
    label: "Case",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "comment-alt-smile"]} />,
    permissions: ["r4.cxe_mgmt.view"],
    children: [
      {
        key: "caseManagementTickets",
        path: "case_management/tickets",
        regex: "case_management/tickets",
        product: "r4",
        component: asyncComponent(() => import("../pages/tickets/")),
        onMenu: true,
        label: "Tickets",
        permissions: ["r4.cxe_mgmt.view"],
      },
      {
        key: "caseManagementTicketsCommunication",
        path: "case_management/tickets/:title",
        product: "r4",
        component: asyncComponent(() => import("../pages/tickets/conversation.js")),
        label: "{title}",
        permissions: ["r4.cxe_mgmt.view"],
        onMenu: false,
      },
      {
        key: "caseManagmentArchived",
        path: "case_management/archived",
        product: "r4",
        label: "Archived",
        onMenu: true,
        permissions: ["r4.cxe_mgmt.view"],
        component: asyncComponent(() => import("../pages/tickets/search.js")),
      },
      {
        key: "caseManagementTicketsCommunicationArchived",
        path: "case_management/archived/:title",
        product: "r4",
        component: asyncComponent(() => import("../pages/tickets/conversation.js")),
        label: "{title}",
        permissions: ["r4.cxe_mgmt.view"],
        onMenu: false,
      },
    ],
  },
  {
    key: "discoveryzone",
    path: "discoveryzone",
    product: "r4",
    onMenu: true,
    label: "Discovery",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "portal-enter"]} />,
    component: asyncComponent(() => import("../pages/discovery")),
    permissions: ["r4.dz.view"],
  },
  {
    key: "documents",
    path: "documents",
    product: "r4",
    onMenu: true,
    label: "Documents",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "file-alt"]} />,
    component: false,
    permissions: ["r4.dz.documents"],
    children: [
      {
        key: "documentsManage",
        path: "documents/manage",
        product: "r4",
        component: asyncComponent(() => import("../pages/documents/manage")),
        onMenu: true,
        label: "Manage",
        permissions: ["r4.dz.documents.manage"],
      },
      {
        key: "documentsGroups",
        path: "documents/groups",
        product: "r4",
        label: "Groups",
        onMenu: true,
        permissions: ["r4.dz.documents.groups"],
        component: asyncComponent(() => import("../pages/documents/groups")),
      },
      {
        key: "documentsBatchUpload",
        path: "documents/batch",
        regex: "documents/batch",
        product: "r4",
        label: "Batch",
        onMenu: true,
        permissions: ["r4.dz.documents.batch"],
        component: asyncComponent(() => import("../pages/documents/batch")),
      },
      {
        key: "docuementsBatchReview",
        path: "documents/batch/review/batch=:batchid",
        product: "r4",
        label: "Review",
        onMenu: false,
        permissions: ["r4.dz.documents.batch.review"],
        component: asyncComponent(() => import("../pages/documents/review")),
      },
    ],
  },
  {
    key: "notifications",
    path: "notifications",
    product: "r4",
    onMenu: true,
    label: "Notifications",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "bell"]} />,
    permissions: ["r4.dz.view.notification"],
    children: [
      {
        key: "notificationManagement",
        path: "notifications/manage",
        product: "r4",
        onMenu: true,
        label: "Manage",
        component: asyncComponent(() => import("../pages/notifications/manage")),
        permissions: ["r4.dz.view.notification"],
      },
      {
        key: "notificationGroups",
        path: "notifications/groups",
        product: "r4",
        onMenu: true,
        label: "Groups",
        component: asyncComponent(() => import("../pages/notifications/groups")),
        permissions: ["r4.dz.view.notification"],
      },
    ],
  },
  {
    key: "content",
    path: "content",
    product: "r4",
    onMenu: true,
    label: "Manage Content",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "rss"]} />,
    component: asyncComponent(() => import("../pages/content/")),
    permissions: ["r4.dz.view.notification"],
    children: false,
  },
  {
    key: "searchR4",
    path: "search",
    component: false,
    onMenu: true,
    label: "Search",
    product: "r4",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "search"]} />,
    permissions: ["r4.transaction.view"],
    children: [
      {
        key: "transactionsR4",
        path: "search/transactions/trades",
        product: "r4",
        component: asyncComponent(() => import("../pages/transactions/Transactions")),
        onMenu: true,
        label: "Transactions",
        permissions: ["r4.transaction.view"],
      },
      {
        key: "transactionReviewR4",
        path: "search/transactions/trades/client/:clientid/portfolio",
        regex: "search/transactions/trades/client/:clientid/portfolio",
        product: "r4",
        label: "Client Portfolio",
        onMenu: false,
        permissions: ["r4.transaction.view"],
        component: asyncComponent(() => import("../pages/client/clientPortfolioReport")),
      },
    ],
  },
  {
    key: "reports",
    path: "reports",
    product: "r4",
    onMenu: true,
    label: "Reports",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "file-chart-line"]} />,
    permissions: ["r4.kyc_report.view"],
    children: [
      {
        key: "kyc",
        path: "reports/kyc",
        product: "r4",
        onMenu: true,
        permissions: ["r4.kyc_report.view"],
        label: "KYC",
        component: asyncComponent(() => import("../pages/reports/kyc.js")),
      },
    ],
  },
  {
    key: "dashboard",
    path: "dashboard",
    product: "r4",
    onMenu: true,
    label: "Dashboards",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "tachometer-average"]} />,
    permissions: ["r4.kyc_dashboard.view"],
    children: [
      {
        key: "advisor",
        path: "dashboard/advisor",
        product: "r4",
        onMenu: true,
        permissions: ["r4.kyc_dashboard.view"],
        label: "Advisor",
        component: asyncComponent(() => import("../pages/dashboards/kyc/index.js")),
      },
    ],
  },
];

export default routes;
