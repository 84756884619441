import { library, icon } from "@fortawesome/fontawesome-svg-core";

import {
  faBrowser,
  faCaretDown,
  faCaretUp,
  faCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faMobile as fasMobile,
  faLaptop,
  faTablet,
  faDesktop,
  faPhoneVolume,
  faShieldCheck,
  faUserCheck,
  faBolt,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faCircleNotch,
  faCommentAltSmile as farCommentAltSmile,
  faCommentAltDollar as farCommentAltDollar,
  faKey,
  faRecycle,
  faShield,
  faEllipsisV,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faEye,
  faQuestionCircle,
  faAddressCard,
  faArchive,
  faPhoneAlt,
  faMobile,
  faEnvelope,
  faPencilAlt,
  faMapMarker,
  faUserFriends,
  faTachometerAverage,
  faHistory,
  faUsers,
  faChartPieAlt,
  faCashRegister,
  faFile,
  faPortalEnter,
  faCommentAltSmile,
  faCheck,
  faTrash,
  faCogs,
  faExclamationTriangle,
  faCommentAltPlus,
  faCommentAltLines,
  faConstruction,
  faCheckCircle,
  faRegistered,
  faParkingCircle,
  faPencil,
  faFileChartLine,
  faFileAlt,
  faFilePlus,
  faFileImport,
  faSearch,
  faUserChart,
  faReply,
  faUserCheck as falUserCheck,
  faUserCog,
  faLockAlt,
  faTrashUndo,
  faBooks,
  faTools,
  faMinus,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faMicrophone,
  faVideoSlash,
  faMicrophoneSlash,
  faVideo,
  faTvAlt,
  faStop,
  faRecordVinyl,
  faPhone,
  faFax,
  faDirections,
  faBell,
  faCompressWide,
  faCompressArrowsAlt,
  faRss,
  faComment,
  faPlusSquare,
  faPersonCarry,
  faAtlas,
  faBadgeDollar,
  faCommentAltExclamation,
  faChartNetwork,
} from "@fortawesome/pro-light-svg-icons";

import { faCog } from "@fortawesome/pro-duotone-svg-icons";

import { fab } from "@fortawesome/free-brands-svg-icons";

/** Add Solid Icons **/
library.add(
  faBrowser,
  faCaretDown,
  faCaretUp,
  faCircle,
  fasExclamationTriangle,
  fasMobile,
  faLaptop,
  faTablet,
  faDesktop,
  faPhoneVolume,
  faShieldCheck,
  faUserCheck,
  faBolt
);

/** Add Regular Icons **/
library.add(faCircleNotch, farCommentAltSmile, farCommentAltDollar, faKey, faRecycle, faShield, faEllipsisV);

/** Add Light Icons **/
library.add(
  faEye,
  faQuestionCircle,
  faAddressCard,
  faArchive,
  faPhoneAlt,
  faMobile,
  faEnvelope,
  faPencilAlt,
  faMapMarker,
  faUserFriends,
  faTachometerAverage,
  faHistory,
  faUsers,
  faChartPieAlt,
  faCashRegister,
  faFile,
  faPortalEnter,
  faCheck,
  faTrash,
  faUsers,
  faExclamationTriangle,
  faCommentAltPlus,
  faCommentAltLines,
  faConstruction,
  faCheckCircle,
  faRegistered,
  faParkingCircle,
  faPencil,
  faFileChartLine,
  faCogs,
  faCommentAltSmile,
  falUserCheck,
  faFileAlt,
  faFileImport,
  faFilePlus,
  faFileImport,
  faSearch,
  faUserChart,
  faUserCog,
  faLockAlt,
  faTrashUndo,
  faBooks,
  faReply,
  faArchive,
  faTools,
  faMinus,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faMicrophone,
  faVideoSlash,
  faMicrophoneSlash,
  faVideo,
  faTvAlt,
  faStop,
  faRecordVinyl,
  faPhone,
  faChevronRight,
  faFax,
  faDirections,
  faBell,
  faCompressWide,
  faCompressArrowsAlt,
  faRss,
  faComment,
  faPlusSquare,
  faPersonCarry,
  faAtlas,
  faBadgeDollar,
  faCommentAltExclamation,
  faChartNetwork
);

/** Add Duotone Icons **/
library.add(faCog);

/** All Brand icons *Firefox, Chrome, etc  **/
library.add(fab);

export default library;

export function check_icon_imported(is) {
  return icon({ prefix: is[0], iconName: is[1] }) !== undefined;
}
