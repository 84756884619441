import { notification } from "antd";
import axios from "axios";
import { axiosCancel, getChatsocket, invokeApi } from "./helpers/authHelper";

class Meeting {
  constructor(updateApp) {
    //variable name for localStorage
    this.variable_name = "meeting.instance.id";
    //meeting info
    this.attendee = null;
    this.uuid = null;
    this.region = null;
    this.title = null;
    this.recording = false;
    this.recorder_id = null;
    this.chatroom = null;
    this.chatSocket = {
      connected: false,
      connection: null,
      counter: 0,
      url: null,
    };
    this.channelLastRead = null;
    this.canRecord = false;
    this.meeting_info = null;
    this.page = null;
    this.attendees = null;
    this.wasToggled = false;
    this.updateApp = updateApp;
    this.signal = axiosCancel();
    //Re initalize after refresh
    if (this.getMeetingLocal()) {
      this.setMeeting(this.getMeetingLocal());
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  //initial state for cleanup
  cleanUpMeeting = () => {
    this.attendee = null;
    this.canRecord = false;
    this.uuid = null;
    this.region = null;
    this.title = null;
    this.recording = false;
    this.recorder_id = null;
    this.chatroom = null;
    if (this.chatSocket.connection) {
      this.chatSocket.connection.close();
    }
    this.chatSocket = {
      connected: false,
      connection: null,
      counter: 0,
      url: null,
    };
    this.channelLastRead = null;
    this.meeting_info = null;
    this.page = null;
    this.visible = false;
    this.attendees = null;
    this.wasToggled = false;
    localStorage.removeItem(this.variable_name);
    this.updateApp();
  };

  //invokes
  joinLobby = async () => {
    await invokeApi({
      //Retrieves attendee info
      path: `/rest/meeting/join`,
      method: "GET",
      queryParams: {
        m: this.uuid,
      },
      cancelToken: this.signal.token,
      onSuccess: this.handleInfo,
      onError: this.handleError,
    });
  };

  setWasToggled = value => {
    this.wasToggled = value;
  };

  findAttendee = user => { //Finds the user based on the meeting_arn send in
    for (const attendee of Object.values(this.attendees)) {
      if (attendee?.chat_arn === user) {
        return attendee?.Name;
      }
    }
  }

  handleInfo = join_info => {
    if (join_info) {
      const { Attendee, canRecord, Chatroom, channelLastRead, Meeting } = join_info;
      if (Meeting && Attendee) {
        this.meeting_info = {
          meetingInfo: Meeting,
          attendeeInfo: Attendee,
        };
        this.canRecord = canRecord ?? false;
        this.chatroom = Chatroom;
        this.attendee = Attendee?.AttendeeId;
        this.channelLastRead = channelLastRead;
        this.getAttendees();
      } else {
        this.handleError();
      }
    } else {
      this.handleError();
    }
  };

  getAttendees = async () => {
    await invokeApi({
      //Retrieves all attendees
      path: `/rest/meeting/attendees`,
      method: "GET",
      queryParams: {
        m: this.uuid,
      },
      cancelToken: this.signal.token,
      onSuccess: this.handleAttendees,
      onError: this.handleError,
    });
  };

  handleAttendees = attendees => {
    this.attendees = attendees;
    this.page = "lobby";
    this.getChatSocket();
  };

  getChatSocket = async () => {
    if (this.chatroom !== null) {
      this.chatSocket.url = await getChatsocket();
    }
    this.updateApp();
  };

  joinCall = (wasToggled = false) => {
    this.page = "room";
    this.wasToggled = wasToggled;
  };

  findRegion = async () => {
    const res = await axios.get("https://nearest-media-region.l.chime.aws");
    return res.data.region || "ca-central-1";
  };

  handleError = () => {
    notification.info({ message: "This meeting is no longer available", duration: 5 });
    this.cleanUpMeeting();
  };
  //End of invokes

  //GETS
  checkPage = page => this.page === page;

  getAttendeeList = () => this.attendees;

  getMeetingLocal = () => localStorage.getItem(this.variable_name);

  getMeeting = () => this.uuid;

  getMeetingInfo = () => this.meeting_info;

  isVisible = () => this.visible;

  isRecording = () => this.recording;
  //End of GETS

  //SETS
  setMeeting = async uuid => {
    localStorage.setItem(this.variable_name, uuid);
    this.uuid = uuid;
    this.visible = true;
    await this.joinLobby();
  };

  requestRecording = async () => {
    await invokeApi({
      path: `/rest/meeting/recording/request`,
      method: "GET",
      queryParams: { m: this.uuid },
    });
  }

  acceptRecording = async () => {
    await invokeApi({
      path: `/rest/meeting/recording/accept`,
      method: "GET",
      queryParams: { m: this.uuid },
    });
  }

  updateCanRecord = (value) => {
    this.canRecord = value;
    this.updateApp();
  }

  updateChannelLastRead = (value) => {
    this.channelLastRead = value;
  }

  startContextRecording = async () => {
    await invokeApi({
      path: `/rest/meeting/recording/start`,
      method: "GET",
      queryParams: { m: this.uuid },
      onSuccess: info => {
        this.recording = true;
        this.recorder_id = info;
        this.updateApp();
      },
    });
  };

  stopContextRecording = async () => {
    await invokeApi({
      path: `/rest/meeting/recording/stop`,
      method: "GET",
      queryParams: { m: this.uuid },
      onSuccess: () => {
        this.recording = false;
        this.updateApp();
      },
    });
  };
  //End of SETS
}

export default Meeting;
