class FourLytics {
  constructor(id, muuid = null, amuuid, recordingEnabled = false, custom_vars = {}) {
    this.previousUrl = null;
    this.noRecordingLinks = [
      "/signin",
      "/authorize",
      "/challenge",
      "/security",
      "/forgotpassword",
      "/forgotusername",
      "/activate",
    ];
    this.dontFormatText = ["Login Successful", "Disabled Recording", "Enabled Recording", "Recording is disabled"];
    this.specialChecks = [
      { value: "/forgotpassword", text: "Forgot Password" },
      { value: "/forgotusername", text: "Forgot Username" },
      { value: "/authorize", text: "Authorize" },
      { value: "/challenge", text: "Device Verification" },
      { value: "/activate", text: "Activate" },
      { value: "/security/device-token", text: "Device Token Request" },
      { value: "/security/security-questions", text: "Security Questions" },
    ];
    this.recording = false;
    this.recordingEnabled = recordingEnabled;
    this.sensitiveUsed = false;
    this.previousUrl = window?.location?.href;
    if (id) {
      this.trackerId = id;
      this._tracker = window._paq = window._paq || [];
      this.custom_vars = custom_vars;
      this.muuid = muuid;
      if (muuid) {
        this.setUser();
        this.setCustomVars(custom_vars);
      }
      this.amuuid = amuuid;
      this.createScript();
      window.addEventListener("click", this.navAndUnAuthTrackers);
    } else {
      console.warn("4Lytics not enabled");
    }
  }

  handleRecording = (login = false) => {
    if (this.recordingEnabled) {
      let hideRecording = this.check_auth(window?.location?.pathname);
      if (hideRecording) {
        this.disableRecording(window?.location?.pathname);
      } else {
        if (login || !this.sensitiveUsed) {
          //If overrided this has to be false before it turns back on
          this.enableRecording();
        }
      }
    } else {
      this.disableRecording("Recording is disabled");
    }
  };

  navAndUnAuthTrackers = e => {
    this._tracker = window._paq = window._paq || []; //re initialized because it's in an event listener
    //for any tabs the user clicks over to
    this.handleRecording(e?.login);
    let isNav = this.checkIfNav(e);
    if (isNav) {
      if (this.previousUrl !== e?.target?.href) {
        let footer = ["Privacy Policy", "Legal Page"];
        let callRegex = /^tel:/i;
        let emailRegex = /^mailto:/i;
        if (
          e?.target?.innerText !== "Read More" &&
          !footer.includes(e?.target?.innerText) &&
          !callRegex.test(e?.target?.href) &&
          !emailRegex.test(e?.target?.href)
        ) {
          this.trackPage(window.location.pathname);
        } else if (!callRegex.test(e?.target?.href) && !emailRegex.test(e?.target?.href)) {
          this.trackEvent("Redirect", e?.target?.innerText);
        }
      }
    } else {
      if (e?.login) {
        this.trackPage(e?.text);
      }
    }
  };

  checkIfNav = event => {
    if (event?.target?.tagName === "A" && event?.target.rel !== "nofollow") {
      return true;
    }
    return false;
  };

  setSensitive = value => (this.sensitiveUsed = value);

  updateMuuid = muuid => {
    if (this.muuid !== muuid) {
      this.muuid = muuid;
      this.setUser();
      if (muuid) {
        this.setSensitive(false);
        this.navAndUnAuthTrackers({ login: true, text: "Login Successful" });
      } else {
        this.newSession();
      }
    }
  };

  updateAmuuid = amuuid => {
    if (this.amuuid !== amuuid) {
      this.amuuid = amuuid;
    }
  };

  disableRecording = (text, button = false) => {
    if (this.recording) {
      this._tracker.push(["HeatmapSessionRecording::disable"]);
      this.recording = false;
      if (button) {
        this.setSensitive(true);
        this.trackPage("Disabled Recording");
      }
      this.trackEvent("Interaction", text);
    }
  };

  enableRecording = (text = null, button = false) => {
    if (!this.recording) {
      this._tracker.push(["HeatmapSessionRecording::enable"]);
      this.recording = true;
      if (button) {
        this.setSensitive(false);
        this.trackPage("Enabled Recording");
      }
      this.trackEvent("Interaction", text);
    }
  };

  check_auth = link => {
    const { noRecordingLinks } = this;
    for (let i = 0; i < noRecordingLinks?.length; i++) {
      if (link?.includes(noRecordingLinks?.[i])) {
        return true;
      }
    }
    return false;
  };

  check_sensitive = text => {
    const { sensitiveButtons } = this;
    for (let i = 0; i < sensitiveButtons?.length; i++) {
      if (text?.includes(sensitiveButtons?.[i])) {
        return true;
      }
    }
    return false;
  };

  setUser = () => {
    const { muuid, _tracker } = this;
    if ((muuid ? muuid.toString() : "") !== "") {
      _tracker.push(["setUserId", muuid.toString()]);
    }
  };

  trackDownload = text => {
    this._tracker = window._paq = window._paq || []; //re initialized because it's in an event listener
    this._tracker.push(["trackLink", `https://${document.domain}/${text}`, "download"]);
  };

  clearCustomVars = () => {
    this.custom_vars = [];
  };

  setNewCustomVars = vars => {
    this.custom_vars = vars;
  };

  trackPage = async text => {
    const { previousUrl, _tracker } = this;
    if (previousUrl) {
      _tracker.push(["setReferrerUrl", this.previousUrl]);
    }
    let pageInfo = this.check_path(text);
    let doc_title = await this.getDocTitle(pageInfo?.text);
    _tracker.push(["setCustomUrl", pageInfo?.url]);
    _tracker.push(["setDocumentTitle", doc_title]);
    _tracker.push(["trackPageView"]);
    _tracker.push(["appendToTrackingUrl", ""]);
    this.previousUrl = pageInfo?.url;
  };

  trackEvent = (type, text) => {
    this._tracker = window._paq = window._paq || []; //re initialized because it's in an event listener
    this._tracker.push(["trackEvent", type, text]);
    if (type === "Authentication") {
      setTimeout(() => {
        this.handleRecording();
      }, 1000);
    }
  };

  setRecordingEnabled = value => {
    this.recordingEnabled = value;
    if (value === false) {
      this.disableRecording();
    }
  };

  newSession = () => {
    const { _tracker } = this;
    this.clearCustomVars();
    _tracker.push(["resetUserId"]);
    _tracker.push(["appendToTrackingUrl", "new_visit=1"]);
    _tracker.push(["deleteCustomVariables", "page"]);
  };

  getDocTitle = async (text, retry = false) => {
    if (text?.includes?.("/")) {
      text = text?.slice?.(1);
    }
    if (text === "" && !retry) {
      //Situtation is usually needed for users that put the baseUrl in without /app /signin
      let results = await new Promise((resolve, reject) =>
        setTimeout(async () => {
          //one retry if redirect was not finished
          let check_again = await this.check_path(window.location.pathname);
          let new_title = await this.getDocTitle(check_again?.text, true);
          await this.handleRecording();
          return resolve(new_title);
        }, 1500)
      ).then(r => r);
      return new Promise((resolve, reject) => resolve(results));
    }
    return new Promise((resolve, reject) => resolve(text?.[0]?.toUpperCase?.() + text?.slice?.(1)));
  };

  parse_breadcrumb = () => {
    const topbar = document?.querySelector("#topbar-container");
    if (topbar) {
      const breadcrumb = topbar.querySelector(".ant-breadcrumb");
      if (breadcrumb) {
        const links = breadcrumb.querySelectorAll(".ant-breadcrumb-link");
        if (links?.length > 0) {
          let text = "";
          for (let i = 0; i < links?.length; i++) {
            text += `${i > 0 ? " - " : ""}${links?.[i]?.innerText}`;
          }
          return text;
        }
      }
    }
  };

  check_path = text => {
    const { specialChecks } = this;
    if (this.dontFormatText.includes(text)) {
      return {
        url: document.URL,
        text: text,
      };
    }
    for (let i = 0; i < specialChecks?.length; i++) {
      if (text?.includes(specialChecks?.[i]?.value)) {
        return {
          url: `https://${document.domain}/${specialChecks[i].value}`,
          text: specialChecks[i]?.text ?? specialChecks[i].value,
        };
      }
    }
    let breadCrumbText = this.parse_breadcrumb();
    if (breadCrumbText) {
      return { url: document?.URL, text: breadCrumbText };
    }
    let removeFirst = ["fef", "r4", "r7"];
    let new_text = text;
    for (const item of removeFirst) {
      new_text = text.replace(`/${item}/`, "");
    }
    return { url: document.URL, text: new_text.trim() };
  };

  setCustomVars = vars => {
    const { _tracker } = this;
    let index = 1;
    for (const [key, value] of Object.entries(vars)) {
      _tracker.push(["setCustomVariable", index++, key, value, "visit"]);
    }
  };

  createScript = () => {
    const { custom_vars, _tracker, trackerId } = this;
    // _tracker.push(['setCustomVariable', 1, 'Advisor', amuuid, 'visit']);
    setTimeout(async () => {
      let pageInfo = this.check_path(window.location.pathname);
      let doc_title = await this.getDocTitle(pageInfo?.text);
      this.handleRecording();
      _tracker.push(["setCustomUrl", pageInfo?.url]);
      _tracker.push(["setDocumentTitle", doc_title]);
      this.setCustomVars(custom_vars);
      _tracker.push(["setLinkTrackingTimer", 250]);
      _tracker.push(["trackPageView"]); // should be after setting custom dimensions (variables) before pushing an action
      _tracker.push(["enableLinkTracking"]);
      this.previousUrl = pageInfo?.url;
      (() => {
        let u = "https://fourlytics.foureyes.financial/";
        _tracker.push(["setTrackerUrl", u + "js/tracker.php"]);
        _tracker.push(["setSiteId", trackerId]);
        _tracker.push(["appendToTrackingUrl", ""]);

        let d = document,
          g = d.createElement("script"),
          s = d.getElementsByTagName("script")[0];
        g.type = "text/javascript";
        g.async = true;
        g.src = u + "js/tracker.php";
        s.parentNode.insertBefore(g, s);
      })();
    }, 1250);
  };
}

export default FourLytics;
