/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import moment from "moment";
import * as d3 from "d3";
import themes from "../settings/themes";
import { siteConfig } from "../settings/config";

export function getGreeting() {
  const currentHour = moment().hour();
  return currentHour >= 12 && currentHour <= 17
    ? "Good afternoon"
    : currentHour >= 18
    ? "Good evening"
    : "Good morning";
}

export function checkExists(obj, level, ...rest) {
  if (obj === undefined) return false;
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
  return checkExists(obj[level], ...rest);
}

export function getValue(path, obj) {
  const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : null), o);
  let fields = path.split(".");
  return get(fields, obj);
}

export function formatNumber(value, format, defaultvalue = null) {
  let result = defaultvalue ?? "--";

  if (value !== null && !isNaN(value)) {
    result = d3.format(format)(value);
  } else if (defaultvalue !== null && !isNaN(defaultvalue)) {
    result = d3.format(format)(defaultvalue);
  }
  return result;
}

export function charScrubber(str) {
  const percent = "\u0025";
  const semicolon = "\u003B";
  const amper = "\u0026";
  const cash = "\u0024";
  let output = str.replace(/[%]/g, percent).replace(/[;]/g, semicolon).replace(/[&]/g, amper).replace(/[$]/g, cash);
  return output;
}

function hashCode(str) {
  /* Helper function for generateColor */
  if (!str) return 0;
  var hash = 0,
    i,
    chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function generateColor(str, colors, tag = false, level = "-5") {
  const colorPalettes = ["red", "volcano", "orange", "gold", "blue", "cyan", "green", "geekblue", "purple", "magenta"];
  if (!colors) {
    colors = colorPalettes;
  }
  var hash = hashCode(str);
  let colorName = colors[Math.abs(hash) % colors.length];
  if (tag) {
    return colorName;
  }
  return themes[siteConfig.defaultTheme].color[colorName][colorName + level];
}

export function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function similarity(s1, s2) {
  // Gets similarity based off Levenshtein distance
  // https://en.wikipedia.org/wiki/Levenshtein_distance
  // Bascially it calculates the simiularity score based
  // on how many edits it takes to make the two strings equal

  if (!s1 || !s2) {
    // If either are null the sim score is 0
    return 0.0;
  }
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = [];
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export const objectEquals = (obj0, obj1) => {
  if (obj0 === null || obj0 === undefined || obj1 === null || obj1 === undefined) {
    return obj0 === obj1;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  // if they are regexps, they should exactly refer to same one
  if (obj0 instanceof Function || obj0 instanceof RegExp) {
    return obj0 === obj1;
  }
  if (obj0 === obj1 || obj0.valueOf() === obj1.valueOf()) {
    return true;
  }
  // if they are dates, they must equal valueOf
  // if they are strictly equal, they both need to be objects
  if (
    (Array.isArray(obj0) && obj0.length !== obj1.length) ||
    obj0 instanceof Date ||
    !(obj0 instanceof Object) ||
    !(obj1 instanceof Object)
  ) {
    return false;
  }

  // recursive object equality check
  let props = Object.keys(obj0);
  return (
    Object.keys(obj1).every(function (i) {
      return props.indexOf(i) !== -1;
    }) &&
    props.every(function (i) {
      return objectEquals(obj0[i], obj1[i]);
    })
  );
};

export function overwriteObj(obj, overwrite) {
  let temp = { ...(obj || {}) };
  Object.keys(overwrite || {}).forEach(key => {
    if (!temp.hasOwnProperty(key) || temp[key]?.constructor !== Object) {
      temp[key] = overwrite[key];
    } else {
      temp[key] = overwriteObj(temp[key], overwrite[key]);
    }
  });
  return temp;
}

export const hasSpecialChar = val => {
  return /.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./@#]{1,}.*/.test(val);
};

export function makeFourlyticsCustomVariables(values, context = {}) {
  let customVars = {};
  const parser = {
    text: value => {
      if (value?.text !== undefined && value?.text !== null) {
        return { value: value.text };
      } else {
        return false;
      }
    },
    state: value => {
      if (value?.state !== undefined && value?.state !== null) {
        const temp = value.state.split(".");
        let tempValue = JSON.parse(JSON.stringify(context));
        for (const item of temp) {
          if (Object.keys(tempValue).includes(item)) {
            tempValue = tempValue?.[item];
          } else {
            return false;
          }
        }
        if (tempValue !== undefined && tempValue !== null) {
          return { value: tempValue };
        }
      } else {
        return false;
      }
    },
    func: value => {
      if (value?.func !== undefined && value?.func !== null && value?.func?.call) {
        const params = value?.func?.params?.split(",") ?? [];
        let in_params = [];
        let tempValue = JSON.parse(JSON.stringify(context));
        for (const param of params) {
          const the_param = param?.split(".");
          if (the_param[0] === "state") {
            const temp = value?.func?.[the_param?.[1]]?.split(".") ?? [];
            for (const item of temp) {
              if (Object.keys(tempValue).includes(item)) {
                tempValue = tempValue?.[item];
              } else {
                return false;
              }
            }
          } else {
            tempValue = param;
          }
          if (tempValue !== undefined && tempValue !== null) {
            in_params.push(tempValue);
          } else {
            console.warn("There was an issue parsing");
            return false;
          }
        }
        return { value: context?.[value.func.call]?.(...in_params) };
      } else {
        return false;
      }
    },
    default: () => false,
  };
  for (let value of values) {
    let new_var = parser?.[value?.type ?? "default"](value);
    if (new_var?.value && value?.key) {
      customVars[value?.key] = new_var?.value;
    }
  }
  return customVars;
}

export function debounce(func, time = 250) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, time);
  };
}

export function formatSymbolInfo(item) {
  if (item?.entity_id === undefined && item?.category === undefined) {
    return false;
  } else {
    return (
      (item?.entity_id === null ? "--" : item?.entity_id) + " | " + (item?.category === null ? "--" : item?.category)
    );
  }
}

export function getPrimaryAddressKey(addresses, key = "prov_state") {
  let foundKey = null;
  for (const address of addresses || []) {
    if (address && address?.is_primary && address?.[key]) {
      foundKey = address[key];
      break;
    }
  }
  return foundKey;
}
