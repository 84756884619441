/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, List, Popconfirm, Space, Tooltip, Typography, Upload } from "antd";
import Scrollbar from "react-smooth-scrollbar";
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import Message from "./message";
import MessageSender from "./messageSender";
import { invokeApi } from "../../helpers/authHelper";
import AppContext from "../../AppContext";
import moment from "moment";

const { Item } = List;
const { Text } = Typography;
const { Dragger } = Upload;

const Messages = withTheme(
  injectIntl(
    ({
      attachment,
      backToBottom,
      canSave,
      chatSelected = {},
      getToken,
      intl,
      isLoading,
      itemEditing,
      itemVisible,
      limit = null,
      loading = false,
      messages = {},
      messageForm,
      noSelectable = false,
      room = {},
      scrollbar = {},
      senderButtonDisabled,
      setAttachment,
      setBackToBottom,
      setCanSave,
      setSenderButtonDisabled,
      setItemEditing,
      setItemVisible,
      setLoading,
      senderForm,
      setLimit,
      setUploading,
      title,
      token = null,
      type = "meeting",
      updateChannelLastRead,
      uploading,
      users = {},
      visible = false,
    }) => {
      const { active_role, hasPermission, meetingContext, roles, user_info } = useContext(AppContext);
      const [grid, setGrid] = useState(noSelectable ? `calc(100% - 0px) 0px` : `40px calc(100% - 40px) 0px`);

      useEffect(() => {
        if (visible) {
          if (scrollbar.current?.scrollbar && !isLoading) {
            scrollbar.current.scrollbar.scrollTo(
              limit?.x ? limit?.x : scrollbar.current.scrollbar?.limit?.x,
              limit?.y ? limit?.y : scrollbar.current.scrollbar?.limit?.y
            );
          }
        } else {
          setLimit(null);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [grid, messages, limit, scrollbar, setLimit, visible]);

      const updateGrid = new_pixels => {
        if (scrollbar?.current?.scrollbar?.limit?.y !== scrollbar?.current?.scrollbar?.offset?.y) {
          setLimit(scrollbar?.current?.scrollbar?.limit);
        } else {
          setLimit(null);
        }
        if (noSelectable) {
          setGrid(`calc(100% - ${new_pixels}px) ${new_pixels}px`);
        } else {
          setGrid(`40px calc(100% - ${40 + new_pixels}px) ${new_pixels}px`);
        }
      };

      const onScroll = event => {
        if (event?.offset?.y === 0 && token) {
          setLoading(true);
          setLimit(event?.limit);
          getToken();
        } else if (room?.count > 0) {
          if (event.offset.y <= scrollbar.current.scrollbar?.limit?.y - 50) {
            if (!backToBottom) {
              setBackToBottom(true);
            }
          } else {
            if (backToBottom) {
              updateChannelLastRead();
              setBackToBottom(false);
            }
          }
        }
      };

      const scrollToBottom = () => {
        setBackToBottom(false);
        updateChannelLastRead();
        if (scrollbar.current.scrollbar) {
          scrollbar.current.scrollbar.scrollTo(
            scrollbar.current.scrollbar.limit?.x,
            scrollbar.current.scrollbar.limit?.y
          );
        }
      };

      const editItem = (item, index) => {
        if (messageForm?.setFieldsValue) {
          messageForm.setFieldsValue({ content: item?.Content?.replace("{link}", "") });
        }
        setItemEditing(index);
      };

      const cancelEdit = () => {
        if (messageForm?.resetFields) {
          messageForm.resetFields();
        }
        setItemEditing(null);
        setCanSave(false);
      };

      const checkValues = (changedValues, original) => {
        let message = changedValues?.content ?? "";
        if (original?.Content.trim() === message.trim()) {
          message = "";
        }
        if (message !== "" && !canSave) {
          setCanSave(true);
        } else if (message === "" && canSave) {
          setCanSave(false);
        }
      };

      const saveItem = async item => {
        setItemEditing(null);
        let params = {
          path: `/rest/${type}/chat/edit/${item?.MessageId}`,
          method: "POST",
          body: {
            m: messageForm.getFieldValue("content"),
            c: chatSelected?.uuid,
          },
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(), // for meetings
          };
        }
        await invokeApi(params);
        cancelEdit();
      };

      const deleteItem = async item => {
        let params = {
          path: `/rest/${type}/chat/redact/${item?.MessageId}`,
          method: "POST",
          body: {
            m: messageForm.getFieldValue("content"),
            c: chatSelected?.uuid,
          },
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(), // for meetings
          };
        }
        await invokeApi(params);
      };

      const createActions = (item, index) => {
        if (index === itemEditing) {
          return [
            <Tooltip getPopupContainer={e => e?.parentElement} title={<FormattedMessage id="Save" />}>
              <Button disabled={!canSave} icon={<SaveOutlined />} onClick={() => saveItem(item)} type="link" />
            </Tooltip>,
            <Tooltip getPopupContainer={e => e?.parentElement} title={<FormattedMessage id="Cancel" />}>
              <Button icon={<CloseCircleOutlined />} onClick={cancelEdit} type="link" />
            </Tooltip>,
          ];
        } else if (index === itemVisible && users?.[item?.Sender?.Arn]?.uuid === user_info?.uuid && !item?.Redacted) {
          let buttons = [];
          if (
            hasPermission(`chime.chat${type === "meeting" ? ".video" : ""}.${roles?.[active_role]?.name}.message.edit`)
          ) {
            buttons.push(
              <Tooltip
                getPopupContainer={e => e?.parentElement}
                placement="bottom"
                title={<FormattedMessage id="Edit" />}
              >
                <Button icon={<EditOutlined />} onClick={() => editItem(item, index)} type="link" />
              </Tooltip>
            );
          }
          if (
            hasPermission(
              `chime.chat${type === "meeting" ? ".video" : ""}.${roles?.[active_role]?.name}.message.delete`
            )
          ) {
            buttons.push(
              <Popconfirm
                getPopupContainer={e => e?.parentElement?.parentElement}
                onConfirm={() => deleteItem(item, index)}
                title={<FormattedMessage id="Are you sure you want to delete this message?" />}
              >
                <Tooltip
                  getPopupContainer={e => e?.parentElement}
                  placement="bottom"
                  title={<FormattedMessage id="Delete" />}
                >
                  <Button icon={<DeleteOutlined />} type="link" />
                </Tooltip>
              </Popconfirm>
            );
          }
          return buttons;
        }
      };

      const sorted_list = Object.values(messages ?? {}).sort((a, b) =>
        moment(a?.CreatedTimestamp).diff(b?.CreatedTimestamp)
      );

      const uploadProps = {
        showUploadList: false,
        disabled:
          uploading ||
          !hasPermission(
            `chime.chat${type === "meeting" ? ".video" : ""}.${roles?.[active_role]?.name}.message.attachment`
          ) ||
          !hasPermission(`chime.chat${type === "meeting" ? ".video" : ""}.${roles?.[active_role]?.name}.message`),
        customRequest: attach_info => {
          senderForm.setFieldsValue({ messageAttachment: attach_info?.file });
          if (senderButtonDisabled) {
            setSenderButtonDisabled(false);
          }
          setAttachment(attach_info?.file);
        },
        openFileDialogOnClick: false,
        style: {
          background: "transparent",
          border: "none",
          cursor: "default",
          textAlign: "unset",
        },
      };

      return (
        <Fragment>
          <Space
            direction="vertical"
            hidden={!visible}
            style={{ width: "100%", display: "grid", gridTemplateRows: grid, height: "100%" }}
          >
            {!noSelectable && (
              <Text strong style={{ fontSize: "1.1em", paddingLeft: "1em" }}>
                {title ?? (
                  <Space>
                    <Text>{chatSelected?.name}</Text>
                    {chatSelected?.username && <Text type="secondary">({chatSelected?.username})</Text>}
                  </Space>
                )}
              </Text>
            )}
            <Scrollbar onScroll={onScroll} ref={scrollbar} style={{ height: "100%" }}>
              <UploadWrapper uploadText={intl.formatMessage({ id: "Drop here" })}>
                <Dragger {...uploadProps}>
                  <List
                    bordered={false}
                    dataSource={sorted_list}
                    loading={loading || isLoading}
                    locale={{ emptyText: <FormattedMessage id="No messages" /> }}
                    renderItem={(message, index) => (
                      <Wrapper>
                        <Item
                          actions={createActions(message, index)}
                          onMouseEnter={() => setItemVisible(index)}
                          onMouseLeave={() => setItemVisible(null)}
                          style={{ minHeight: "80px", padding: 0 }}
                        >
                          <Message
                            chatSelected={chatSelected}
                            details={message}
                            editing={index === itemEditing}
                            form={messageForm}
                            read={
                              room?.last_read ? moment(message?.CreatedTimestamp).diff(room?.last_read) <= 0 : false
                            }
                            saveItem={() => saveItem(message)}
                            setCanSave={changedValues => checkValues(changedValues, message)}
                            type={type}
                            user={users?.[message?.Sender?.Arn]}
                          />
                        </Item>
                      </Wrapper>
                    )}
                  />
                </Dragger>
              </UploadWrapper>
            </Scrollbar>
            {hasPermission(`chime.chat${type === "meeting" ? ".video" : ""}.${roles?.[active_role]?.name}.message`) && (
              <MessageSender
                attachment={attachment}
                chatSelected={chatSelected}
                disabled={senderButtonDisabled}
                form={senderForm}
                room={room}
                scrollbar={scrollbar}
                setAttachment={setAttachment}
                setDisabled={setSenderButtonDisabled}
                setLimit={setLimit}
                setUploading={setUploading}
                setGrid={updateGrid}
                type={type}
                updateChannelLastRead={updateChannelLastRead}
                uploading={uploading}
                users={users}
              />
            )}
          </Space>
          <Button
            hidden={!backToBottom}
            onClick={scrollToBottom}
            style={{ borderRadius: "5em", position: "absolute", left: "37%", bottom: "17%" }}
            type="primary"
          >
            <FormattedMessage id="See New Messages" />
          </Button>
        </Fragment>
      );
    }
  )
);

const Wrapper = styled.div`
  .ant-list-item-action {
    margin-left: 8px;
    & > li {
      padding: 0 3px;
    }
  }
  textArea {
    max-height: 150px !important;
  }
`;

const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  .ant-upload.ant-upload-drag .ant-upload-btn {
    padding: 0;
  }
  .ant-upload.ant-upload-drag.ant-upload-drag-hover {
    //Library maybe needed to make this work properly **react-dnd
    // &::after {
    //   content: "${props => props.uploadText}";
    //   position: absolute;
    //   background: ${props => props.theme.color.grey["grey-5"]};
    //   border-radius: 1em;
    //   left: 0;
    //   top: 0;
    //   z-index: 10;
    //   width: 100%;
    //   height: 100%;
    //   opacity: 0.3;
    //   border: dashed thin ${props => props.theme.color.blue["blue-6"]};
    //   text-align: center;
    //   padding-top: 50%;
    // }
  }
`;

export default Messages;
