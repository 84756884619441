/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import { config } from "../settings/config";
import jwt from "jsonwebtoken";
import axios from "axios";
import { deviceDetect } from "react-device-detect";
// For use with Username Login
export async function loginUser(username, password, product) {
  try {
    let res = await invokeApi({
      path: "/auth/login",
      method: "post",
      body: {
        username: username,
        password: password,
        product: product,
      },
      unauth: true,
    });
    if (res !== null) {
      if (res?.access_token !== undefined) {
        sessionStorage.clear();
        if (setUserTokens(res)) {
          let dt = getDeviceToken();

          if (dt !== null) {
            // TODO: Need to impliment a better login / activity tracking
          }
          return true;
        } else {
          return false;
        }
      } else if (res?.challenge !== undefined) {
        sessionStorage.clear();
        return res;
      }
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }
}

export async function getChatsocket(role = "meeting") {
  try {
    return await invokeApi({
      path: `/rest/${role}/chat/socket`,
      method: "GET",
    });
  } catch {}
}

// For use with SSO Login
export async function authorizeUser(tokens) {
  try {
    if (setUserTokens(tokens)) {
      let dt = getDeviceToken();

      let adata = {};
      if (dt !== null) {
        await invokeApi({
          path: `/rest/user/device`,
          method: "PATCH",
          body: { token: dt, data: deviceDetect() },
        });
        adata["device"] = dt;
      }

      addActivity("user.login.sso", adata);

      return true;
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }
}

export async function authUser() {
  let tokens = getUserTokens();
  if (tokens !== null && getTokenExpiry(tokens?.access_token) > Date.now()) {
    if ((getTokenExpiry(tokens?.access_token) - Date.now()) / 60000 <= 30) {
      refreshUser(tokens?.refresh_token);
    }
    return true;
  }
  return false;
}

function getTokenExpiry(token) {
  let exp = 0;

  if (token !== null) {
    let t = jwt.decode(token);
    exp = t?.exp ? t.exp * 1000 : 0;
  }

  return exp;
}

async function refreshUser(token) {
  try {
    let res = await invokeApi({
      path: "/rest/user/refresh",
      method: "post",
      body: {
        refresh_token: token,
      },
      unauth: false,
      refresh: true,
    });
    setUserTokens(res);
  } catch (e) {
    throw e;
  }
}

export function setUserTokens(tokens) {
  if (tokens !== null && getTokenExpiry(tokens?.access_token) > Date.now()) {
    localStorage.setItem("fef.auth.tokens", jwt.sign(tokens, config.jwt.sign_key));
    return true;
  } else {
    return false;
  }
}

export function getUserTokens() {
  let cred = localStorage.getItem("fef.auth.tokens");
  if (cred !== null) {
    cred = jwt.decode(localStorage.getItem("fef.auth.tokens"));
  }
  return cred;
}

export function removeAuthToken() {
  localStorage.removeItem("fef.auth.tokens");
}

export async function getUserInfo() {
  var userState = {
    user_uuid: null,
    user_account: null,
  };

  try {
    // Get User Info
    let result = await invokeApi({
      path: "/rest/user/me",
      method: "GET",
    });

    //Build User State Object to be merged into application context
    userState["user_uuid"] = result["uuid"];
    userState["roles"] = result["roles"] !== undefined ? result["roles"] : {};
    userState["permissions"] = result["permissions"] !== undefined ? result["permissions"] : {};
    delete result["roles"];
    delete result["permissions"];

    userState["user_info"] = result;

    //Gets User state
    userState["user_state"] = await getUserState();

    if (Object.keys(userState["roles"])[0] !== undefined) {
      if (sessionStorage.getItem("active_role_uuid")) {
        userState["active_role"] = sessionStorage.getItem("active_role_uuid");
      } else {
        userState["active_role"] =
          userState["user_info"]["default_role"] !== undefined && userState["user_info"]["default_role"] !== null
            ? userState["user_info"]["default_role"]
            : Object.keys(userState["roles"])[0];
      }

      sessionStorage.setItem("active_role", userState["roles"][userState["active_role"]]?.name);
      sessionStorage.setItem("active_role_uuid", userState["active_role"]);
      userState["defaultPage"] =
        userState["roles"][userState["active_role"]] !== undefined &&
        userState["roles"][userState["active_role"]] !== null
          ? userState["roles"][userState["active_role"]].page
          : "/";
    }

    result = await invokeApi({
      path: `/rest/app/appvar`,
      method: "POST",
      body: {
        key_list: ["risk.4lytics.config", "risk.language_toggle"],
      },
    });

    if (result?.length > 0) {
      userState.fourlyticsVars = result?.[0];
      userState.languages = result?.[1];
    }
  } catch (e) {
    if (e.code === "UserNotFoundException") {
      removeAuthToken();
    } else {
      console.error(e);
    }
    userState = false;
  }
  return userState;
}

export async function getUserState() {
  let t = getDeviceToken();
  let queryParams = t !== null ? { t: t } : {};

  return await invokeApi({
    path: "/rest/user/state",
    method: "GET",
    queryParams: queryParams,
  });
}

export function getDeviceToken() {
  return localStorage.getItem("fef.auth.device");
}

export function setDeviceToken(token) {
  localStorage.setItem("fef.auth.device", token);
}

export async function addActivity(activity, data = {}) {
  let payload = { activity: activity, data: data };

  return await invokeApi({
    path: "/rest/user/activity",
    method: "POST",
    body: payload,
  });
}

export const axiosCancel = () => axios.CancelToken.source();

export async function invokeApi({
  path,
  method = "get",
  headers = {},
  queryParams = {},
  body,
  unauth = false,
  refresh = false,
  cancelToken = axios.CancelToken.source().token,
  onSuccess = false,
  onError = false,
}) {
  if (refresh !== true && unauth !== true && !(await authUser())) {
    window.location.reload();
  }

  var params = {};
  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.headers.post["Content-Type"] = "application/json";

  headers = { ...headers, "x-fef-application": "risk" };

  if (unauth === true) {
    params = {
      baseURL: config.apiGateway.AUTH !== undefined ? config.apiGateway.AUTH : config.apiGateway.URL,
      method: method.toLowerCase(),
      url: path,
      params: queryParams,
      data: body ? JSON.stringify(body) : body,
      headers: headers,
      cancelToken: cancelToken,
    };
  } else {
    let tokens = getUserTokens();
    let app_headers = { "x-fef-auth": tokens?.access_token };

    if (sessionStorage.getItem("active_role")) {
      app_headers["x-fef-active-role"] = sessionStorage.getItem("active_role");
    }
    if (sessionStorage.getItem("active_role") === "overseer") {
      app_headers["x-fef-active-role"] = "overseer";
      let selected_advisor = JSON.parse(sessionStorage.getItem("overseer_selected_advisor"))?.advisor_uuid ?? null;
      if (selected_advisor) {
        app_headers["x-fef-oversight-contact"] = selected_advisor;
        app_headers["x-fef-active-role"] = "overseer";
      }
    }
    params = {
      baseURL: config.apiGateway.URL,
      method: method.toLowerCase(),
      url: path,
      params: queryParams,
      data: body,
      headers: { ...app_headers, ...headers },
      cancelToken: cancelToken,
    };
  }
  var inError = false;
  try {
    var result = await axios(params).catch();
  } catch (error) {
    inError = true;
    if (!axios.isCancel(error)) {
      let e = error;
      if (error.response && error.response.data) {
        // The request was made and the server responded with an error
        e.data = {
          ...error.response.data,
          status_code: error.response.data.status_code ? error.response.data.status_code : error.request.status,
          message: error.response.data.message ? error.response.data.message : error.message,
        };
      } else {
        // Something happened in setting up the request that triggered an Error
        e.data = {
          status_code: error.request.status,
          message: error.message,
        };
      }

      if (onError !== false) {
        onError(e);
      } else {
        throw e;
      }
    }
  }
  if (!inError) {
    if (onSuccess !== false) {
      onSuccess(result.data);
    } else {
      return result.data;
    }
  }
}
