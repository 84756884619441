/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";

import Panel from "../pages/template/panel";
import Sidebar from "../pages/template/sidebar";
import AppContext from "../AppContext";
import { Empty } from "antd";
import { FormattedMessage } from "react-intl";

import "nprogress/nprogress.css";
import "react-placeholder/lib/reactPlaceholder.css";

export default function asyncComponent(importComponent) {
  const C = React.lazy(importComponent);

  class AsyncFunc extends Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        component: null,
        templates: {
          panel: Panel,
          sidebar: Sidebar,
        },
      };
    }

    render() {
      const Template =
        this.state.templates[this.context.appLayout] !== undefined
          ? this.state.templates[this.context.appLayout]
          : null;

      return (
        <Template layout>
          <React.Suspense
            fallback={
              <div>
                <FormattedMessage id="Loading" />
                ...
              </div>
            }
          >
            <C {...this.props} />
          </React.Suspense>
        </Template>
      );
    }
  }
  return AsyncFunc;
}

export function asyncCustomComponent(importComponent) {
  const C = React.lazy(importComponent);

  class AsyncFunc extends Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
      this.state = {
        component: null,
        hasError: false,
      };
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidCatch = error => {
      console.warn(error);
    };

    render() {
      return this.state.hasError ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <React.Suspense
          fallback={
            <div>
              <FormattedMessage id="Loading" />
              ...
            </div>
          }
        >
          <C {...this.props} />
        </React.Suspense>
      );
    }
  }
  return AsyncFunc;
}
