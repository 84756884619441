import React from "react";
import { Button, Tooltip } from "antd";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TOOLTIPDELAY = 1;

const MeetingControl = withTheme(
  injectIntl(
    ({
      buttonText = {
        icon: ["fal", "phone"],
        size: "lg",
      },
      delay,
      intl,
      onClick = () => {},
      placement = "topLeft",
      shape = "circle",
      size = "large",
      title = "New Button",
      type = "ghost",
      visible = true,
      wrapper = null,
    }) => {
      const button = () => (
        <Button type={type} shape={shape} size={size} onClick={onClick} hidden={!visible}>
          <FontAwesomeIcon
            icon={buttonText?.icon}
            color={buttonText?.color}
            size={buttonText?.size}
            transform={buttonText?.transform}
          />
        </Button>
      );

      return (
        <Tooltip
          mouseEnterDelay={delay ?? TOOLTIPDELAY}
          title={intl.formatMessage({ id: title })}
          placement={placement}
        >
          {wrapper ? wrapper(button()) : button()}
        </Tooltip>
      );
    }
  )
);

export default MeetingControl;
