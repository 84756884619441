import React, { Fragment, useContext } from "react";
import { Avatar } from "antd";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { AudioMutedOutlined } from "@ant-design/icons";
import { Flex, useAttendeeStatus, VideoTile } from "amazon-chime-sdk-component-library-react";
import MeetingContext from "../../MeetingContext";

const NoCamera = withTheme(
  injectIntl(({ attendee }) => {
    const { attendees } = useContext(MeetingContext);
    const { muted: attendeeMuted } = useAttendeeStatus(attendee);
    const attendeeName = attendees?.[attendee]?.Name;

    const getInitials = name => {
      if (name) {
        const names = name.split(" ");
        return names.map(item => item?.[0]?.toUpperCase?.())?.join?.("");
      } else {
        return "UN";
      }
    };

    return (
      <Flex css="height: 100%" layout="fill-space-centered">
        <Avatar size={96} style={{ zIndex: 1, position: "absolute", fontSize: "1.5em" }} src={attendees?.[attendee]?.Avatar?.url}>
          {getInitials(attendeeName)}
        </Avatar>
        <VideoTile
          nameplate={
            attendeeMuted ? (
              <Fragment>
                {attendeeName} <AudioMutedOutlined />
              </Fragment>
            ) : (
              attendeeName
            )
          }
          style={{ background: "#3f4149" }}
        />
      </Flex>
    );
  })
);

export default NoCamera;
