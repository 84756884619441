/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React from "react";
import asyncComponent from "../helpers/AsyncFunc";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const routes = [
  {
    key: "risk4options",
    path: "r4options",
    onMenu: true,
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "tools"]} />,
    permissions: ["app.module.mgmt"],
    product: "fef",
    component: asyncComponent(() => import("../pages/options/riskOptions")),
    label: "Risk4 Options",
  },
  {
    key: "risk7options",
    path: "r7options",
    onMenu: true,
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "tools"]} />,
    permissions: ["app.module.mgmt"],
    product: "fef",
    component: asyncComponent(() => import("../pages/options/riskOptions")),
    label: "Risk7 Options",
  },
  {
    key: "posDelivery",
    path: "posDelivery",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "person-carry"]} />,
    permissions: ["app.module.mgmt"],
    product: "fef",
    component: asyncComponent(() => import("../pages/options/posDelivery")),
    label: "POS Delivery Methods",
    onMenu: true,
  },
  {
    key: "dzOptions",
    path: "dzOptions",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "tools"]} />,
    permissions: ["app.module.mgmt"],
    product: "fef",
    component: asyncComponent(() => import("../pages/options/dzOptions")),
    label: "DZ Options",
    onMenu: true,
  },
];

export default routes;
