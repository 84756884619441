import React, { useContext } from "react";
import styled from "styled-components";
import Room from "./Room";
import Lobby from "./Lobby";
import MeetingContext from "./MeetingContext";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

const MeetingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1.5rem 1em;
`;

const Routes = () => {
  const { checkPage, fullscreen } = useContext(MeetingContext);

  const manager = useMeetingManager(); //So meeting manager can be used throughout meeting container
  
  return (
    manager &&
    <MeetingContainer {...checkPage("room") && !fullscreen && { style: { padding: 0 } }}>
      {checkPage("lobby") && <Lobby manager={manager} />}
      {checkPage("room") && <Room manager={manager} />}
    </MeetingContainer>
  );
};

export default Routes;
