/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { useContext, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { invokeApi } from "../../helpers/authHelper";
import { Button, Form, Input, notification, Popconfirm, Space, Tooltip, Typography, Upload } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import AppContext from "../../AppContext";
import axios from "axios";

const { Item } = Form;
const { TextArea } = Input;
const { Text } = Typography;

const MessageSender = withTheme(
  injectIntl(
    ({
      attachment = null,
      chatSelected,
      disabled,
      form,
      intl,
      room = {},
      setAttachment,
      setDisabled,
      setGrid,
      setLimit,
      setUploading,
      theme,
      type = "meeting",
      updateChannelLastRead,
      uploading,
      users = {},
    }) => {
      const { axiosCancel, active_role, hasPermission, meetingContext, roles } = useContext(AppContext);

      const signal = axiosCancel();
      const textArea = useRef({});

      useEffect(() => {
        setGrid(32);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        calculateSize(textArea.current?.resizableTextArea?.clientHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [attachment]);

      const calculateSize = height => {
        const default_size = 32;
        let new_size = default_size;
        if ((height ?? 32) > 32) {
          new_size = height + 4;
        }
        if (attachment) {
          new_size = 32 + new_size;
        }
        setGrid(new_size);
      };

      const checkMessage = changedValues => {
        const message = changedValues?.message ?? "";
        if ((message.trim() !== "" || attachment) && disabled) {
          setDisabled(false);
        } else if (message.trim() === "" && !attachment && !disabled) {
          setDisabled(true);
        }
      };

      const makeTyper = () => {
        const user_keys = Object.keys(room?.typing ?? {});
        const max = 2;
        let string = "";
        switch (user_keys?.length) {
          case 0:
            return;
          case 1:
            return (
              <span>
                {users?.[user_keys?.[0]]?.name} <FormattedMessage id="is typing" />
                ...
              </span>
            );
          case 2:
            return (
              <span>
                <FormattedMessage
                  defaultMessage="{name} and {name2} are typing"
                  id="{name} and {name2} are typing"
                  values={{ name: users?.[user_keys?.[0]]?.name, name2: users?.[user_keys?.[1]]?.name }}
                />
                ...
              </span>
            );
          default:
            let keep_track = 1;
            for (let user of user_keys) {
              if (keep_track < max) {
                break;
              }
              string += users?.[user]?.name;
              if (keep_track > 1) {
                string += ", ";
              }
              keep_track++;
            }
            return (
              <span>
                <FormattedMessage
                  defaultMessage="{names} and {num} others are typing"
                  id="{names} and {num} others are typing"
                  values={{ names: string, num: user_keys?.length - max }}
                />
                ...
              </span>
            );
        }
      };

      const addAttachment = async () => {
        setUploading(true);
        let params = {
          path: `/rest/meeting/chat/send/attachment`,
          method: "POST",
          body: {
            file_name: attachment?.name,
            file_type: attachment?.type,
            c: chatSelected?.uuid,
          },
          onSuccess: processUpload,
          cancelToken: signal.token,
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(),
          };
        }
        return await invokeApi(params);
      };

      const sleep = async ms => {
        return await new Promise(resolve => setTimeout(resolve, ms));
      };

      const getDocumentStatus = async doc_id => {
        let params = {
          path: `/rest/meeting/chat/attachment/${doc_id}/status`,
          method: "POST",
          body: {
            c: chatSelected?.uuid,
          },
          cancelToken: signal.token,
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(),
          };
        }
        return await invokeApi(params);
      };

      const checkUploadStatus = async doc_id => {
        let i = 0;
        let resp = await getDocumentStatus(doc_id);
        while (i <= 60 && resp === "pending") {
          await sleep(3 * 1000);
          resp = await getDocumentStatus(doc_id);
          i = i + 3;
        }
        return resp;
      };

      const processUpload = async prep_info => {
        try {
          let doc_id = prep_info?.attachment_uuid;
          let options = {
            headers: prep_info?.upload_info?.headers,
          };
          await axios.put(prep_info?.upload_info?.url, attachment, options);
          let doc = await checkUploadStatus(doc_id);
          if (doc !== "clean") {
            notification.info({
              message: intl.formatMessage({ id: "Error sending message" }),
              duration: 5,
            });
            setDisabled(false);
            setUploading(false);
          } else {
            finishSending(doc_id);
          }
        } catch {
          notification.error({
            message: intl.formatMessage({ id: "Error uploading file" }),
            duration: 5,
          });
          setDisabled(false);
          setUploading(false);
        }
      };

      const finishSending = (doc_id = null) => {
        const message = form.getFieldValue("message");
        let text = "";
        if (message && message.trim() !== "") {
          text = message.trim();
        } else {
          text = "{link}";
        }
        let params = {
          path: `/rest/${type}/chat/send`,
          method: "POST",
          body: {
            m: text,
            c: chatSelected?.uuid,
            a: doc_id,
          },
        };
        if (type === "meeting") {
          params.queryParams = {
            m: meetingContext?.getMeeting(), // for meetings
          };
        }
        invokeApi(params);
        form.resetFields();
        setAttachment(null);
        setUploading(false);
        setGrid(32);
        setTimeout(() => form.getFieldInstance("message").focus(), 1);
        updateChannelLastRead();
      };

      const sendMessage = event => {
        if (!event?.shiftKey) {
          const message = form.getFieldValue("message");
          const messageAttachment = form.getFieldValue("messageAttachment");
          if ((message && message.trim() !== "") || messageAttachment) {
            setLimit(null);
            setDisabled(true);
            try {
              if (messageAttachment) {
                addAttachment();
              } else {
                finishSending();
              }
            } catch {
              setDisabled(false);
            }
          }
        }
      };

      const uploadProps = {
        showUploadList: false,
        disabled: uploading,
        customRequest: attach_info => {
          if (disabled) {
            setDisabled(false);
          }
          form.setFieldsValue({ messageAttachment: attach_info?.file });
          setAttachment(attach_info?.file);
        },
      };

      return (
        <Wrapper>
          <Space direction="vertical" size={0} style={{ width: "100%", padding: "0 1em" }}>
            {Object.keys(room?.typing ?? {})?.length > 0 && <Text type="secondary">{makeTyper()}</Text>}
            {attachment && (
              <Space style={{ width: "100%" }}>
                <div>
                  <FormattedMessage id="File being uploaded" />: <b>{attachment?.name}</b>
                </div>
                <Popconfirm
                  okText={<FormattedMessage id="Yes" />}
                  cancelText={<FormattedMessage id="No" />}
                  title={<FormattedMessage id="Are you sure you want to remove this file?" />}
                  getPopupContainer={e => e?.parentElement}
                  onConfirm={() => {
                    const message = form.getFieldValue("message");
                    form.setFieldsValue({
                      messageAttachment: null,
                    });
                    if ((!message || message === "") && !disabled) {
                      setDisabled(true);
                    }
                    setAttachment(null);
                  }}
                >
                  <Tooltip title={<FormattedMessage id="Remove" />}>
                    <Button hidden={uploading} icon={<CloseOutlined />} type="link" />
                  </Tooltip>
                </Popconfirm>
              </Space>
            )}
            <Space align="end" style={{ width: "100%" }}>
              <Form form={form} layout="inline" name="messager" onValuesChange={checkMessage}>
                <Item name="message" style={{ width: "100%" }}>
                  <TextArea
                    autoSize
                    disabled={uploading}
                    onChange={event => setTimeout(() => calculateSize(event?.target?.clientHeight), 1)}
                    onPressEnter={sendMessage}
                    placeholder={intl.formatMessage({ id: "Enter message" })}
                    maxLength={4000}
                    ref={textArea}
                  />
                </Item>
                <Item hidden={true} name="messageAttachment" style={{ marginBottom: 0 }}>
                  <Input />
                </Item>
              </Form>
              <Button disabled={disabled} loading={uploading} onClick={sendMessage} type="primary">
                <FormattedMessage id="Send" />
              </Button>
              {hasPermission(
                `chime.chat${type === "meeting" ? ".video" : ""}.${roles?.[active_role]?.name}.message.attachment`
              ) && (
                <Tooltip title={<FormattedMessage id="Upload" />}>
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />} disabled={uploading} />
                  </Upload>
                </Tooltip>
              )}
            </Space>
          </Space>
        </Wrapper>
      );
    }
  )
);

const Wrapper = styled.div`
  .ant-space-item {
    &:first-child {
      width: 100%;
    }
  }
  textArea {
    max-height: 150px !important;
  }
`;

export default MessageSender;
