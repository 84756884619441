/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React from "react";
import asyncComponent from "../helpers/AsyncFunc";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PercentageOutlined } from "@ant-design/icons";

// Note: paths on components within a parent role must be unique for breadcrumbs to work correctly.
// ie. You cannot have appvar_case and appvar in the same parent.
const routes = [
  {
    key: "clients",
    path: "clients",
    product: "r7",
    component: asyncComponent(() => import("../pages/client/clientPortfolios")),
    label: "Clients",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "users"]} />,
    onMenu: true,
    permissions: ["r7.contact_search.view"],
  },
  {
    key: "client-report",
    path: "clients/report",
    product: "r7",
    label: "Client Review",
    permissions: ["r7.view.clients"],
    onMenu: false,
    component: asyncComponent(() => import("../pages/dashboards/dailyreports/Tier1SDReport")),
  },
  {
    key: "client-contactCard",
    path: "clients/:clientid/contactCard",
    regex: "clients/:clientid/contactCard",
    product: "r7",
    onMenu: false,
    label: "Contact Card", //"Clients"
    component: asyncComponent(() => import("../pages/Contacts")),
    permissions: ["r7.view.clients"],
    children: false,
  },
  {
    key: "onboarding",
    path: "onboarding",
    product: "r7",
    onMenu: true,
    label: "Onboarding",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "address-card"]} />,
    permissions: ["r7.view.onboarding", "r7.view.kyc"],
    children: [
      {
        key: "envelopestatus",
        path: "onboarding/envelopestatus",
        product: "r7",
        onMenu: true,
        label: "Envelope Status",
        component: asyncComponent(() => import("../pages/envelopestatus")),
        permissions: ["r7.view.onboarding", "r7.view.kyc"],
      },
      {
        key: "envelopestatusclient",
        path: "onboarding/envelopestatus/client=:clientid",
        product: "r7",
        onMenu: false,
        label: "Envelope Status",
        component: asyncComponent(() => import("../pages/envelopestatus")),
        permissions: ["r7.view.onboarding", "r7.view.kyc"],
      },
    ],
  },
  {
    key: "dashboards",
    path: "dashboards",
    product: "r7",
    component: false,
    onMenu: true,
    label: "Dashboards",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "tachometer-average"]} />,
    permissions: ["r7.all"],
    children: [
      {
        key: "dailyexceptions",
        path: "dashboards/dailyexceptions",
        regex: "dashboards/dailyexceptions",
        product: "r7",
        component: asyncComponent(() => import("../pages/dashboards/supervisordaily/dashboard.js")),
        onMenu: true,
        label: "Daily Exceptions",
        permissions: ["r7.all"],
      },
      {
        key: "kycdashboard",
        path: "dashboards/kyc",
        regex: "dashboards/kyc",
        product: "r7",
        component: asyncComponent(() => import("../pages/dashboards/kyc/index.js")),
        onMenu: true,
        label: "KYC",
        permissions: ["r7.kyc_dashboard.view"],
      },
      {
        key: "exceptionreport",
        path: "dashboards/dailyexceptions",
        regex: "dashboards/dailyexceptions/:title?",
        product: "r7",
        component: asyncComponent(() => import("../pages/dashboards/reports/ExceptionReport")),
        label: "{title}",
        permissions: ["r7.all"],
      },
      {
        key: "dailyreview",
        path: "dashboards/dailyexceptions/:title?/report",
        product: "r7",
        onMenu: false,
        label: "Daily Review",
        permissions: ["r7.all"],
        component: asyncComponent(() => import("../pages/dashboards/dailyreports/Tier1SDReport")),
      },
      {
        key: "dailyreview",
        path: "dashboards/dailyexceptions/:title?/advisor/report",
        product: "r7",
        onMenu: false,
        label: "Daily Review",
        permissions: ["r7.all"],
        component: asyncComponent(() => import("../pages/dashboards/dailyreports/SDAdvisorReport")),
      },
    ],
  },
  {
    key: "pos",
    path: "pos",
    product: "r7",
    onMenu: true,
    label: "Point of Sale",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "cash-register"]} />,
    permissions: ["r7.pos.view"],
    children: [
      {
        key: "search",
        path: "pos/search",
        product: "r7",
        onMenu: true,
        permissions: ["r7.pos.view"],
        label: "Search",
        component: asyncComponent(() => import("../pages/pos")),
      },
      {
        key: "history",
        path: "pos/history",
        product: "r7",
        onMenu: true,
        permissions: ["r7.pos.view"],
        label: "History",
        component: asyncComponent(() => import("../pages/pos")),
      },
    ],
  },
  {
    key: "products",
    path: "products",
    product: "r7",
    onMenu: true,
    label: "Product List",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "parking-circle"]} />,
    permissions: ["r7.product.approved.view", "r7.product.greyList.view", "r7.product.restricted.view"],
    children: [
      {
        key: "reviewed",
        path: "products/reviewed",
        product: "r7",
        onMenu: true,
        permissions: ["r7.product.approved.view"],
        label: "Reviewed",
        component: asyncComponent(() => import("../pages/products/products")),
      },
      {
        key: "grey",
        path: "products/grey",
        product: "r7",
        onMenu: true,
        permissions: ["r7.product.greyList.view"],
        label: "Grey List Restrictions",
        component: asyncComponent(() => import("../pages/products/products")),
      },
      {
        key: "restricted",
        path: "products/restricted",
        product: "r7",
        onMenu: true,
        permissions: ["r7.product.restricted.view"],
        label: "Restricted",
        component: asyncComponent(() => import("../pages/products/products")),
      },
    ],
  },
  {
    key: "caseManagement",
    path: "case_management",
    product: "r7",
    component: false,
    onMenu: true,
    label: "Case",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "comment-alt-smile"]} />,
    permissions: ["r7.cxe_mgmt.view"],
    children: [
      {
        key: "caseManagementTickets",
        path: "case_management/tickets",
        regex: "case_management/tickets",
        product: "r7",
        component: asyncComponent(() => import("../pages/tickets/")),
        onMenu: true,
        label: "Tickets",
        permissions: ["r7.cxe_mgmt.view"],
      },
      {
        key: "caseManagementTicketsCommunication",
        path: "case_management/tickets/:title",
        product: "r7",
        component: asyncComponent(() => import("../pages/tickets/conversation.js")),
        label: "{title}",
        permissions: ["r7.cxe_mgmt.view"],
        onMenu: false,
      },
      {
        key: "caseManagmentArchived",
        path: "case_management/archived",
        product: "r7",
        label: "Archived",
        onMenu: true,
        permissions: ["r7.cxe_mgmt.view"],
        component: asyncComponent(() => import("../pages/tickets/search.js")),
      },
      {
        key: "caseManagementTicketsCommunicationArchived",
        path: "case_management/archived/:title",
        product: "r7",
        component: asyncComponent(() => import("../pages/tickets/conversation.js")),
        label: "{title}",
        permissions: ["r7.cxe_mgmt.view"],
        onMenu: false,
      },
    ],
  },
  {
    key: "search",
    path: "search",
    product: "r7",
    component: false,
    onMenu: true,
    label: "Search",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "search"]} />,
    permissions: ["r7.transaction.view"],
    children: [
      {
        key: "transactions",
        path: "search/transactions/trades",
        product: "r7",
        component: asyncComponent(() => import("../pages/transactions/Transactions")),
        onMenu: true,
        label: "Transactions",
        permissions: ["r7.transaction.view"],
      },
      {
        key: "transactionreview",
        path: "search/transactions/trades/report",
        product: "r7",
        onMenu: false,
        label: "Daily Review",
        permissions: ["r7.transaction.view"],
        component: asyncComponent(() => import("../pages/dashboards/dailyreports/Tier1SDReport")),
      },
    ],
  },
  {
    key: "appvar",
    path: "appvar",
    product: "r7",
    onMenu: true,
    label: "App Variables",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "cogs"]} />,
    permissions: ["app.variable.mgmt"],
    component: asyncComponent(() => import("../pages/appvars/appvars.js")),
  },
  {
    key: "caseManagement",
    path: "case",
    product: "r7",
    onMenu: true,
    label: "Case",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "comment-alt-smile"]} />,
    permissions: ["app.variable.case"],
    component: asyncComponent(() => import("../pages/appvars/appvars.js")),
  },
  {
    key: "repcodes",
    path: "repcodes",
    product: "r7",
    component: asyncComponent(() => import("../pages/repcode/repcode")),
    onMenu: true,
    label: "Rep Codes",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "registered"]} />,
    permissions: ["r7.repcode.mgmt"],
  },
  {
    key: "advgroups",
    path: "advgroups",
    product: "r7",
    component: asyncComponent(() => import("../pages/advisors/advgroups/advgroup")),
    onMenu: true,
    label: "Advisor Groups",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "users"]} />,
    permissions: ["r7.advisor.mgmt"],
  },
  {
    key: "advisors",
    path: "advisors",
    product: "r7",
    component: asyncComponent(() => import("../pages/advisors/advisors")),
    onMenu: true,
    label: "Advisors",
    permissions: ["r7.advisor_contact.search.view"],
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "user-chart"]} />,
  },
  {
    key: "associates",
    path: "associates",
    product: "r7",
    onMenu: true,
    label: "Associates",
    permissions: ["assistant.view"],
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "user-chart"]} />,
    component: asyncComponent(() => import("../pages/advisors/assistants")),
  },
  {
    key: "regions",
    path: "regions",
    product: "r7",
    onMenu: true,
    label: "Regions",
    permissions: ["region.dashboard.view"],
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "atlas"]} />,
    component: asyncComponent(() => import("../pages/regions/region")),
  },
  {
    key: "usermgmt",
    path: "users",
    onMenu: true,
    label: "User Management",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "user-cog"]} />,
    permissions: ["user.view"],
    product: "r7",
    component: asyncComponent(() => import("../pages/users/user")),
  },
  {
    key: "reports",
    path: "reports",
    product: "r7",
    component: false,
    onMenu: true,
    label: "Reports",
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "file-chart-line"]} />,
    permissions: ["report.view"],
    children: [
      // {
      //     key: "activity",
      //     path: "reports/activity",
      //     product: "r7",
      //     exact: false,
      //     component: asyncComponent(() => import("../pages/reports/reports")),
      //     onMenu: true,
      //     label: "Activity Logs",
      //     permissions: ["r7.all"],
      // },
      {
        key: "supervisory",
        path: "reports/supervisory",
        regex: "reports/supervisory",
        product: "r7",
        component: asyncComponent(() => import("../pages/reports/reports.js")),
        onMenu: true,
        label: "Supervisory",
        permissions: ["report.view", "r7.kyc_report.view", "r7.report.onboarding.view"],
      },
      {
        key: "reportsreview",
        path: "reports/supervisory/report",
        product: "r7",
        onMenu: false,
        label: "Daily Review",
        permissions: ["r7.all"],
        component: asyncComponent(() => import("../pages/dashboards/dailyreports/Tier1SDReport")),
      },
      {
        key: "kycreport",
        path: "reports/kyc",
        product: "r7",
        onMenu: true,
        permissions: ["r7.kyc_report.view"],
        label: "KYC",
        component: asyncComponent(() => import("../pages/reports/kyc.js")),
      },
    ],
  },
  {
    key: "feeschedules",
    path: "feeschedules",
    product: "r7",
    onMenu: true,
    leftIcon: <PercentageOutlined className="anticon menuIcon" />,
    permissions: ["4ms.feeplans.mgmt"],
    component: asyncComponent(() => import("../pages/feeplanning")),
    label: "Fee Planning",
  },
  {
    key: "accountgroups",
    path: "accountgroups",
    product: "r7",
    onMenu: true,
    leftIcon: <FontAwesomeIcon className="anticon menuIcon" icon={["fal", "chart-network"]} />,
    permissions: ["r7.account_grouping.view"],
    component: asyncComponent(() => import("../pages/accountGroups/accountGroup")),
    label: "Account Groups",
  },
];

export default routes;
